import { Component, HostBinding, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ui-filter',
  template: `
    <div
      class="search-container
      {{ filterExpand ? 'filterExpand' : '' }}
      {{ hotpicks ? 'withHotpicks' : '' }}"
    >
      <mat-form-field subscriptSizing="dynamic">
        <mat-icon matPrefix svgIcon="icon_search"></mat-icon>
        <input
          type="search"
          [formControl]="control"
          matInput
          [placeholder]="placeholder"
          data-testid="search-input"
        />
      </mat-form-field>
      <mat-icon
        class="after"
        svgIcon="icon_filter"
        *ngIf="filterExpand"
      ></mat-icon>
    </div>
    <div *ngIf="hotpicks" class="hotpicks">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent {
  @Input() public placeholder = 'Search';
  @Input() public hotpicks = false;
  @Input() public filterNoBreakpoint = false;
  @Input() public filterExpand = false;
  @Input({ required: true }) public control!: FormControl<string>;

  @HostBinding('class')
  public get classes(): Record<string, boolean> {
    return {
      noBreakpoint: this.filterNoBreakpoint
    };
  }
}
