import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, Input, NgModule, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ErrorStateMatcher, MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Subject, takeUntil } from 'rxjs';
import { Constants } from '../../constants';

@Component({
  selector: 'ui-auto-complete-field',
  template: `
    <mat-form-field>
      <mat-label>{{ label }}</mat-label>
      <input
        title="filter"
        type="text"
        matInput
        [formControl]="control"
        [matAutocomplete]="auto"
        #trigger="matAutocompleteTrigger"
        [placeholder]="placeHolder"
      />
      <mat-icon matSuffix>search</mat-icon>
      <mat-error data-testid="errors">{{ getErrors() }}</mat-error>
      <mat-autocomplete #auto="matAutocomplete">
        <cdk-virtual-scroll-viewport
          [itemSize]="15"
          [style.height.px]="optionsBoxHeight(options.length)"
          #scrollViewport
        >
          <mat-option
            *cdkVirtualFor="let val of options; trackBy: trackBy"
            [value]="val"
            data-testid="option"
          >
            {{ val }}
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </mat-autocomplete>
    </mat-form-field>
  `,
  styles: []
})
export class AutoCompleteFieldComponent implements OnDestroy, OnInit {
  @Input() public placeHolder = '';
  @Input() public errorMessages: { [key: string]: string } = {};
  @Input() public control!: FormControl;
  @Input() public label = '';
  @Input() public numOfItemsDisplayed = 5; // Currently not used

  public options: string[] = [];
  public errorStateMatcher!: ErrorStateMatcher;

  private _destroySubject = new Subject<void>();
  private _possibleValues: string[] | null = [];

  public get possibleValues(): string[] | null {
    return this._possibleValues;
  }

  @Input()
  public set possibleValues(value: string[] | null) {
    this._possibleValues = value;
    this._updateOptions();
  }

  public ngOnInit(): void {
    this._updateOptions();
    this.control.valueChanges
      .pipe(takeUntil(this._destroySubject))
      .subscribe(() => this._updateOptions());
    this.errorStateMatcher = new AutoCompleteFieldComponentErrorStateMatcher();
  }

  public ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  public getErrors(): string {
    if (!this.control?.errors) return '';
    const errors = Object.keys(this.control.errors);
    return errors?.map(e => this.errorMessages[e] || e).join(',') ?? '';
  }

  public trackBy(_index: number, item: string): string {
    return item;
  }

  private _updateOptions(): void {
    if (this.possibleValues) {
      this.options = this.possibleValues
        .filter(
          val =>
            val
              ?.toLowerCase()
              .includes(this.control?.value?.toLowerCase() ?? '')
        )
        .sort((a, b) => a.localeCompare(b));
    } else {
      this.options = [];
    }
  }

  public optionsBoxHeight(count: number): number {
    return (
      Math.min(Constants.OPTION_VIEW_HEIGHT, count) *
      Constants.MATERIAL_OPTION_HEIGHT
    );
  }
}

export class AutoCompleteFieldComponentErrorStateMatcher
  implements ErrorStateMatcher
{
  public isErrorState(control: AbstractControl): boolean {
    const isTouchedOrDirty = control.touched || control.dirty;
    const isInvalid = control.invalid;

    return isTouchedOrDirty && isInvalid;
  }
}

@NgModule({
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    CommonModule,
    MatIconModule,
    ScrollingModule
  ],
  exports: [AutoCompleteFieldComponent],
  declarations: [AutoCompleteFieldComponent],
  providers: []
})
export class AutoCompleteFieldComponentModule {}
