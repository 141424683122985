import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { USER_PREFERENCES_KEY } from './constants';

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsService {
  constructor(
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  public async openSettingsPanel(key?: string): Promise<boolean> {
    const settingsPanelTitle = key ?? this._getDefaultSelectedPanel();

    if (settingsPanelTitle.length === 0) {
      return false;
    }

    const settingsQueryParam: Params = {
      settings: settingsPanelTitle
    };
    return this._router.navigate([], {
      relativeTo: this._route,
      queryParams: settingsQueryParam,
      queryParamsHandling: 'merge',
      preserveFragment: true
    });
  }

  public async closeSettingsPanel(): Promise<boolean> {
    const settingsQueryParam: Params = { settings: undefined };

    return this._router.navigate([], {
      relativeTo: this._route,
      queryParams: settingsQueryParam,
      queryParamsHandling: 'merge',
      preserveFragment: true
    });
  }

  private _getDefaultSelectedPanel(): string {
    return USER_PREFERENCES_KEY;
  }
}
