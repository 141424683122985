import { CdkPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ActionComponent } from '../../../components/action/action.component';
import { PageActionsBridgeService } from '../../../services/bridge/page-actions-bridge.service';
import { PageBackLink } from '../../../services/page-back-link.model';
import { PageTitleService } from '../../../services/page-title.service';

@Component({
  selector: 'ui-shell-page-title',
  template: `
    <ng-container *cdkPortal>
      <ng-content select="ui-shell-action"></ng-content>
    </ng-container>
  `
})
export class ShellPageTitleComponent
  implements OnChanges, OnDestroy, AfterViewInit
{
  @Input() public title!: string | null;
  @Input() public backLink!: PageBackLink | null;
  @ViewChild(CdkPortal, { static: true }) public portalContent!: CdkPortal;
  @ContentChildren(ActionComponent)
  public pageActions!: ActionComponent[];

  constructor(
    private _pageTitleService: PageTitleService,
    private _pageActionsBridgeService: PageActionsBridgeService
  ) {}

  public ngAfterViewInit(): void {
    this._pageActionsBridgeService.setActivePageItems(this.pageActions.length);
    if (this.pageActions && this.pageActions.length > 0) {
      this._pageActionsBridgeService.setPortal(this.portalContent);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['title']) {
      this._pageTitleService.setPageTitle(this.title);
    }
    if (changes['backLink']) {
      this._pageTitleService.setBackLink(this.backLink);
    }
  }

  public ngOnDestroy(): void {
    this._pageTitleService.leavePage();
    if (this.portalContent.isAttached) {
      this.portalContent.detach();
    }
    this._pageActionsBridgeService.setActivePageItems(0);
  }
}
