import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ProfilecardToggleService } from '../../services/behavior/profilecard-toggle.service';
import { ProfileCardIntl } from './profile-card-intl';
import { UserProfileInfo } from './user-profile-info.model';

@Component({
  selector: 'ui-shell-profile-card',
  template: `
    <div class="profile-data-container">
      <div class="profile-data">
        <ui-shell-avatar [userInfo]="userInfo"></ui-shell-avatar>
        <div class="email" data-testid="email">{{ userInfo.email }}</div>
      </div>
    </div>
    <div class="profile-links" (click)="closeProfileCard()">
      <ng-content select="[action-items]"></ng-content>
      <ui-shell-action
        *ngIf="userInfo.logoutAction"
        [title]="intl.logoutLabel"
        (click)="userInfo.logoutAction.do()"
        data-testid="logout"
      >
        <mat-icon prefix>logout</mat-icon>
      </ui-shell-action>
    </div>
  `,
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnDestroy {
  private readonly _destroySubject = new Subject<void>();

  @Input({ required: true }) public userInfo!: UserProfileInfo;

  constructor(
    private _profileCardToggleService: ProfilecardToggleService,
    public intl: ProfileCardIntl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    intl.changes$.pipe(takeUntil(this._destroySubject)).subscribe(() => {
      changeDetectorRef.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  public closeProfileCard(): void {
    this._profileCardToggleService.close();
  }
}
