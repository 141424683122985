import { CdkPortal } from '@angular/cdk/portal';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  booleanAttribute
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { SidePanelToggleService } from '../../services/behavior/sidepanel-toggle.service';
import { SidePanelBridgeService } from '../../services/bridge/sidepanel-bridge.service';

@Component({
  selector: 'ui-shell-sidepanel-content',
  template:
    '<ng-container *cdkPortal><div [class.active]="active" class="ui-sidepanel"><ui-thumbnail [title]="title" (close)="onHideClick()"></ui-thumbnail><ui-card><ng-content></ng-content></ui-card></div></ng-container>'
})
export class SidepanelContentComponent implements OnChanges, OnInit, OnDestroy {
  @ViewChild(CdkPortal, { static: true }) public portalContent?: CdkPortal;
  @Output() public activeChange = new EventEmitter<boolean>();
  @Input({ transform: booleanAttribute }) public active?: boolean;
  @Input() public title = '';

  private _onDestroySubject = new Subject<void>();

  public set activeValue(a: boolean) {
    this.active = a;
    this.activeChange.emit(this.active);
  }

  constructor(
    private _sidePanelBridgeService: SidePanelBridgeService,
    private _sidePanelToggleService: SidePanelToggleService
  ) {}

  public ngOnInit(): void {
    this._sidePanelBridgeService.portal$
      .pipe(takeUntil(this._onDestroySubject))
      .subscribe(x => {
        if (x !== this.portalContent) {
          this.activeValue = false;
        }
      });
  }
  public ngOnDestroy(): void {
    if (this.portalContent?.isAttached) {
      this.portalContent.detach();
      this._sidePanelToggleService.close();
    }
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }
  public onHideClick(): void {
    this.activeValue = false;
    this._sidePanelToggleService.close();
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['active'] && this.active && this.portalContent) {
      this._sidePanelToggleService.open();
      this._sidePanelBridgeService.setPortal(this.portalContent);
    }
    if (changes['active'] && !this.active) {
      const currentActivePortal = this._sidePanelBridgeService.activePortal;
      if (
        this.portalContent &&
        currentActivePortal === this.portalContent &&
        currentActivePortal.isAttached
      ) {
        this._sidePanelToggleService.close();
      }
    }
  }
}
