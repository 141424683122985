import { formatDate } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { DatetimeNumberComponent } from './datetime-number/datetime-number.component';

@Component({
  selector: 'ui-datetime',
  templateUrl: './datetime.component.html',
  styleUrls: ['./datetime.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: DatetimeComponent }]
})
export class DatetimeComponent
  implements
    MatFormFieldControl<Date>,
    ControlValueAccessor,
    OnChanges,
    AfterViewInit,
    OnDestroy
{
  @Input()
  public type: 'date' | 'time' | 'datetime' = 'date';

  @Input()
  public dateFormat: 'yyyy/MM/dd' | 'MM/dd/yyyy' | 'dd/MM/yyyy' =
    this._getLocaleDateFormat();

  @Input()
  public timeFormat: 'HH:mm:ss' | 'HH:mm' = 'HH:mm';

  @Input()
  public value: Date | null = null;

  @Input()
  public min: Date | null = null;

  @Input()
  public max: Date | null = null;

  @ViewChild('datetimePicker')
  private _datetimePicker!: ElementRef;
  @ViewChild('yearComponent')
  private _yearComponent!: DatetimeNumberComponent;
  @ViewChild('monthComponent')
  private _monthComponent!: DatetimeNumberComponent;
  @ViewChild('dateComponent')
  private _dateComponent!: DatetimeNumberComponent;
  @ViewChild('hourComponent')
  private _hourComponent!: DatetimeNumberComponent;
  @ViewChild('minuteComponent')
  private _minuteComponent!: DatetimeNumberComponent;
  @ViewChild('secondComponent')
  private _secondComponent!: DatetimeNumberComponent;

  private _orderedComponents: DatetimeNumberComponent[] = [];

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private _elementRef: ElementRef,
    @Inject(LOCALE_ID) private _locale: string
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['dateFormat'] || changes['type'] || changes['timeFormat']) {
      this._setComponentOrder();
      if (this.timeFormat == 'HH:mm') {
        this.second = null;
      }
    }

    if (changes['value']) {
      this._mapDateToProperties(this.value);
      this.stateChanges.next();
    }

    if (changes['min']) {
      if (this.value && this.min && this.value < this.min) {
        this.value = this.min;
        this._mapDateToProperties(this.min);
        this.stateChanges.next();
      }
      this._setMinProperties();
    }

    if (changes['max']) {
      if (this.value && this.max && this.value > this.max) {
        this.value = this.max;
        this._mapDateToProperties(this.max);
        this.stateChanges.next();
      }
      this._setMaxProperties();
    }
  }

  private _mapDateToProperties(date: Date | null): void {
    if (date) {
      this.year = date.getFullYear();
      this.month = date.getMonth() + 1;
      this.date = date.getDate();
      this.hour = date.getHours();
      this.minute = date.getMinutes();
      this.second = date.getSeconds();
    }
  }

  public ngAfterViewInit(): void {
    this._setComponentOrder();
  }

  private _setComponentOrder(): void {
    setTimeout(() => {
      switch (this.dateFormat) {
        case 'yyyy/MM/dd':
          this._orderedComponents = [
            this._yearComponent,
            this._monthComponent,
            this._dateComponent,
            this._hourComponent,
            this._minuteComponent,
            this._secondComponent
          ].filter(x => x);
          break;
        case 'MM/dd/yyyy':
          this._orderedComponents = [
            this._monthComponent,
            this._dateComponent,
            this._yearComponent,
            this._hourComponent,
            this._minuteComponent,
            this._secondComponent
          ].filter(x => x);
          break;
        case 'dd/MM/yyyy':
          this._orderedComponents = [
            this._dateComponent,
            this._monthComponent,
            this._yearComponent,
            this._hourComponent,
            this._minuteComponent,
            this._secondComponent
          ].filter(x => x);
          break;
      }
    });
  }

  // no easy way to retrieve the date format for a locale, other then to instantiate a date and then see where what is.
  // if in the future there ever is an easier way, please update below.
  private _getLocaleDateFormat(): 'yyyy/MM/dd' | 'MM/dd/yyyy' | 'dd/MM/yyyy' {
    //month is zero-based indexing months, so second 1 will give 2 as shortdate as it is the month in the constructor.
    const formattedDate = formatDate(
      new Date(1, 1, 3),
      'shortDate',
      this._locale
    );
    const yearIndex = formattedDate.indexOf('1');
    const monthIndex = formattedDate.indexOf('2');
    const dateIndex = formattedDate.indexOf('3');

    if (yearIndex < monthIndex && monthIndex < dateIndex) {
      return 'yyyy/MM/dd';
    } else if (monthIndex < dateIndex && dateIndex < yearIndex) {
      return 'MM/dd/yyyy';
    } else if (dateIndex < monthIndex && monthIndex < yearIndex) {
      return 'dd/MM/yyyy';
    } else {
      return 'yyyy/MM/dd';
    }
  }

  protected onNextRequested(event: DatetimeNumberComponent): void {
    const currentIndex = this._orderedComponents.indexOf(event);
    if (
      currentIndex > -1 &&
      currentIndex < this._orderedComponents.length - 1
    ) {
      const nextComponent = this._orderedComponents[currentIndex + 1];
      if (nextComponent) {
        nextComponent.inputElement.nativeElement.focus();
      }
    }
  }

  protected onPreviousRequested(event: DatetimeNumberComponent): void {
    const currentIndex = this._orderedComponents.indexOf(event);
    if (currentIndex > 0) {
      const previousComponent = this._orderedComponents[currentIndex - 1];
      previousComponent.inputElement.nativeElement.focus();
    }
  }

  private _convertPropertiesToDate(): Date {
    const yearNumber = this.year ? this.year : this.minYearValue;
    const date = new Date(
      yearNumber,
      this.month ? this.month - 1 : this.minMonthValue - 1,
      this.date ? this.date : this.minDateValue,
      this.hour ? this.hour : this.minHourValue,
      this.minute ? this.minute : this.minMinuteValue,
      this.second ? this.second : this.minSecondValue
    );

    //In JavaScript, when you pass a year value between 0 and 99 to the Date constructor, it's interpreted as a year in the 20th century (1900-1999).
    //This behavior is a legacy feature designed for compatibility with older features of JavaScript.
    //setFullYear will prevent this behavior.
    if (yearNumber >= 0 && yearNumber < 100) {
      date.setFullYear(yearNumber);
    }

    return date;
  }

  protected openDatePicker(): void {
    if (!this.empty) {
      const localDate = this._convertPropertiesToDate();
      this._datetimePicker.nativeElement.value =
        this._convertDateToIsoString(localDate);
    } else {
      this._datetimePicker.nativeElement.value = '';
    }

    if (this.max) {
      this._datetimePicker.nativeElement.setAttribute(
        'max',
        this._convertDateToIsoString(this.max)
      );
    }
    if (this.min) {
      this._datetimePicker.nativeElement.setAttribute(
        'min',
        this._convertDateToIsoString(this.min)
      );
    }

    this._datetimePicker.nativeElement.showPicker();
  }

  private _convertDateToIsoString(localDate: Date): string {
    const timezoneOffset = localDate.getTimezoneOffset() * 60000;
    const date = new Date(localDate.getTime() - timezoneOffset);

    if (this.type == 'date') {
      return date.toISOString().substring(0, 10);
    } else if (this.type == 'datetime') {
      if (this.timeFormat == 'HH:mm:ss') {
        return date.toISOString().substring(0, 19);
      } else {
        return date.toISOString().substring(0, 16);
      }
    } else {
      if (this.timeFormat == 'HH:mm:ss') {
        return date.toISOString().substring(11, 19);
      } else {
        return date.toISOString().substring(11, 16);
      }
    }
  }

  protected onPickerSelected(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    if (value) {
      if (value.includes('T')) {
        const [datePart, timePart] = value.split('T');
        [this.year, this.month, this.date] = datePart
          .split('-')
          .map(x => (x ? parseInt(x) : null));
        [this.hour, this.minute, this.second] = timePart
          .split(':')
          .map(x => (x ? parseInt(x) : null));
        if (this.timeFormat == 'HH:mm:ss' && !this.second) {
          this.second = 0;
        }
      } else if (value.includes('-')) {
        [this.year, this.month, this.date] = value
          .split('-')
          .map(x => (x ? parseInt(x) : null));
      } else if (value.includes(':')) {
        [this.hour, this.minute, this.second] = value
          .split(':')
          .map(x => (x ? parseInt(x) : null));
        if (this.timeFormat == 'HH:mm:ss' && !this.second) {
          this.second = 0;
        }
      }
    }
    this._elementRef.nativeElement.querySelector('input').focus();
  }

  private _setMinProperties(): void {
    this.minYearValue = this.min ? this.min.getFullYear() : 1;
    this.minMonthValue =
      this._minYearIsReached() && this.min ? this.min.getMonth() + 1 : 1;
    this.minDateValue =
      this._minMonthIsReached() && this.min ? this.min.getDate() : 1;
    this.minHourValue =
      this._minDateIsReached() && this.min ? this.min.getHours() : 0;
    this.minMinuteValue =
      this._minHourIsReached() && this.min ? this.min.getMinutes() : 0;
    this.minSecondValue =
      this._minMinutesIsReached() && this.min ? this.min.getSeconds() : 0;
  }

  private _setMaxProperties(): void {
    this.maxYearValue = this.max ? this.max.getFullYear() : 9999;
    this.maxMonthValue =
      this._maxYearIsReached() && this.max ? this.max.getMonth() + 1 : 12;
    this.maxDateValue =
      this._maxMonthIsReached() && this.max
        ? this.max.getDate()
        : this._getMaxDateForYearAndMonth();
    this.maxHourValue =
      this._maxDateIsReached() && this.max ? this.max.getHours() : 23;
    this.maxMinuteValue =
      this._maxHourIsReached() && this.max ? this.max.getMinutes() : 59;
    this.maxSecondValue =
      this._maxMinutesIsReached() && this.max ? this.max.getSeconds() : 59;
  }

  private _minYearIsReached(): boolean {
    return !!this.min && !!this.year && this.year == this.minYearValue;
  }
  private _maxYearIsReached(): boolean {
    return !!this.max && !!this.year && this.year == this.maxYearValue;
  }

  private _minMonthIsReached(): boolean {
    return (
      this._minYearIsReached() &&
      !!this.month &&
      this.month == this.minMonthValue
    );
  }
  private _maxMonthIsReached(): boolean {
    return (
      this._maxYearIsReached() &&
      !!this.month &&
      this.month == this.maxMonthValue
    );
  }

  private _minDateIsReached(): boolean {
    return (
      (this._minMonthIsReached() &&
        !!this.date &&
        this.date == this.minDateValue) ||
      (!!this.min && this.type == 'time')
    );
  }
  private _maxDateIsReached(): boolean {
    return (
      (this._maxMonthIsReached() &&
        !!this.date &&
        this.date == this.maxDateValue) ||
      (!!this.max && this.type == 'time')
    );
  }

  private _minHourIsReached(): boolean {
    return (
      this._minDateIsReached() && !!this.hour && this.hour == this.minHourValue
    );
  }
  private _maxHourIsReached(): boolean {
    return (
      this._maxDateIsReached() && !!this.hour && this.hour == this.maxHourValue
    );
  }

  private _minMinutesIsReached(): boolean {
    return (
      this._minHourIsReached() &&
      !!this.minute &&
      this.minute == this.minMinuteValue
    );
  }
  private _maxMinutesIsReached(): boolean {
    return (
      this._maxHourIsReached() &&
      !!this.minute &&
      this.minute == this.maxMinuteValue
    );
  }

  private _getMaxDateForYearAndMonth(): number {
    if (this.year && this.month) {
      // JavaScript Date months are 0-indexed (January is 0, February is 1, etc.)
      // so we pass month without adjusting to this 0 based index to get the next month.
      // We then select day 0 to get the last day of the previous month.
      const date = new Date(this.year, this.month, 0);
      return date.getDate();
    } else {
      return 31;
    }
  }

  private _year: number | null = null;
  protected get year(): number | null {
    return this._year;
  }
  protected set year(value: number | null) {
    this._year = value;
    this._setMinProperties();
    this._setMaxProperties();
    this._mapPropertiesToValue();
  }

  private _month: number | null = null;
  protected get month(): number | null {
    return this._month;
  }
  protected set month(value: number | null) {
    this._month = value;
    this._setMinProperties();
    this._setMaxProperties();
    this._mapPropertiesToValue();
  }

  private _date: number | null = null;
  protected get date(): number | null {
    return this._date;
  }
  protected set date(value: number | null) {
    this._date = value;
    this._setMinProperties();
    this._setMaxProperties();
    this._mapPropertiesToValue();
  }

  private _hour: number | null = null;
  protected get hour(): number | null {
    return this._hour;
  }
  protected set hour(value: number | null) {
    this._hour = value;
    this._setMinProperties();
    this._setMaxProperties();
    this._mapPropertiesToValue();
  }

  private _minute: number | null = null;
  protected get minute(): number | null {
    return this._minute;
  }
  protected set minute(value: number | null) {
    this._minute = value;
    this._setMinProperties();
    this._setMaxProperties();
    this._mapPropertiesToValue();
  }

  private _second: number | null = null;
  protected get second(): number | null {
    return this._second;
  }
  protected set second(value: number | null) {
    this._second = value;
    this._mapPropertiesToValue();
  }

  protected maxYearValue = 9999;
  protected maxMonthValue = 12;
  protected maxDateValue = 31;
  protected maxHourValue = 23;
  protected maxMinuteValue = 59;
  protected maxSecondValue = 59;

  protected minYearValue = 0;
  protected minMonthValue = 1;
  protected minDateValue = 1;
  protected minHourValue = 0;
  protected minMinuteValue = 0;
  protected minSecondValue = 0;

  protected static nextId = 0;
  @HostBinding()
  public id = `ui-datetime-local-${DatetimeComponent.nextId++}`;

  public readonly placeholder: string = '';

  public focused = false;

  protected onFocusIn(_: FocusEvent): void {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }
  protected onFocusOut(event: FocusEvent): void {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  public get empty(): boolean {
    return (
      this.year == null &&
      this.month == null &&
      this.date == null &&
      this.hour == null &&
      this.minute == null &&
      this.second == null
    );
  }

  @HostBinding('class.floating')
  public get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  public readonly autofilled!: boolean;
  public readonly controlType!: string;

  @Input()
  public disabled = false;

  public get errorState(): boolean {
    return (
      this.ngControl &&
      this.ngControl.errors !== null &&
      !!this.ngControl.touched
    );
  }

  public readonly required!: boolean;
  public readonly userAriaDescribedBy!: string;
  // eslint-disable-next-line rxjs/suffix-subjects
  public stateChanges = new Subject<void>();

  public onContainerClick(): void {
    if (this.empty && !this.focused) {
      this._orderedComponents[0].inputElement.nativeElement.focus();
    }
  }

  public setDescribedByIds: (_: string[]) => void = () => {};

  public ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  public registerOnChange(fn: (_: Date | null) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.stateChanges.next();
  }

  private _onChange: (_: Date | null) => void = () => {};
  private _onTouched: () => void = () => {};

  public writeValue(obj: Date | null): void {
    this.value = obj;
    this._mapDateToProperties(this.value);
  }

  private _mapPropertiesToValue(): void {
    if (
      this.type == 'datetime' &&
      this.year !== null &&
      this.month !== null &&
      this.date !== null &&
      this.hour !== null &&
      this.minute !== null &&
      (this.timeFormat == 'HH:mm' || this.second !== null)
    ) {
      this.value = this._convertPropertiesToDate();
    } else if (
      this.type == 'date' &&
      this.year !== null &&
      this.month !== null &&
      this.date !== null
    ) {
      this.value = this._convertPropertiesToDate();
    } else if (
      this.type == 'time' &&
      this.hour !== null &&
      this.minute !== null &&
      (this.timeFormat == 'HH:mm' || this.second !== null)
    ) {
      this.value = this._convertPropertiesToDate();
    } else {
      this.value = null;
    }
    this._onChange(this.value);
    this._onTouched();
  }
}
