import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { SidePanelToggleService } from '../../services/behavior/sidepanel-toggle.service';

@Component({
  selector: 'ui-shell-main',
  template: `
    <div
      [ngClass]="{
        'main-panel': (sidepanelIsOpen$ | async) !== true,
        'main-panel-with-sidepanel': (sidepanelIsOpen$ | async)
      }"
      (scroll)="onScroll($event)"
      #main
    >
      <ng-container
        *ngIf="(sidepanelIsOpen$ | async) !== true; else withSidePanel"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </ng-container>
      <ng-template #withSidePanel>
        <div class="main-panel" (scroll)="onScroll($event)">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
        <ui-shell-sidepanel>
          <ng-content select="[sidePanel]"></ng-content>
        </ui-shell-sidepanel>
      </ng-template>
    </div>
    <ng-template #content>
      <ng-content></ng-content>
    </ng-template>
  `,
  styleUrls: ['shell-main.component.scss']
})
export class ShellMainComponent {
  public sidepanelIsOpen$: Observable<boolean>;
  @ViewChild('main') public mainElement!: ElementRef;

  constructor(
    private _sidePanelToggleService: SidePanelToggleService,
    private _renderer: Renderer2
  ) {
    this.sidepanelIsOpen$ = this._sidePanelToggleService.open$;
  }

  public onScroll($event: Event): void {
    const className = 'is-scrolling';
    if (this.mainElement) {
      if ($event.target instanceof HTMLElement && $event.target.scrollTop > 0) {
        this._renderer.addClass(this.mainElement.nativeElement, className);
      } else {
        this._renderer.removeClass(this.mainElement.nativeElement, className);
      }
    }
  }
}
