import { Injectable } from '@angular/core';
import { Brand, ThemeAndBrandService } from '@onyx/core';
import { Observable, map } from 'rxjs';
import { ContactItems } from '../components/corporate-branding/corporate-branding.component';
import { brandDetailsList } from '../config/brand-details';

export interface BrandDetails {
  fullName: string;
  productName: string;
  productNameHTML: string;
  siteUrl?: string;
  termsUrl?: string;
  contactItems?: ContactItems;
}

@Injectable({
  providedIn: 'root'
})
export class BrandDetailsService {
  public brandDetails$: Observable<BrandDetails>;

  constructor(_themeAndBrandService: ThemeAndBrandService) {
    this.brandDetails$ = _themeAndBrandService.brand$.pipe(
      map(brand => this._getBrandDetails(brand))
    );
  }

  private _getBrandDetails(brand: Brand): BrandDetails {
    const details = brandDetailsList[brand];
    if (!details) {
      return { fullName: '', productName: '', productNameHTML: '' };
    }
    return details;
  }
}
