import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';
import { Portal } from '../../../services/bridge/base-bridge.service';
import { PageActionsBridgeService } from '../../../services/bridge/page-actions-bridge.service';
import {
  BaseNavigationItem,
  NavigationItem
} from '../../navbar/nav-item/navigation-item.model';

@Component({
  selector: 'ui-shell-topbar-menu[title]',
  template: `
    <div
      class="title-wrapper"
      [class.clickable]="menuItemsPresent"
      (click)="toggleMenuChildOverlayer()"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
    >
      <span class="title">{{ title }}</span>
      <mat-icon *ngIf="menuItemsPresent">arrow_drop_down</mat-icon>
    </div>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="menuChildsCardIsOpen"
      [cdkConnectedOverlayOffsetX]="-8"
      [cdkConnectedOverlayOffsetY]="8"
      [cdkConnectedOverlayViewportMargin]="48"
      cdkConnectedOverlayMinWidth="280px"
      cdkConnectedOverlayPanelClass="topbar-menu-overlay"
      cdkConnectedOverlayHasBackdrop
      cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
      cdkConnectedOverlayFlexibleDimensions
      (backdropClick)="toggleMenuChildOverlayer()"
    >
      <div *ngIf="hasPageActions" (click)="toggleMenuChildOverlayer()">
        <ng-template [cdkPortalOutlet]="portal$ | async"></ng-template>
      </div>
      <div *ngIf="hasPageActions && menuItems.length > 0" class="divider"></div>
      <div *ngIf="menuItems.length > 0" class="menu-items">
        <ui-shell-nav-item
          *ngFor="let item of menuItems; trackBy: trackByKey"
          [id]="item.key || ''"
          [link]="buildLink(item)"
          [exactMatch]="item.exactMatch"
          [label]="item.label"
          [disabled]="item.disabled"
          [additionalTooltipText]="item.tooltip"
          [badge]="item.badge"
          [preserveQueryParams]="item.preserveQueryParams"
          [preserveFragment]="item.preserveFragment"
          (click)="toggleMenuChildOverlayer()"
        ></ui-shell-nav-item>
      </div>
    </ng-template>
  `,
  styleUrls: ['./topbar-menu.component.scss']
})
export class TopbarMenuComponent implements OnDestroy, OnInit {
  @Input() public title?: string | null;
  @Input() public activeItem?: NavigationItem | null;

  private _onDestroySubject = new ReplaySubject<void>(1);

  public portal$!: Observable<Portal>;

  public hasPageActions = false;
  public menuChildsCardIsOpen = false;

  constructor(
    private _pageActionsBridgeService: PageActionsBridgeService,
    private _eRef: ElementRef
  ) {}

  public ngOnInit(): void {
    this.portal$ = this._pageActionsBridgeService.portal$;
    this._pageActionsBridgeService.activePageItems$
      .pipe(takeUntil(this._onDestroySubject))
      .subscribe(x => {
        this.hasPageActions = x > 0;
      });
  }

  public get menuItems(): NavigationItem[] {
    return this.activeItem?.children ?? [];
  }

  public get menuItemsPresent(): boolean {
    return this.menuItems.length > 0 || this.hasPageActions;
  }

  public buildLink(child: NavigationItem): string[] {
    if (typeof child.link === 'string') {
      child.link = [child.link];
    }
    return (this.activeItem?.link ?? []).concat(child.link ?? []);
  }

  public trackByKey(_index: number, item: BaseNavigationItem): string {
    return item.key;
  }

  public toggleMenuChildOverlayer(): void {
    if (!this.menuItemsPresent) return;
    this.menuChildsCardIsOpen = !this.menuChildsCardIsOpen;
  }

  private _closeMenuChildOverlayer(): void {
    this.menuChildsCardIsOpen = false;
  }

  public ngOnDestroy(): void {
    this._onDestroySubject.next();
  }

  @HostListener('document:click', ['$event'])
  private _click(event: MouseEvent): void {
    if (!this._eRef.nativeElement.contains(event.target)) {
      this._closeMenuChildOverlayer();
    }
  }
}
