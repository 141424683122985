import { booleanAttribute, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveNavigationItemService } from '../../services/active-navigation-item.service';
import { PageBackLink } from '../../services/page-back-link.model';
import { PageTitleService } from '../../services/page-title.service';
import { NavigationItem } from '../navbar/nav-item/navigation-item.model';

@Component({
  selector: 'ui-shell-topbar',
  template: `
    <div class="left-container">
      <ng-container *ngIf="backLink$ | async as backLink">
        <span
          class="back clickable"
          [routerLink]="backLink.link"
          data-testid="back-link"
        >
          {{ backLink.label }}
        </span>
        <mat-icon>chevron_right</mat-icon>
      </ng-container>
      <ui-shell-topbar-menu
        [title]="title$ | async"
        [activeItem]="activeItem$ | async"
      ></ui-shell-topbar-menu>
    </div>
    <div class="right-container">
      <ng-content select="[icons]"></ng-content>
      <ng-content select="[site]"></ng-content>
      <ng-content select="[darkMode]"></ng-content>
      <ng-content select="[profile]"></ng-content>
      <div *ngIf="!hideLogo" class="brand-logo" data-testid="logo"></div>
    </div>
  `,
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
  @Input({ transform: booleanAttribute }) public hideLogo = false;

  public title$: Observable<string>;
  public backLink$: Observable<PageBackLink | undefined | null>;
  public activeItem$: Observable<NavigationItem | undefined>;

  constructor(
    pageTitleService: PageTitleService,
    activeNavigationItemService: ActiveNavigationItemService
  ) {
    this.title$ = pageTitleService.pageTitle$;
    this.backLink$ = pageTitleService.currentBackLink$;
    this.activeItem$ = activeNavigationItemService.activeItem$;
  }
}
