import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-shell-panel-title',
  template: `
    <div class="prefix" data-testid="prefix">
      <ng-content select="[prefix]"></ng-content>
    </div>
    <div class="title" data-testid="title">{{ title }}</div>
    <div class="suffix" data-testid="suffix">
      <ng-content select="[suffix]"></ng-content>
    </div>
  `,
  styleUrls: ['./panel-title.component.scss']
})
export class PanelTitleComponent {
  @Input() public title = '';
}
