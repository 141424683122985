import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { BaseBridgeService } from './base-bridge.service';

@Injectable({
  providedIn: 'root'
})
export class PageActionsBridgeService extends BaseBridgeService {
  private _activePageItemsSubject = new ReplaySubject<number>(1);

  public readonly activePageItems$ =
    this._activePageItemsSubject.asObservable();

  public setActivePageItems(activePageItems: number): void {
    this._activePageItemsSubject.next(activePageItems);
  }
}
