import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-hotpick',
  template: `
    <div class="hotpick" [class.active]="active">
      <div class="label-container" *ngIf="hasLabel">
        <div class="label">{{ label }}</div>
        <mat-icon *ngIf="hasIcon && !svgIcon">{{ icon }}</mat-icon>
        <mat-icon *ngIf="hasIcon && svgIcon" svgIcon="{{ icon }}"></mat-icon>
      </div>
      <div class="value-container">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./hotpick.component.scss']
})
export class HotpickComponent {
  @Input() public hasLabel = true;
  @Input() public label = '';
  @Input() public active = false;
  @Input() public hasIcon = false;
  @Input() public svgIcon = false;
  @Input() public icon = '';
}
