import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ThemeAndBrandService } from '@onyx/core';

@Component({
  selector: 'ui-shell-dark-mode-toggle',
  template: `
    <mat-slide-toggle
      [checked]="darkMode"
      (change)="onChangeTheme($event)"
      data-testid="toggle"
    >
      <ng-content></ng-content>
    </mat-slide-toggle>
  `
})
export class DarkModeToggleComponent implements OnChanges {
  @Input() public darkMode = false;
  @Output() public darkModeChange = new EventEmitter<boolean>();

  constructor(private _themeAndBrandService: ThemeAndBrandService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['darkMode']) {
      this._changeTheme(this.darkMode);
    }
  }

  public onChangeTheme(theme: MatSlideToggleChange): void {
    this._changeTheme(theme.checked);
    this.darkModeChange.emit(theme.checked);
  }

  private _changeTheme(darkMode: boolean): void {
    this._themeAndBrandService.changeTheme(darkMode);
  }
}
