/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import {
  combineLatest,
  filter,
  Observable,
  ReplaySubject,
  startWith
} from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ResponsiveService } from '../../../services/responsiveness/responsive.service';

@Component({
  selector: 'ui-shell-nav-item',
  template: `
    <ng-container *ngIf="isMobile$ | async; else desktop">
      <ui-link-button
        class="mobile"
        *ngIf="!small"
        [title]="label || ''"
        [active]="active$ | async"
        [disabled]="disabled"
        [routerLink]="mappedLink"
        [preserveFragment]="preserveFragment"
        [queryParamsHandling]="preserveQueryParams ? 'preserve' : ''"
        (click)="handleClick()"
        size="medium"
        border
        data-testid="mobile-button"
      >
        <mat-icon prefix *ngIf="icon && !customIcon">{{ icon }}</mat-icon>
        <mat-icon prefix *ngIf="icon && customIcon" [svgIcon]="icon"></mat-icon>
        <span
          *ngIf="badge !== undefined"
          suffix
          [matBadge]="badge === 0 ? '&#8203;' : badge"
          matBadgeColor="warn"
          class="standalone brand"
          [class.dot]="badge === 0"
        ></span>
        <mat-icon suffix class="arrow" svgIcon="icon_arrow-line"></mat-icon>
      </ui-link-button>
    </ng-container>
    <ng-template #desktop>
      <ui-icon-button
        *ngIf="small"
        size="large"
        theme="brand"
        [svgIcon]="customIcon"
        [icon]="icon || ''"
        [active]="active$ | async"
        [badge]="badge !== undefined"
        [disabled]="disabled"
        [routerLink]="mappedLink"
        [preserveFragment]="preserveFragment"
        [queryParamsHandling]="preserveQueryParams ? 'preserve' : ''"
        [matTooltip]="tooltip || ''"
        [matTooltipDisabled]="!tooltip || disableTooltip"
        [matTooltipShowDelay]="500"
        matTooltipPosition="right"
        (click)="handleClick()"
        data-testid="icon-button"
      ></ui-icon-button>
      <ui-link-button
        *ngIf="!small"
        [title]="label || ''"
        [active]="active$ | async"
        [disabled]="disabled"
        [routerLink]="mappedLink"
        [preserveFragment]="preserveFragment"
        [queryParamsHandling]="preserveQueryParams ? 'preserve' : ''"
        [matTooltip]="tooltip || ''"
        [matTooltipDisabled]="!tooltip || disableTooltip"
        [matTooltipShowDelay]="500"
        matTooltipPosition="right"
        (click)="handleClick()"
        data-testid="link-button"
      >
        <mat-icon prefix *ngIf="icon && !customIcon">{{ icon }}</mat-icon>
        <mat-icon prefix *ngIf="icon && customIcon" [svgIcon]="icon"></mat-icon>
        <span
          *ngIf="badge !== undefined"
          suffix
          [matBadge]="badge === 0 ? '&#8203;' : badge"
          matBadgeColor="warn"
          class="standalone brand"
          [class.dot]="badge === 0"
        ></span>
        <mat-icon suffix *ngIf="suffixIcon && !customSuffixIcon">{{
          suffixIcon
        }}</mat-icon>
        <mat-icon
          suffix
          *ngIf="suffixIcon && customSuffixIcon"
          [svgIcon]="suffixIcon"
        ></mat-icon>
      </ui-link-button>
    </ng-template>
  `,
  styleUrls: ['nav-item.component.scss']
})
export class NavItemComponent implements OnInit, OnChanges {
  @Input() public link!: string[] | string | undefined | null;
  @Input() public disableLink!: boolean | undefined;
  @Input() public label!: string | undefined | null;
  @Input() public icon!: string | undefined | null;
  @Input() public customIcon!: boolean | undefined;
  @Input() public small!: boolean;
  @Input({ transform: booleanAttribute }) public exactMatch = false;
  @Input() public disableTooltip = false;
  @Input() public suffixIcon?: string;
  @Input({ transform: booleanAttribute }) public customSuffixIcon = false;
  @Input({ transform: booleanAttribute }) public disabled = false;
  @Input() public additionalTooltipText?: string;
  @Input() public badge?: number;
  @Input({ transform: booleanAttribute }) public preserveQueryParams?: boolean =
    false;
  @Input({ transform: booleanAttribute }) public preserveFragment?: boolean =
    false;

  @Output() public linkClick = new EventEmitter<void>();

  private readonly _disabledSubject = new ReplaySubject<boolean>(1);

  public isMobile$: Observable<boolean>;
  public active$: Observable<boolean>;
  public tooltip?: string;
  public mappedLink: string[] | string = [];

  constructor(
    private _router: Router,
    responsiveService: ResponsiveService
  ) {
    this.isMobile$ = responsiveService.responsiveFlags$.pipe(
      map(x => x.mobile)
    );

    const routerUrl$ = _router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => (event as NavigationEnd).urlAfterRedirects),
      startWith(_router.url)
    );
    this.active$ = combineLatest([
      routerUrl$,
      this._disabledSubject.asObservable().pipe(distinctUntilChanged())
    ]).pipe(
      map(([url, disabled]) => (disabled ? false : this._getIsActive(url)))
    );
  }

  public ngOnInit(): void {
    this.tooltip = this._getTooltip();

    this.mappedLink = this._getLink();

    this._disabledSubject.next(this.disabled);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['label'] ||
      changes['small'] ||
      changes['additionalTooltipText']
    ) {
      this.tooltip = this._getTooltip();
    }

    if (changes['link'] || changes['disableLink'] || changes['disabled']) {
      this.mappedLink = this._getLink();
    }

    if (changes['disabled']) {
      this._disabledSubject.next(this.disabled);
    }
  }

  private _getLink(): string | string[] {
    if (this.disableLink || this.disabled || !this.link) {
      return [];
    }
    if (typeof this.link === 'string') {
      return `/${this.link}`;
    }
    return ['/', ...this.link].filter(item => item !== '');
  }

  private _getTooltip(): string | undefined {
    if (!this.label && !this.additionalTooltipText) {
      return undefined;
    }

    if (this.label && this.small) {
      return this.additionalTooltipText
        ? `${this.label} - ${this.additionalTooltipText}`
        : this.label;
    }

    return this.additionalTooltipText;
  }

  private _getIsActive(url: string): boolean {
    if (!this.link) {
      return false;
    }

    let link: string;
    if (Array.isArray(this.link)) {
      link = this.link.join('/');
    } else {
      link = this.link;
    }
    const cleanUrl = this._getUrlWithoutParams(url).substring(1);

    if (this.exactMatch) {
      return cleanUrl === link;
    }
    if (link === '' && cleanUrl !== '') {
      return false;
    }
    const splitUrl = cleanUrl.split('/');
    const splitLink = link.split('/');
    return this._arrayStartsWith(splitUrl, splitLink);
  }

  private _getUrlWithoutParams(url: string): string {
    const urlTree = this._router.parseUrl(url);
    const segments = urlTree.root.children[PRIMARY_OUTLET]?.segments ?? [];
    return segments.reduce((prev, curr) => `${prev}/${curr.path}`, '');
  }

  private _arrayStartsWith<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length < arr2.length) {
      return false;
    }
    return arr1
      .slice(0, arr2.length)
      .every((element, index) => element === arr2[index]);
  }

  public handleClick(): void {
    if (this.disabled) {
      return;
    }
    this.linkClick.emit();
  }
}
