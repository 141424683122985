import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'ui-button',
  template: `
    <div [matTooltip]="tooltip" [matTooltipDisabled]="tooltipDisabled">
      <button
        mat-stroked-button
        color="primary"
        class="icon_left"
        (click)="!disabled && clicked.emit($event)"
        [disabled]="disabled"
      >
        <mat-icon *ngIf="!!icon" [svgIcon]="icon"></mat-icon>
        <ng-content></ng-content>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, MatTooltipModule, MatIconModule, CommonModule]
})
export class ButtonComponent {
  /* Event emitted when the button has been clicked */
  @Output() public clicked = new EventEmitter<MouseEvent>();

  /* The message to be displayed in the tooltip  */
  @Input() public tooltip = '';

  /* Disables the display of the tooltip. */
  @Input() public tooltipDisabled = false;

  /* Disables the button */
  @Input() public disabled = false;

  /* Optional name of the icon to display */
  @Input() public icon = '';
}
