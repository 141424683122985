import { booleanAttribute, Component, Input } from '@angular/core';
import { UserProfileInfo } from '../profilecard/user-profile-info.model';

@Component({
  selector: 'ui-shell-avatar',
  template: `
    <div class="avatar" [class.clickable]="clickable">
      <span *ngIf="shortCut.length > 0; else icon">{{
        shortCut | uppercase
      }}</span>
      <ng-template #icon>
        <mat-icon>person</mat-icon>
      </ng-template>
    </div>
  `,
  styleUrls: ['avatar.component.scss']
})
export class AvatarComponent {
  @Input({ required: true }) public userInfo!: UserProfileInfo;
  @Input({ transform: booleanAttribute }) public clickable?: boolean = false;

  public get shortCut(): string {
    if (this.userInfo && (this.userInfo.firstName || this.userInfo.lastName)) {
      const { firstName, lastName } = this.userInfo;
      return (firstName ?? '')?.charAt(0) + (lastName ?? '')?.charAt(0);
    }
    return '';
  }
}
