import { InjectionToken } from '@angular/core';
import {
  ExtendedNavigationItem,
  NavigationItem
} from './nav-item/navigation-item.model';

export interface NavigationConfig {
  navigationItems: NavigationItem[];
  extendedNavigationItems: ExtendedNavigationItem[];
}

export const NAVIGATION_CONFIG = new InjectionToken<NavigationConfig>(
  'NAVIGATION_CONFIG'
);

export const DEFAULT_TITLE = new InjectionToken<string>('DEFAULT_TITLE');
