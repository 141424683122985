import { Injectable } from '@angular/core';
import { BaseIntl } from '../../services/base-intl';

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsIntl extends BaseIntl {
  public settingsLabel = 'Settings';

  public appSettingsLabel = 'Application settings';

  public userPreferencesLabel = 'User preferences';

  public closeLabel = 'Close';

  public languageLabel = 'Language';

  public preferredLanguageLabel = 'Preferred Language';

  public screenSettings = {
    title: 'Screen Settings',
    dark: 'Dark',
    light: 'Light'
  };
}
