import { CdkPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ContentChildren,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { SettingsPanelBridgeService } from '../../../services/bridge/settings-panel-bridge.service';
import { USER_PREFERENCES_KEY } from '../constants';
import { GlobalSettingsModalComponent } from '../global-setting-modal/global-settings-modal.component';
import { GlobalSettingsIntl } from '../global-settings-intl';
import { ShellGlobalSettingPanelComponent } from '../global-settings-panel/global-setting-panel.component';

@Component({
  selector: 'ui-shell-global-settings',
  template: ` <ng-template #preferenceTempl>
      <ui-shell-global-setting-panel
        [key]="USER_PREFERENCES_KEY"
        [title]="intl.userPreferencesLabel"
        icon="icon_settings"
        [active]="preferenceActive"
        [customIcon]="true"
        #preference
      >
        <ng-content
          select="ui-shell-global-settings-user-preferences"
        ></ng-content>
      </ui-shell-global-setting-panel>
    </ng-template>
    <ng-container *cdkPortal>
      <ng-container [ngTemplateOutlet]="preferenceTempl"></ng-container>
      <ng-content select="ui-shell-global-setting-panel"></ng-content>
    </ng-container>`
})
export class ShellGlobalSettingsComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  protected readonly USER_PREFERENCES_KEY = USER_PREFERENCES_KEY;
  private readonly _destroySubject = new Subject<void>();

  @ViewChild(CdkPortal, { static: true }) public portalContent!: CdkPortal;
  @ContentChildren(ShellGlobalSettingPanelComponent)
  public settingsPanels!: QueryList<ShellGlobalSettingPanelComponent>;

  public preferenceActive = false;

  private _settingsModalRef?: MatDialogRef<GlobalSettingsModalComponent>;

  constructor(
    public intl: GlobalSettingsIntl,
    private _settingsPanelBridgeService: SettingsPanelBridgeService,
    private _dialog: MatDialog
  ) {
    this._monitorSettingsQueryParam();
  }

  public ngOnInit(): void {
    this._settingsPanelBridgeService.enableSettingsAction();
  }

  public ngAfterViewInit(): void {
    const settingsPanel = this.settingsPanels
      ? this.settingsPanels.toArray()
      : [];
    this._settingsPanelBridgeService.settingPanelProperties = settingsPanel;
    this._settingsPanelBridgeService.activePanel$.subscribe(key => {
      settingsPanel.forEach(panel => {
        panel.active = panel.key === key;
      });
      this.preferenceActive = USER_PREFERENCES_KEY === key;
    });

    this._settingsPanelBridgeService.setPortal(this.portalContent);
  }

  public ngOnDestroy(): void {
    if (this.portalContent && this.portalContent.isAttached) {
      this.portalContent.detach();
    }
    this._settingsPanelBridgeService.disableSettingsAction();

    this._destroySubject.next();
    this._destroySubject.complete();
  }

  private _monitorSettingsQueryParam(): void {
    this._settingsPanelBridgeService.activePanel$
      .pipe(takeUntil(this._destroySubject))
      .subscribe(panel => {
        if (!panel) {
          this._closeSettingsModal();
          return;
        }

        this._openSettingsModal();
      });
  }

  private _openSettingsModal(): void {
    if (this._settingsModalRef) {
      return;
    }
    this._settingsModalRef = this._dialog.open(GlobalSettingsModalComponent, {
      panelClass: 'large',
      disableClose: true
    });
  }

  private _closeSettingsModal(): void {
    this._settingsModalRef?.close();
    this._settingsModalRef = undefined;
  }
}
