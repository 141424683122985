import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-setting-category-item',
  template: `
    {{ label }}
    <ng-content></ng-content>
  `,
  styleUrl: './setting-category-item.component.scss'
})
export class SettingCategoryItemComponent {
  @Input() public label?: string;
}
