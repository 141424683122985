import { ConnectedPosition } from '@angular/cdk/overlay';
import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ProfilecardToggleService } from '../../services/behavior/profilecard-toggle.service';
import { ResponsiveService } from '../../services/responsiveness/responsive.service';
import { UserProfileInfo } from '../profilecard';

@Component({
  selector: 'ui-shell-profile[userInfo]',
  template: `
    <ui-shell-avatar
      [userInfo]="userInfo"
      (click)="toggleProfileCard()"
      clickable
      data-testid="avatar"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
    ></ui-shell-avatar>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="(open$ | async) ?? false"
      [cdkConnectedOverlayPositions]="
        (isMobile$ | async) ? overlayPositionsMobile : overlayPositions
      "
      [cdkConnectedOverlayOffsetY]="8"
      cdkConnectedOverlayPanelClass="profile-card-overlay"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      (backdropClick)="toggleProfileCard()"
    >
      <ui-shell-profile-card
        [userInfo]="userInfo"
        [class.mobile]="isMobile$ | async"
        data-testid="profile-card"
      >
        <ng-container action-items>
          <ng-content select="ui-shell-action"></ng-content>
        </ng-container>
      </ui-shell-profile-card>
    </ng-template>
  `,
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  @Input() public userInfo!: UserProfileInfo;

  public isMobile$: Observable<boolean>;
  public open$: Observable<boolean>;
  public overlayPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
      offsetX: -8
    },
    {
      originY: 'bottom',
      originX: 'end',
      overlayY: 'top',
      overlayX: 'end',
      offsetX: 8
    }
  ];
  public overlayPositionsMobile: ConnectedPosition[] = [
    {
      originY: 'top',
      originX: 'start',
      overlayY: 'bottom',
      overlayX: 'start',
      offsetY: -20
    },
    {
      originY: 'top',
      originX: 'end',
      overlayY: 'bottom',
      overlayX: 'end',
      offsetY: -20
    }
  ];

  @HostListener('document:click', ['$event'])
  public click(event: MouseEvent): void {
    if (!this._eRef.nativeElement.contains(event.target)) {
      this.profileCardToggleService.close();
    }
  }

  constructor(
    public profileCardToggleService: ProfilecardToggleService,
    private _eRef: ElementRef,
    responsiveService: ResponsiveService
  ) {
    this.isMobile$ = responsiveService.responsiveFlags$.pipe(
      map(flags => flags.mobile)
    );
    this.open$ = this.profileCardToggleService.open$;
  }

  public toggleProfileCard(): void {
    this.profileCardToggleService.toggle();
  }
}
