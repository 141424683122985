import { Injectable } from '@angular/core';
import { BaseIntl } from '../services/base-intl';

@Injectable({
  providedIn: 'root'
})
export class ShellIntl extends BaseIntl {
  public closeMenuLabel = 'Close menu';

  public openMenuLabel = 'Open menu';

  public byLabel = 'By';

  public settingsLabel = 'Settings';

  public termsLabels = {
    contact: 'Contact',
    productOf: 'is a product of',
    rightsReserved: 'All rights reserved',
    usage: 'By using this application you acknowledge the',
    termsConditions: 'Terms & Conditions of'
  };
}
