import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-flowpanel',
  template: `
    <div class="flowpanel" [ngClass]="show ? 'show' : 'hidden'">
      <ui-icon-button
        icon="close"
        type="tertiary"
        aria-label="Close flowpanel"
        (click)="clickEvent()"
      >
      </ui-icon-button>
      <ng-content select="ui-flowpanel-header"></ng-content>
      <ng-template #content>
        <ng-content></ng-content>
      </ng-template>
      <div
        *ngIf="hasHeader"
        class="flowpanel-container hasHeader-{{ padding }}"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
      <div
        *ngIf="!hasHeader"
        class="flowpanel-container noHeader-{{ padding }}"
      >
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  `,
  styleUrls: ['./flowpanel.component.scss']
})
export class FlowpanelComponent {
  @Input() public padding: FlowpanelComponentPadding = 'default';
  @Input() public hasHeader = true;
  @Input() public show = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onClose: EventEmitter<boolean> = new EventEmitter();

  // TODO DEV: If the flowpanel is allready open, and clicked to open, switch content within the flowpanel

  public clickEvent(): void {
    this.show = !this.show;
    this.onClose.emit(this.show);
  }
}

@Component({
  selector: 'ui-flowpanel-header',
  template: ` <ui-icon-button
      svgIcon
      icon="icon_arrow-line"
      theme="tertiary"
      aria-label="Close menu"
      class="back {{ padding }}"
      *ngIf="levelDown"
    >
      <!--TODO DEV: on click -> go back to previous flowpanel content-->
    </ui-icon-button>
    <ng-content></ng-content>`,
  styleUrls: ['flowpanel-header.component.scss']
})
export class FlowpanelHeaderComponent {
  @Input() public padding: FlowpanelComponentPadding = 'default';
  @Input() public levelDown = false;
}

export type FlowpanelComponentPadding = 'default' | 'small' | 'none';
