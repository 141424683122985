import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ui-shell-content-toolbar',
  template: `
    <div class="toolbar">
      <div class="left-side">
        <ng-content select="[toolbar]"></ng-content>
      </div>

      <div class="right-side">
        <ng-content select="[utilities]"></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./content-toolbar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ContentToolbarComponent {}
