import {
  booleanAttribute,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'ui-shell-panel',
  template: `
    <div
      class="shell-panel background-{{ background }} padding-{{ padding }}"
      [class.border]="border"
      (scroll)="onScroll($event)"
      #panel
    >
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./shell-panel.component.scss']
})
export class ShellPanelComponent {
  @Input({ transform: booleanAttribute }) public border? = true;
  @Input() public padding?: ShellPanelPadding = 'large';
  @Input() public background?: ShellPanelBackground = 'panel-primary';

  @ViewChild('panel') public panelElement?: ElementRef;

  constructor(private _renderer: Renderer2) {}

  public onScroll($event: Event): void {
    const className = 'is-scrolling';
    if (this.panelElement) {
      if ($event.target instanceof HTMLElement && $event.target.scrollTop > 0) {
        this._renderer.addClass(this.panelElement.nativeElement, className);
      } else {
        this._renderer.removeClass(this.panelElement.nativeElement, className);
      }
    }
  }
}

export type ShellPanelPadding = 'none' | 'medium' | 'large';
export type ShellPanelBackground = 'none' | 'panel-primary' | 'panel-secondary';
