import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@onyx/core';

import { MatDialogModule } from '@angular/material/dialog';
import { ActionComponent } from './components/action/action.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { CorporateBrandingComponent } from './components/corporate-branding/corporate-branding.component';
import { DarkModeToggleComponent } from './components/dark-mode-toggle/dark-mode-toggle.component';
import { LinkComponent } from './components/link/link.component';
import { PanelTitleComponent } from './components/panel-title/panel-title.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileCardComponent } from './components/profilecard';
import { SiteComponent } from './components/site';
import { MobileSiteSelectorComponent } from './components/site/mobile-site-selector/mobile-site-selector.component';
import { SiteSearchComponent } from './components/site/site-search/site-search.component';
import { SiteSelectorComponent } from './components/site/site-selector/site-selector.component';
import { TrafficLightComponent } from './components/site/traffic-light/traffic-light.component';
import { MobileMenuToggleService } from './services/behavior/mobile-menu-toggle.service';
import { SidePanelToggleService } from './services/behavior/sidepanel-toggle.service';
import { PageTitleService } from './services/page-title.service';
import { ResponsiveDirective } from './services/responsiveness/responsive.directive';
import { ResponsiveService } from './services/responsiveness/responsive.service';
import { ContentHeaderComponent } from './shell/content-header/content-header.component';
import { ContentToolbarComponent } from './shell/content-header/content-toolbar/content-toolbar.component';
import { NavExpandableItemComponent } from './shell/navbar/nav-expandable-item/nav-expandable-item.component';
import { NavExtendedpanelComponent } from './shell/navbar/nav-extendedpanel/nav-extendedpanel.component';
import { NavItemComponent } from './shell/navbar/nav-item/nav-item.component';
import { NavLevelpanelComponent } from './shell/navbar/nav-levelpanel/nav-levelpanel.component';
import { NavMobileComponent } from './shell/navbar/nav-mobile/nav-mobile.component';
import { NavigationOverlayComponent } from './shell/navbar/nav-mobile/navigation-overlay/navigation-overlay.component';
import { NavbarComponent } from './shell/navbar/navbar.component';
import {
  DEFAULT_TITLE,
  NAVIGATION_CONFIG,
  NavigationConfig
} from './shell/navbar/navigation.model';
import { ShellGlobalScreenSettingsComponent } from './shell/settings-modal/global-screen-settings/global-screen-settings.component';
import { ScreenSettingsComponent } from './shell/settings-modal/global-screen-settings/screen-settings/screen-settings.component';
import { GlobalSettingsModalComponent } from './shell/settings-modal/global-setting-modal/global-settings-modal.component';
import { ShellGlobalSettingsLanguageComponent } from './shell/settings-modal/global-settings-language/global-settings-language.component';
import { ShellGlobalSettingsNavItemComponent } from './shell/settings-modal/global-settings-nav-item/global-settings-nav-item.component';
import { ShellGlobalSettingPanelComponent } from './shell/settings-modal/global-settings-panel/global-setting-panel.component';
import { ShellGlobalSettingsUserPreferencesComponent } from './shell/settings-modal/global-settings-user-preferences/global-settings-user-preferences.component';
import { ShellGlobalSettingsComponent } from './shell/settings-modal/global-settings/global-settings.component';
import { ShellMainComponent } from './shell/shell-main/shell-main.component';
import { ShellPanelComponent } from './shell/shell-main/shell-panel/shell-panel.component';
import { ShellComponent } from './shell/shell.component';
import { SidepanelContentComponent } from './shell/sidepanel/sidepanel-content.component';
import { SidepanelComponent } from './shell/sidepanel/sidepanel.component';
import { ShellPageTitleComponent } from './shell/topbar/page-title/page-title.component';
import { TopbarMenuComponent } from './shell/topbar/topbar-menu/topbar-menu.component';
import { TopbarMobileComponent } from './shell/topbar/topbar-mobile.component';
import { TopbarComponent } from './shell/topbar/topbar.component';

export * from './components/action/action.component';
export * from './components/avatar/avatar.component';
export * from './components/dark-mode-toggle/dark-mode-toggle.component';
export * from './components/link/link.component';
export * from './components/profile/profile.component';
export * from './components/profilecard';
export * from './components/site';
export * from './components/site/site.component';
export * from './components/site/traffic-light/traffic-light.model';
export * from './models/actions';
export * from './services/behavior/sidepanel-toggle.service';
export * from './services/brand-details.service';
export * from './services/navigation.service';
export * from './services/page-back-link.model';
export * from './services/responsiveness/responsive.service';
export * from './services/settings-action.service';
export * from './shell/content-header/content-header.component';
export * from './shell/content-header/content-toolbar/content-toolbar.component';
export * from './shell/navbar/nav-extendedpanel/nav-extendedpanel.component';
export * from './shell/navbar/nav-item/nav-item.component';
export * from './shell/navbar/nav-item/navigation-item.model';
export * from './shell/navbar/nav-levelpanel/nav-levelpanel.component';
export * from './shell/navbar/nav-mobile/nav-mobile.component';
export * from './shell/navbar/nav-mobile/navigation-overlay/navigation-overlay.component';
export * from './shell/navbar/navbar.component';
export * from './shell/navbar/navigation.model';
export * from './shell/settings-modal/global-screen-settings/global-screen-settings.component';
export * from './shell/settings-modal/global-setting-modal/global-settings-modal.component';
export * from './shell/settings-modal/global-settings-intl';
export * from './shell/settings-modal/global-settings-language/global-settings-language.component';
export * from './shell/settings-modal/global-settings-panel/global-setting-panel.component';
export * from './shell/settings-modal/global-settings-user-preferences/global-settings-user-preferences.component';
export * from './shell/settings-modal/global-settings.service';
export * from './shell/settings-modal/global-settings/global-settings.component';
export * from './shell/shell-intl';
export * from './shell/shell-main/shell-main.component';
export * from './shell/shell-main/shell-panel/shell-panel.component';
export * from './shell/shell.component';
export * from './shell/sidepanel/sidepanel-content.component';
export * from './shell/sidepanel/sidepanel.component';
export * from './shell/topbar/page-title/page-title.component';

@NgModule({
  declarations: [
    ScreenSettingsComponent,
    NavbarComponent,
    TopbarComponent,
    ShellMainComponent,
    SidepanelComponent,
    NavExtendedpanelComponent,
    NavLevelpanelComponent,
    NavMobileComponent,
    AvatarComponent,
    NavItemComponent,
    NavigationOverlayComponent,
    ShellComponent,
    ResponsiveDirective,
    TopbarMenuComponent,
    ProfileCardComponent,
    ContentHeaderComponent,
    ContentToolbarComponent,
    NavExpandableItemComponent,
    SiteSelectorComponent,
    TrafficLightComponent,
    PanelTitleComponent,
    SiteComponent,
    SiteSearchComponent,
    MobileSiteSelectorComponent,
    LinkComponent,
    ProfileComponent,
    DarkModeToggleComponent,
    CorporateBrandingComponent,
    ShellPageTitleComponent,
    TopbarMobileComponent,
    SidepanelContentComponent,
    ActionComponent,
    ShellGlobalSettingsUserPreferencesComponent,
    GlobalSettingsModalComponent,
    ShellGlobalSettingPanelComponent,
    ShellGlobalSettingsComponent,
    ShellGlobalSettingsNavItemComponent,
    ShellGlobalSettingsLanguageComponent,
    ShellPanelComponent,
    ShellGlobalScreenSettingsComponent
  ],
  providers: [
    ResponsiveService,
    MobileMenuToggleService,
    SidePanelToggleService,
    {
      provide: DEFAULT_TITLE,
      useValue: 'UI Demo'
    },
    {
      provide: NAVIGATION_CONFIG,
      useValue: { navigationItems: [], extendedNavigationItems: [] }
    }
  ],
  imports: [
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatSlideToggleModule,
    RouterModule,
    MatMenuModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    CoreModule,
    PortalModule,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    MatBadgeModule,
    MatDialogModule
  ],
  exports: [
    NavbarComponent,
    ShellMainComponent,
    SidepanelComponent,
    NavExtendedpanelComponent,
    NavLevelpanelComponent,
    NavMobileComponent,
    ProfileCardComponent,
    AvatarComponent,
    NavigationOverlayComponent,
    ShellComponent,
    ContentHeaderComponent,
    ContentToolbarComponent,
    SiteComponent,
    ProfileComponent,
    DarkModeToggleComponent,
    ShellPageTitleComponent,
    SidepanelContentComponent,
    ActionComponent,
    ShellGlobalSettingsUserPreferencesComponent,
    ShellGlobalSettingPanelComponent,
    ShellGlobalSettingsComponent,
    ShellGlobalSettingsLanguageComponent,
    ShellPanelComponent,
    ShellGlobalScreenSettingsComponent
  ]
})
export class ShellModule {
  constructor(pageTitleService: PageTitleService) {
    pageTitleService.listenForBrowserPageTitle();
  }

  public static forRoot(
    navigationConfig: NavigationConfig
  ): ModuleWithProviders<ShellModule> {
    return {
      ngModule: ShellModule,
      providers: [{ provide: NAVIGATION_CONFIG, useValue: navigationConfig }]
    };
  }
}
