import { Component } from '@angular/core';

@Component({
  selector: 'ui-shell-global-settings-user-preferences',
  template: `
    <div class="user-preference-panel">
      <ng-content
        select="ui-shell-global-settings-category-language"
      ></ng-content>
      <ng-content
        select="ui-shell-global-settings-category-screen"
      ></ng-content>
      <ng-content select="ui-setting-category"></ng-content>
    </div>
  `
})
export class ShellGlobalSettingsUserPreferencesComponent {}
