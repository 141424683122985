import {
  Directive,
  ElementRef,
  Input,
  NgModule,
  OnChanges
} from '@angular/core';

//https://stackblitz.com/edit/angular-search-text-highlight-directive?file=src%2Fapp%2Fapp.component.html
@Directive({ selector: '[uiHighlight]' })
export class HighlightDirective implements OnChanges {
  constructor(private _el: ElementRef) {}

  @Input()
  public set content(content: string) {
    this._content = '' + content;
  }
  public get content(): string {
    return this._content;
  }

  @Input()
  public set searchTerm(searchTerm: string) {
    //trim the search and replace spaces with |
    //wich matches each word in the string
    //ex: this|is|match
    //would match all instances of the string "this" "is" and "match"
    //regardless of how many times they occur
    this._searchTerm = searchTerm?.trim()?.replace(/\s/g, '|') ?? '';
    this.matchpattern = new RegExp(this._searchTerm, 'gi');
    this.searchPattern = new RegExp(this._searchTerm, 'i');
  }
  public get searchTerm(): string {
    return this._searchTerm ?? '';
  }

  private _content = '';
  private _searchTerm?: string;

  public spanStart = '<span class="highlighted">';
  public spanEnd = '</span>';
  public searchPattern!: RegExp;
  public matchpattern!: RegExp;

  private _separatedText: string[] = [];
  private _separatedSearchedText: string[] = [];
  private _final = '';

  public ngOnChanges(): void {
    this.highlight();
  }

  public highlight(): void {
    this._final = '';
    if (
      this._searchTerm !== undefined &&
      this._searchTerm != null &&
      this._searchTerm.length > 0 &&
      this._searchTerm[0] !== '.'
    ) {
      this._separatedText = this._content?.split(this.searchPattern);
      this._separatedSearchedText =
        this._content?.match(this.matchpattern) ?? [];

      if (
        this._separatedSearchedText != null &&
        this._separatedSearchedText.length > 0
      ) {
        for (let i = 0; i < this._separatedText.length; i++) {
          if (i <= this._separatedSearchedText.length - 1) {
            this._final +=
              this._separatedText[i] +
              this.spanStart +
              this._separatedSearchedText[i] +
              this.spanEnd;
          } else {
            this._final += this._separatedText[i];
          }
        }
      }
      if (this._final.length > 0) {
        this._el.nativeElement.innerHTML = this._final;
      } else {
        this._el.nativeElement.innerText = this._content;
      }
    } else {
      this._el.nativeElement.innerText = this._content;
    }
  }
}

@NgModule({
  imports: [],
  exports: [HighlightDirective],
  declarations: [HighlightDirective],
  providers: []
})
export class HighlightDirectiveModule {}
