import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  OnDestroy,
  OnInit,
  QueryList
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  map,
  startWith,
  takeUntil
} from 'rxjs';
import { Portal } from '../../../services/bridge/base-bridge.service';
import { SettingsPanelBridgeService } from '../../../services/bridge/settings-panel-bridge.service';
import {
  GlobalSettingsService,
  ResponsiveService
} from '../../../shell.module';
import { USER_PREFERENCES_KEY } from '../constants';
import { GlobalSettingsIntl } from '../global-settings-intl';
import {
  SettingPanelProperties,
  ShellGlobalSettingPanelComponent
} from '../global-settings-panel/global-setting-panel.component';
import { ShellGlobalSettingsUserPreferencesComponent } from '../global-settings-user-preferences/global-settings-user-preferences.component';

@Component({
  selector: 'ui-shell-global-settings-modal',
  template: `
    <div class="global-configuration-settings">
      <div mat-dialog-title>
        <ui-title
          size="large"
          state="bodytext"
          [title]="intl.settingsLabel"
          [iconOnly]="true"
          [svgIcon]="true"
          icon="icon_settings"
        >
        </ui-title>
        <div class="close-btn">
          <ui-icon-button
            icon="close"
            theme="tertiary"
            [aria-label]="intl.closeLabel"
            mat-dialog-close
            (click)="handleClose()"
          >
          </ui-icon-button>
        </div>
      </div>
      <mat-dialog-content
        class="settings"
        [ngClass]="{
          desktop: (isMobile$ | async) === false,
          full: appSettingsMenuItems.length === 0
        }"
      >
        <div class="configurations">
          <div
            class="settings-menu frame"
            *ngIf="(showMenu$ | async) && appSettingsMenuItems.length > 0"
          >
            <section>
              <div>
                <ui-shell-global-settings-nav-item
                  [icon]="'icon_user'"
                  [customIcon]="true"
                  [title]="intl.userPreferencesLabel"
                  (click)="setActive(USER_PREFERENCES_KEY)"
                  [active]="(isActive$(USER_PREFERENCES_KEY) | async) ?? false"
                  [isMobile]="(isMobile$ | async) || false"
                >
                </ui-shell-global-settings-nav-item>
              </div>
            </section>
            <section *ngIf="appSettingsMenuItems.length > 0">
              <h4>{{ intl.appSettingsLabel }}</h4>
              <div>
                <ng-container *ngFor="let item of appSettingsMenuItems">
                  <ui-shell-global-settings-nav-item
                    [title]="item.title"
                    (click)="setActive(item.key)"
                    [active]="(isActive$(item.key) | async) ?? false"
                    [icon]="item.icon"
                    [customIcon]="item.customIcon"
                    [isMobile]="(isMobile$ | async) || false"
                  >
                  </ui-shell-global-settings-nav-item>
                </ng-container>
              </div>
            </section>
          </div>
          <div class="settings-content frame" *ngIf="showContent$ | async">
            <div>
              <h3>
                <mat-icon
                  *ngIf="isMobile$ | async"
                  svgIcon="icon_arrow-line"
                  (click)="activeSubject.next(false)"
                  class="back-button"
                ></mat-icon>
                {{ activeTitle$ | async }}
              </h3>
            </div>
            <ng-template [cdkPortalOutlet]="portal$ | async"></ng-template>
          </div>
        </div>
      </mat-dialog-content>
    </div>
  `,
  styleUrls: ['./global-settings-modal.component.scss']
})
export class GlobalSettingsModalComponent
  implements AfterViewInit, OnInit, OnDestroy
{
  protected readonly USER_PREFERENCES_KEY = USER_PREFERENCES_KEY;
  private readonly _destroySubject = new Subject<void>();

  @ContentChildren(ShellGlobalSettingPanelComponent)
  public contentChildren!: QueryList<ShellGlobalSettingPanelComponent>;

  @ContentChildren(ShellGlobalSettingsUserPreferencesComponent)
  public preferenceContentChildren!: QueryList<ShellGlobalSettingsUserPreferencesComponent>;

  public portal$!: Observable<Portal>;
  public appSettingsMenuItems: SettingPanelProperties[] = [];

  public isMobile$!: Observable<boolean>;
  public showMenu$!: Observable<boolean>;
  public showContent$!: Observable<boolean>;
  public activeSubject = new BehaviorSubject<boolean>(false);

  public activeKey$!: Observable<string>;
  public activeTitle$!: Observable<string>;

  constructor(
    private _settingsPanelBridgeService: SettingsPanelBridgeService,
    private _globalSettingsService: GlobalSettingsService,
    public intl: GlobalSettingsIntl,
    public responsiveService: ResponsiveService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    intl.changes$.pipe(takeUntil(this._destroySubject)).subscribe(() => {
      this.changeDetectorRef.markForCheck();
    });
  }

  public isActive$(key: string): Observable<boolean> {
    return this.activeKey$.pipe(map(panel => panel === key));
  }

  public setActive(key: string): void {
    this._globalSettingsService.openSettingsPanel(key);
    this.activeSubject.next(true);
  }

  public handleClose(): void {
    this._globalSettingsService.closeSettingsPanel();
  }

  public ngOnInit(): void {
    this.portal$ = this._settingsPanelBridgeService.portal$;

    this.isMobile$ = this.responsiveService.responsiveFlags$.pipe(
      map(x => x.mobile)
    );
    const mobileContentActive$ = combineLatest([
      this.isMobile$,
      this.activeSubject
    ]);
    this.showContent$ = mobileContentActive$.pipe(
      map(([isMobile, active]) => (isMobile ? active : true))
    );
    this.showMenu$ = mobileContentActive$.pipe(
      map(([isMobile, active]) => (isMobile ? !active : true))
    );
    this.activeKey$ = this._settingsPanelBridgeService.activePanel$.pipe(
      map(panel => panel ?? '')
    );
    this.activeTitle$ = combineLatest([
      this.activeKey$,
      this.intl.changes$.pipe(startWith(null))
    ]).pipe(
      map(([key]) => {
        if (key === USER_PREFERENCES_KEY) {
          return this.intl.userPreferencesLabel;
        }
        const panels = this._settingsPanelBridgeService.settingPanelProperties;
        const activePanel = panels.find(p => p.key === key);
        if (!activePanel) {
          return '';
        }
        return activePanel.title;
      })
    );
  }

  public ngAfterViewInit(): void {
    this.appSettingsMenuItems = [
      ...this._settingsPanelBridgeService.settingPanelProperties
    ];

    this.changeDetectorRef.detectChanges();
  }

  public ngOnDestroy(): void {
    this._settingsPanelBridgeService.clearActivePanel();
    this._destroySubject.next();
    this._destroySubject.complete();
  }
}
