import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-state',
  styleUrls: ['./state.component.scss'],
  template: `
    <div
      class="state-container {{ state }} {{ size }}"
      [class.circle]="circle"
      [class.iconOnly]="iconOnly"
      [class.stateOnly]="stateOnly"
    >
      <mat-icon *ngIf="svgIcon && !stateOnly" [svgIcon]="icon"></mat-icon>
      <mat-icon *ngIf="!svgIcon && !stateOnly">{{ icon }}</mat-icon>
    </div>
  `
})
export class StateComponent {
  @Input() public state?: State;
  @Input() public icon = '';
  @Input() public size = 'default';
  @Input() public circle = false;
  @Input() public svgIcon = false;
  @Input() public iconOnly = false;
  @Input() public stateOnly = false;
}

export type State =
  | 'success'
  | 'warning'
  | 'error'
  | 'unavailable'
  | 'removed'
  | 'accent'
  | 'primary'
  | 'bodytext';
