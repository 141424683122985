import { Component, Input } from '@angular/core';
import { NavItemComponent } from '../nav-item/nav-item.component';
import {
  BaseNavigationItem,
  NavigationItem
} from '../nav-item/navigation-item.model';

@Component({
  selector: 'ui-shell-nav-expandable-item',
  template: `
    <ui-shell-nav-item
      [link]="link"
      [exactMatch]="true"
      [disableLink]="disableLink"
      [label]="label"
      [icon]="icon"
      [customIcon]="customIcon"
      [suffixIcon]="expanded && !disabled ? 'arrow_drop_up' : 'arrow_drop_down'"
      [small]="small"
      [disableTooltip]="disableTooltip"
      [disabled]="disabled"
      [additionalTooltipText]="additionalTooltipText"
      [badge]="expanded && badge === 0 ? undefined : badge"
      preserveFragment
      preserveQueryParams
      (click)="handleClick()"
      data-testid="item"
    >
    </ui-shell-nav-item>
    <div
      *ngIf="children && expanded && !disabled"
      class="children-list"
      data-testid="children-list"
    >
      <ui-shell-nav-item
        *ngFor="let child of children; let i = index; trackBy: trackByKey"
        [link]="getChildLink(child)"
        [exactMatch]="child.exactMatch"
        [label]="child.label"
        [id]="child.key || i.toString()"
        [disabled]="child.disabled"
        [badge]="child.badge"
        [additionalTooltipText]="child.tooltip"
        [preserveQueryParams]="child.preserveQueryParams"
        [preserveFragment]="child.preserveFragment"
        (linkClick)="linkClick.emit()"
      ></ui-shell-nav-item>
    </div>
  `,
  styleUrls: ['./nav-expandable-item.component.scss']
})
export class NavExpandableItemComponent extends NavItemComponent {
  @Input() public children: NavigationItem[] = [];
  @Input() public expanded = false;

  private _hasChildren(children: NavigationItem[] | undefined): boolean {
    return !!children && children.length > 0;
  }

  public override handleClick(): void {
    if (this.disabled) {
      return;
    }

    if (!this._hasChildren(this.children)) {
      this.linkClick.emit();
      return;
    }
    this.expanded = !this.expanded;
  }

  public getChildLink(child: NavigationItem): string[] {
    let parentLink = this.link;
    if (!parentLink || !child.link) {
      return child.link ?? [];
    }
    if (typeof parentLink === 'string') {
      parentLink = [parentLink];
    }
    return [...parentLink, ...child.link];
  }

  public trackByKey(_index: number, item: BaseNavigationItem): string {
    return item.key;
  }
}
