/* eslint-disable @typescript-eslint/no-explicit-any */

export const joinAndTruncateList = (
  elements?: any[],
  maxDisplayElements = 3
): string => {
  if (
    elements &&
    maxDisplayElements > 0 &&
    elements?.length > maxDisplayElements
  ) {
    return elements.slice(0, maxDisplayElements).join(', ') + ',...';
  }
  if (elements?.length === 0 || !elements) return '-';
  return elements.join(', ');
};
