import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  map,
  shareReplay,
  startWith,
  tap
} from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { CustomAction } from '../../models/actions';
import { USER_PREFERENCES_KEY } from '../../shell/settings-modal/constants';
import { SettingPanelProperties } from '../../shell/settings-modal/global-settings-panel/global-setting-panel.component';
import { GlobalSettingsService } from '../../shell/settings-modal/global-settings.service';
import { SettingsActionService } from '../settings-action.service';
import { BaseBridgeService } from './base-bridge.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsPanelBridgeService extends BaseBridgeService {
  private _settingPanelPropertiesSubject = new BehaviorSubject<
    SettingPanelProperties[]
  >([]);

  public get settingPanelProperties(): SettingPanelProperties[] {
    return this._settingPanelPropertiesSubject.value;
  }
  public set settingPanelProperties(value: SettingPanelProperties[]) {
    this._settingPanelPropertiesSubject.next(value);
  }

  public activePanel$: Observable<string | null | undefined>;

  constructor(
    route: ActivatedRoute,
    private _globalSettingsService: GlobalSettingsService,
    private _settingsActionService: SettingsActionService
  ) {
    super();

    const settingPanelKeys$ = this._settingPanelPropertiesSubject
      .asObservable()
      .pipe(
        map(panels => panels.map(panel => panel.key)),
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      );
    const settingsParam$ = route.queryParamMap.pipe(
      map(paramMap => paramMap.get('settings')),
      distinctUntilChanged(),
      startWith(undefined)
    );
    this.activePanel$ = combineLatest([settingsParam$, settingPanelKeys$]).pipe(
      tap(([key, panelKeys]) => {
        if (!key || panelKeys.includes(key) || key === USER_PREFERENCES_KEY) {
          return;
        }
        _globalSettingsService.openSettingsPanel();
      }),
      map(([key]) => key),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  public clearActivePanel(): void {
    this.settingPanelProperties.forEach(panel => {
      panel.active = false;
    });
  }

  public enableSettingsAction(): void {
    this._settingsActionService.action = new CustomAction(() =>
      this._globalSettingsService.openSettingsPanel()
    );
  }

  public disableSettingsAction(): void {
    this._settingsActionService.action = undefined;
  }
}
