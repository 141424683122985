import { Component, Input } from '@angular/core';

export interface SettingPanelProperties {
  key: string;
  title: string;
  active?: boolean;
  icon?: string;
  customIcon?: boolean;
}

@Component({
  selector: 'ui-shell-global-setting-panel',
  template: `
    <div class="setting-panel" *ngIf="active">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./global-setting-panel.component.scss']
})
export class ShellGlobalSettingPanelComponent
  implements SettingPanelProperties
{
  @Input({ required: true }) public key!: string;
  @Input({ required: true }) public title!: string;
  @Input() public active?: boolean = false;
  @Input() public icon?: string;
  @Input() public customIcon?: boolean = false;
}
