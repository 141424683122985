import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Subject, takeUntil } from 'rxjs';
import { Constants } from '../../constants';

@Component({
  selector: 'ui-option-groups-dropdown',
  templateUrl: './option-groups-dropdown.component.html'
})
export class OptionGroupsDropdownComponent implements OnInit, OnDestroy {
  /**
   * From control to be attached of type string
   * Passes reference for get and set
   */
  @Input() public control!: FormControl<string>;

  /**
   * Pass the error messages associated with control
   * for autocomplete to be displayed under input field
   */
  @Input() public errorMessages: { [key: string]: string } = {};

  /**
   * Label for input field
   */
  @Input() public label = '';

  /**
   * place holder for input for when user hasn't
   * entered a value into the input yet
   */
  @Input() public placeHolder = '';

  private _destroySubject = new Subject();
  private _possibleGroupedValues: NestedDropDownModel[] | null = [];
  public options: ScrollableOption[] = [];
  public errorStateMatcher!: ErrorStateMatcher;

  public get possibleGroupedValues(): NestedDropDownModel[] | null {
    return this._possibleGroupedValues;
  }
  @Input()
  public set possibleGroupedValues(value: NestedDropDownModel[] | null) {
    this._possibleGroupedValues = value;
    this._updateOptions();
  }

  private _updateOptions(): void {
    this.options = [];
    if (this.possibleGroupedValues) {
      this.possibleGroupedValues.forEach(grouping => {
        //push group name into select for virtual scrolling
        this.options.push({
          isGroupNaming: true,
          content: grouping.name
        } as ScrollableOption);
        const actualValues = grouping.contents
          .filter(
            val =>
              val
                ?.toLowerCase()
                .includes(this.control?.value?.toLowerCase() ?? '')
          )
          .sort((a, b) => a.localeCompare(b))
          .map(g => {
            return { isGroupNaming: false, content: g } as ScrollableOption;
          });
        //hide group header if no values
        if (actualValues.length === 0) {
          this.options.pop();
          return;
        }

        this.options.push(...actualValues);
        this.options = Array.from(new Set(this.options));
      });
    } else {
      this.options = [];
    }
  }

  public getErrors(): string {
    if (!this.control?.errors) return '';
    const errors = Object.keys(this.control.errors);
    return errors?.map(e => this.errorMessages[e] || e).join(',') ?? '';
  }

  public ngOnInit(): void {
    this._updateOptions();
    this.control.valueChanges
      .pipe(takeUntil(this._destroySubject))
      .subscribe(() => this._updateOptions());
    this.errorStateMatcher =
      new OptionGroupsDropdownComponentErrorStateMatcher();
  }

  public ngOnDestroy(): void {
    this._destroySubject.next(undefined);
  }
  public optionsBoxHeight(count: number): number {
    return (
      Math.min(Constants.OPTION_VIEW_HEIGHT, count) *
      Constants.MATERIAL_OPTION_HEIGHT
    );
  }

  public trackBy(_index: number, item: ScrollableOption): string {
    return item.content;
  }
}

export class OptionGroupsDropdownComponentErrorStateMatcher
  implements ErrorStateMatcher
{
  public isErrorState(control: AbstractControl): boolean {
    const isTouchedOrDirty = control.touched || control.dirty;
    const isInvalid = control.invalid;

    return isTouchedOrDirty && isInvalid;
  }
}

export interface ScrollableOption {
  isGroupNaming: boolean;
  content: string;
}

export interface NestedDropDownModel {
  name: string;
  contents: string[];
}
