import {
  Directive,
  ElementRef,
  Input,
  NgModule,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {
  Instance,
  OptionsGeneric,
  StrictModifiers,
  createPopper
} from '@popperjs/core';
import { Subject, fromEvent, merge } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[uiPopper]'
})
export class PopperDirective implements OnInit, OnDestroy, OnChanges {
  // The hint to display
  @Input() public popperTarget?: HTMLElement;
  // Its positioning (check docs for available options)
  // Optional hint target if you desire using other element than specified one
  @Input() public appPopper?: HTMLElement;
  // The popper instance

  @Input() public popperOptions?: Partial<OptionsGeneric<StrictModifiers>>;
  @Input() public allowHoverOverPopperTarget = false;
  @Input() public shouldPop = true;

  private _popper?: Instance;
  private readonly _destroySubject = new Subject<void>();

  constructor(private readonly _el: ElementRef<HTMLElement>) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['shouldPop']) {
      if (changes['shouldPop'].currentValue === true) {
        this._initPopper();
      } else {
        this.ngOnDestroy();
      }
    }
    if (changes['allowHoverOverPopperTarget']) {
      this._registerMouseEvents();
    }
    if (changes['popperOptions']) {
      this._setPopperOptions();
    }
    if (changes['popperTarget']) {
      this.ngOnDestroy();
      this._initPopper();
    }
  }

  private _createPopperOptions(): Partial<OptionsGeneric<StrictModifiers>> {
    return {
      ...this.popperOptions,
      modifiers: [
        ...(this.popperOptions?.modifiers ?? []),
        {
          name: 'eventListeners',
          enabled: false
        }
      ]
    };
  }

  private _createPopperInstance(): void {
    if (!this.popperTarget) return;
    this._popper = createPopper<StrictModifiers>(
      this._reference,
      this.popperTarget,
      this._createPopperOptions()
    );
  }

  private _setPopperOptions(): void {
    if (this._popper) {
      this._popper.setOptions(this._createPopperOptions());
    }
  }
  private get _reference(): HTMLElement {
    return this.appPopper ? this.appPopper : this._el.nativeElement;
  }

  private _registerMouseEvents(): void {
    if (!this.popperTarget) return;
    const relevantEvent$ = this.allowHoverOverPopperTarget
      ? merge(
          fromEvent<MouseEvent>(this._reference, 'mouseenter'),
          fromEvent<MouseEvent>(this._reference, 'mouseleave'),
          fromEvent<MouseEvent>(this.popperTarget, 'mouseenter'),
          fromEvent<MouseEvent>(this.popperTarget, 'mouseleave')
        )
      : merge(
          fromEvent<MouseEvent>(this._reference, 'mouseenter'),
          fromEvent<MouseEvent>(this._reference, 'mouseleave')
        );

    relevantEvent$
      .pipe(
        filter(() => this._popper != null),
        takeUntil(this._destroySubject)
      )
      .subscribe((e: MouseEvent) => {
        this._mouseHoverHandler(e);
      });
  }

  private _initPopper(): void {
    // An element to position the hint relative to
    if (this.shouldPop) {
      this._createPopperInstance();
      this._registerMouseEvents();
    }
  }

  public ngOnInit(): void {
    if (this.shouldPop) {
      this._initPopper();
    }
  }

  public ngOnDestroy(): void {
    if (!this._popper) {
      return;
    }
    this._popper.destroy();
    this._popper = undefined;

    this._destroySubject.next(undefined);
    this._destroySubject.complete();
  }

  private async _mouseHoverHandler(e: MouseEvent): Promise<void> {
    if (e.type === 'mouseenter') {
      await this._popper?.setOptions({
        modifiers: [
          ...this._popper.state.options.modifiers,
          { name: 'eventListeners', enabled: true }
        ]
      });
      await this._popper?.update();
    } else {
      await this._popper?.setOptions({
        modifiers: [
          ...this._popper.state.options.modifiers,
          { name: 'eventListeners', enabled: false }
        ]
      });
    }
  }
}

@NgModule({
  imports: [],
  exports: [PopperDirective],
  declarations: [PopperDirective],
  providers: []
})
export class PopperDirectiveModule {}
