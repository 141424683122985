import { Component, Input } from '@angular/core';
import { State } from '../state/state.component';

@Component({
  selector: 'ui-title',
  template: `
    <ui-state
      [size]="size"
      [iconOnly]="iconOnly"
      *ngIf="state"
      [svgIcon]="svgIcon"
      data-testid="state"
      [icon]="icon"
      [state]="state"
      [matTooltip]="iconTooltip!"
      [matTooltipDisabled]="!iconTooltip"
    >
    </ui-state>
    <div class="title-container {{ size }}">
      <div
        class="title"
        data-testid="title"
        [class.compact-title]="compactTitle"
        [class.iconOnly]="iconOnly"
        uiHighlight
        [content]="title"
        [searchTerm]="searchTerm"
      ></div>
      <div
        *ngIf="subtitle"
        class="subtitle"
        [class.iconOnly]="iconOnly"
        data-testid="subtitle"
        uiHighlight
        [content]="subtitle"
        [searchTerm]="searchTerm"
      ></div>
    </div>
  `,
  styleUrls: ['./title.component.scss']
})
export class TitleComponent {
  @Input() public state?: State;
  @Input() public title = '';
  @Input() public subtitle = '';
  @Input() public size = 'default';
  @Input() public compactTitle = false;
  @Input() public iconOnly = false;
  @Input() public svgIcon = false;
  @Input() public icon = '';
  @Input() public iconTooltip: string | undefined;

  @Input() public searchTerm = '';
}
