import { Brand } from '@onyx/core';
import { BrandDetails } from '../services/brand-details.service';

export const brandDetailsList: {
  [key in Brand]: BrandDetails;
} = {
  stack: {
    fullName: 'KION GROUP AG',
    productNameHTML: '<strong>stack</strong>',
    productName: 'stack',
    siteUrl: 'https://www.kiongroup.com/'
  },
  kion: {
    fullName: 'KION GROUP AG',
    productNameHTML: '<strong>stack</strong>',
    productName: 'stack',
    siteUrl: 'https://www.kiongroup.com/'
  },
  linde: {
    fullName: 'Linde Material Handling GmbH',
    productNameHTML: '<strong>stack</strong>',
    productName: 'stack',
    siteUrl: 'https://www.linde-mh.com/'
  },
  still: {
    fullName: 'STILL GmbH',
    productNameHTML: 'iGo ',
    productName: 'iGo ',
    siteUrl: 'https://www.still.be/'
  },
  dematic: {
    fullName: 'Dematic',
    productNameHTML: '<strong>stack</strong>',
    productName: 'stack',
    siteUrl: 'https://www.dematic.com/'
  }
};
