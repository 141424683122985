import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map } from 'rxjs';
import { ResponsiveService } from '../../../shell.module';
import { GlobalSettingsIntl } from '../global-settings-intl';

export interface LanguageOption {
  key: string;
  label: string;
}

@Component({
  selector: 'ui-shell-global-settings-category-language',
  template: `
    <ui-setting-category [title]="intl.languageLabel" icon="language">
      <ui-setting-category-item [label]="intl.preferredLanguageLabel">
        <mat-form-field class="noBorder">
          <mat-select
            matNativeControl
            [formControl]="languageControl"
            [class.mobile]="isMobile$ | async"
          >
            <ng-container
              *ngFor="let language of languages; trackBy: trackLanguage"
            >
              <mat-option [value]="languageKey(language)">{{
                languageLabel(language)
              }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </ui-setting-category-item>
    </ui-setting-category>
  `
})
export class ShellGlobalSettingsLanguageComponent implements OnChanges {
  @Input() public languages: (string | LanguageOption)[] = [];
  @Input() public language!: string;
  @Output() public languageChange = new EventEmitter<string>();

  public isMobile$: Observable<boolean>;

  public languageControl = new FormControl('');

  constructor(
    public responsiveService: ResponsiveService,
    public intl: GlobalSettingsIntl
  ) {
    this.isMobile$ = this.responsiveService.responsiveFlags$.pipe(
      map(flags => flags.mobile)
    );
    this.languageControl = new FormControl(this.language);
    this.languageControl.valueChanges.subscribe(value => {
      if (value) this.languageChange.emit(value);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['language'] && this.language) {
      this.languageControl.setValue(this.language);
    }
  }

  public trackLanguage(
    _index: number,
    language: string | LanguageOption
  ): string {
    return language instanceof Object ? language.key : language;
  }

  public languageKey(language: string | LanguageOption): string {
    return language instanceof Object ? language.key : language;
  }

  public languageLabel(language: string | LanguageOption): string {
    return language instanceof Object ? language.label : language;
  }
}
