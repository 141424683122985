import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  CardComponent,
  CardFooterComponent,
  CardHeaderComponent
} from './components/card/card.component';
import { DatetimeNumberComponent } from './components/datetime/datetime-number/datetime-number.component';
import { DatetimeComponent } from './components/datetime/datetime.component';
import {
  FlowpanelComponent,
  FlowpanelHeaderComponent
} from './components/flowpanel/flowpanel.component';
import { HighlightDirectiveModule } from './components/highlight/highlight.directive';
import { HotpickComponent } from './components/hotpick/hotpick.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { LabelValueContainerComponent } from './components/label-value/label-value-container/label-value-container.component';
import { LabelValueComponent } from './components/label-value/label-value/label-value.component';
import { LinkButtonComponent } from './components/link-button/link-button.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { SettingCategoryItemComponent } from './components/setting-category-item/setting-category-item.component';
import { SettingCategoryComponent } from './components/settings-category/setting-category.component';
import { SidepanelTitleComponent } from './components/sidepanel-title/sidepanel-title.component';
import { StateComponent } from './components/state/state.component';
import { TextLinkComponent } from './components/text-link/text-link.component';
import { ThumbnailComponent } from './components/thumbnail/thumbnail.component';
import { TitleComponent } from './components/title/title.component';

export * from './components/auto-complete-field/auto-complete-field.component';
export * from './components/button/button.component';
export * from './components/card/card.component';
export * from './components/datetime/datetime.component';
export * from './components/filter/filter.component';
export * from './components/filter/filter.module';
export * from './components/filterable-dropdown/filterable-dropdown.module';
export * from './components/flowpanel/flowpanel.component';
export * from './components/generic-table/generic-table.module';
export * from './components/highlight/highlight.directive';
export * from './components/hotpick/hotpick.component';
export * from './components/icon-button/icon-button.component';
export * from './components/label-value/label-value-container/label-value-container.component';
export * from './components/label-value/label-value/label-value.component';
export * from './components/link-button/link-button.component';
export * from './components/loading-bar/loading-bar.component';
export * from './components/option-groups-dropdown/option-groups-dropdown.module';
export * from './components/popper/popper.directive';
export * from './components/setting-category-item/setting-category-item.component';
export * from './components/settings-category/setting-category.component';
export * from './components/sidepanel-title/sidepanel-title.component';
export * from './components/state/state.component';
export * from './components/text-link/text-link.component';
export * from './components/thumbnail/thumbnail.component';
export * from './components/title/title.component';
export * from './services/theme-and-brand.service';
export * from './utils/truncate-list/truncate-list';

@NgModule({
  declarations: [
    ThumbnailComponent,
    StateComponent,
    TitleComponent,
    CardComponent,
    CardHeaderComponent,
    CardFooterComponent,
    SidepanelTitleComponent,
    FlowpanelComponent,
    FlowpanelHeaderComponent,
    HotpickComponent,
    LabelValueComponent,
    LabelValueContainerComponent,
    LoadingBarComponent,
    TextLinkComponent,
    LinkButtonComponent,
    IconButtonComponent,
    DatetimeComponent,
    DatetimeNumberComponent,
    SettingCategoryComponent,
    SettingCategoryItemComponent
  ],
  imports: [
    MatTooltipModule,
    MatIconModule,
    MatButtonModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatMenuModule,
    HighlightDirectiveModule,
    MatProgressBarModule,
    MatBadgeModule
  ],
  exports: [
    ThumbnailComponent,
    StateComponent,
    TitleComponent,
    CardHeaderComponent,
    CardFooterComponent,
    SidepanelTitleComponent,
    CardComponent,
    FlowpanelComponent,
    FlowpanelHeaderComponent,
    HotpickComponent,
    LabelValueComponent,
    LabelValueContainerComponent,
    MatTooltipModule,
    LoadingBarComponent,
    TextLinkComponent,
    LinkButtonComponent,
    IconButtonComponent,
    DatetimeComponent,
    SettingCategoryComponent,
    SettingCategoryItemComponent
  ]
})
export class CoreModule {}
