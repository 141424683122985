import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ui-text-link',
  template: `
    <a class="link-button" (click)="_click.emit($event)"
      ><ng-content></ng-content
    ></a>
  `,
  styleUrls: ['./text-link.component.scss']
})
export class TextLinkComponent {
  @Output() protected _click = new EventEmitter<MouseEvent>();
}
