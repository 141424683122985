<div>
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input
      title="filter"
      type="text"
      matInput
      [formControl]="control"
      [matAutocomplete]="auto"
      #trigger="matAutocompleteTrigger"
      [placeholder]="placeHolder"
    />
    <mat-icon matSuffix>search</mat-icon>
    <mat-error data-testid="errors">{{ getErrors() }}</mat-error>
    <mat-autocomplete #auto="matAutocomplete">
      <cdk-virtual-scroll-viewport
        [itemSize]="15"
        [style.height.px]="optionsBoxHeight(options.length)"
        #scrollViewport
      >
        <mat-option
          *cdkVirtualFor="let element of options; trackBy: trackBy"
          [value]="element.content"
          [disabled]="element.isGroupNaming"
        >
          {{ element.content }}
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
  </mat-form-field>
</div>
