import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'ui-toggle-cell',
  template: `
    <mat-slide-toggle
      color="primary"
      [checked]="checked"
      (click)="$event.stopPropagation()"
      (change)="checkedChange.emit($event.checked)"
      [disabled]="disabled"
    >
      <span uiHighlight [content]="label" [searchTerm]="currentFilter"></span>
    </mat-slide-toggle>
  `,
  styleUrls: ['./toggle-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleCellComponent {
  @Input() public currentFilter = '';

  @Input() public checked = false;
  @Output() public checkedChange = new EventEmitter<boolean>();

  @Input() public disabled = false;

  @Input() public label = '';
}
