import { Component, ElementRef, HostListener } from '@angular/core';
import { ThemeAndBrandService } from '@onyx/core';
import { Observable, map } from 'rxjs';
import { ActiveNavigationItemService } from '../../../../services/active-navigation-item.service';
import { LevelMobilePanelToggleService } from '../../../../services/behavior/level-mobile-panel-toggle.service';
import { MobileMenuToggleService } from '../../../../services/behavior/mobile-menu-toggle.service';
import { MobileSiteSelectorToggleService } from '../../../../services/behavior/mobile-site-selector-toggle.service';
import { NavigationService } from '../../../../services/navigation.service';
import { SettingsActionService } from '../../../../services/settings-action.service';
import { ShellIntl } from '../../../shell-intl';
import {
  BaseNavigationItem,
  NavigationItem
} from '../../nav-item/navigation-item.model';

@Component({
  selector: 'ui-shell-navigation-overlay',
  template: `
    <div
      class="second-level"
      *ngIf="mobileLevelMenuIsOpen$ | async; else mobileFirstLevel"
      data-testid="second-level"
    >
      <div
        class="second-level-container"
        *ngIf="selectedItem$ | async as activeItem"
      >
        <div class="header level">
          <mat-icon
            class="arrow"
            (click)="goLevelBack()"
            svgIcon="icon_arrow-line"
            data-testid="back-arrow"
          ></mat-icon>
          <div class="title" data-testid="title">{{ activeItem.label }}</div>
        </div>
        <nav>
          <ui-shell-nav-item
            *ngFor="let item of activeItem.children; trackBy: trackByKey"
            [label]="item.label"
            [link]="buildLink(activeItem, item)"
            [exactMatch]="item.exactMatch"
            [customIcon]="item.customIcon"
            [icon]="item.iconName"
            [disabled]="item.disabled"
            [badge]="item.badge"
            [preserveQueryParams]="item.preserveQueryParams"
            [preserveFragment]="item.preserveFragment"
            (click)="closeLevelMenu()"
            role="menu"
          ></ui-shell-nav-item>
        </nav>
      </div>
    </div>
    <ng-template #mobileFirstLevel>
      <div data-testid="first-level">
        <div class="header">
          <div class="logo">
            <div class="logo-icon" data-testid="icon"></div>
            <div class="logo-stack" data-testid="logo"></div>
          </div>
          <p
            *ngIf="themeAndBrandService.brand$ | async as brand"
            class="brand"
            data-testid="brand"
          >
            {{ intl.byLabel }} {{ brand | titlecase }}
          </p>
        </div>
        <ng-content select="[site]"></ng-content>
        <nav *ngIf="(mobileSiteSelectorToggleService.open$ | async) === false">
          <ui-shell-nav-item
            *ngFor="let item of navigationItems$ | async; trackBy: trackByKey"
            [label]="item.label"
            [link]="item.link"
            [exactMatch]="item.exactMatch"
            [icon]="item.iconName"
            [customIcon]="item.customIcon"
            [disableLink]="hasChildren(item.children)"
            [disabled]="item.disabled"
            [badge]="getBadge(item)"
            [preserveQueryParams]="item.preserveQueryParams"
            [preserveFragment]="item.preserveFragment"
            (linkClick)="openLevelMenu(item)"
            role="menu"
          ></ui-shell-nav-item>
          <ui-shell-nav-item
            *ngIf="settingsActionService.action"
            [label]="intl.settingsLabel"
            [customIcon]="true"
            [icon]="'icon_settings'"
            preserveQueryParams
            preserveFragment
            role="menu"
            (click)="clickOnSettings()"
          ></ui-shell-nav-item>
        </nav>
      </div>
    </ng-template>
  `,
  styleUrls: ['./navigation-overlay.component.scss']
})
export class NavigationOverlayComponent {
  public navigationItems$: Observable<NavigationItem[]>;
  public mobileLevelMenuIsOpen$: Observable<boolean>;
  public selectedItem$: Observable<NavigationItem>;

  @HostListener('document:mousedown', ['$event'])
  public onClick(event: MouseEvent): void {
    if (!this._elementRef.nativeElement.contains(event.target)) {
      this.closeLevelMenu();
    }
  }
  constructor(
    private _elementRef: ElementRef,
    navigationService: NavigationService,
    private _activeNavigationItemService: ActiveNavigationItemService,
    private _mobileMenutoggleService: MobileMenuToggleService,
    private _levelMobileService: LevelMobilePanelToggleService,
    public mobileSiteSelectorToggleService: MobileSiteSelectorToggleService,
    public intl: ShellIntl,
    public themeAndBrandService: ThemeAndBrandService,
    public settingsActionService: SettingsActionService
  ) {
    this.navigationItems$ = navigationService.navigationConfig$.pipe(
      map(config => config.navigationItems)
    );
    this.mobileLevelMenuIsOpen$ = this._levelMobileService.open$;
    this.selectedItem$ = _activeNavigationItemService.selectedItem$;
  }

  public clickOnSettings(): void {
    this.closeLevelMenu();
    this.settingsActionService.action?.do();
  }

  public hasChildren(children?: NavigationItem[]): boolean | undefined {
    return children && children?.length > 0;
  }

  public openLevelMenu(item: NavigationItem): void {
    if (!item.children || item.children.length === 0) {
      this._mobileMenutoggleService.close();
      return;
    }
    this._activeNavigationItemService.selectedItem = item;
    this._levelMobileService.toggle();
  }

  public buildLink(parent: NavigationItem, child: NavigationItem): string[] {
    return (parent.link ?? []).concat(child.link ?? []);
  }

  public goLevelBack(): void {
    this._levelMobileService.toggle();
  }

  public closeLevelMenu(): void {
    this._mobileMenutoggleService.close();
  }

  public trackByKey(_index: number, item: BaseNavigationItem): string {
    return item.key;
  }

  public getBadge(item: NavigationItem): number | undefined {
    if (item.badge !== undefined) {
      return item.badge;
    }

    if (item.children?.some(child => child.badge !== undefined)) {
      return 0;
    }

    return undefined;
  }
}
