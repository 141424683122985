import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Portal } from '../../services/bridge/base-bridge.service';
import { SidePanelBridgeService } from '../../services/bridge/sidepanel-bridge.service';

@Component({
  selector: 'ui-shell-sidepanel',
  template: `<ng-template [cdkPortalOutlet]="portal$ | async"></ng-template
    ><ng-content></ng-content>`,
  styleUrls: ['sidepanel.component.scss']
})
export class SidepanelComponent {
  public portal$!: Observable<Portal>;

  constructor(_sidePanelBridgeService: SidePanelBridgeService) {
    this.portal$ = _sidePanelBridgeService.portal$;
  }
}
