import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-loading-bar',
  template: `
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
  `,
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent {
  @Input() public loading: boolean | undefined;
}
