import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { ErrorStateMatcher, MatOption } from '@angular/material/core';
import {
  BehaviorSubject,
  ReplaySubject,
  Subject,
  combineLatest,
  map,
  race,
  shareReplay,
  startWith,
  take,
  takeUntil,
  timer
} from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { Constants } from '../../constants';
import { AutoCompleteFieldComponentErrorStateMatcher } from '../auto-complete-field/auto-complete-field.component';

@Component({
  selector: 'ui-filterable-dropdown',
  templateUrl: './filterable-dropdown.component.html'
})
export class FilterableDropdownComponent<T = unknown>
  implements OnDestroy, OnInit
{
  @Input() public placeHolder = '';
  @Input() public errorMessages: { [key: string]: string } = {};
  @Input() public control!: FormControl;
  @Input() public label = '';
  @Input() public itemSize = Constants.MATERIAL_OPTION_HEIGHT;
  @Input() public numOfItemsDisplayed = Constants.OPTION_VIEW_HEIGHT;
  @Input() public optionTemplate: TemplateRef<MatOption> | undefined;
  @Input() public displayWithProperty: keyof T | undefined;
  @Input() public displayWith: (option?: T) => string = option =>
    `${
      option
        ? this.displayWithProperty
          ? option[this.displayWithProperty]
          : option
        : ''
    }`;
  @Input() public equalsFn: (query: string, option: T) => boolean = (
    query,
    option
  ) => this.displayWith(option)?.toLowerCase().includes(query.toLowerCase());

  public queryControl = new FormControl<string>('');
  private _optionsSubject = new ReplaySubject<null | T[]>(1);
  private _showAllSubject = new BehaviorSubject<boolean>(true);
  private _queryChanges$ = this.queryControl.valueChanges.pipe(
    distinctUntilChanged(),
    tap(() => this._showAllSubject.next(false)),
    startWith(''),
    filter(query => typeof query === 'string')
  );
  public filteredOptions$ = combineLatest([
    this._optionsSubject,
    this._queryChanges$,
    this._showAllSubject
  ]).pipe(
    map(([values, query, showAll]) =>
      this._getMatchingOptions(values, query ?? '', showAll)
    ),
    shareReplay(1)
  );
  public errorStateMatcher!: ErrorStateMatcher;
  private _destroySubject = new Subject<void>();

  @ViewChild('auto') public auto!: MatAutocomplete;

  @Input()
  public set options(value: null | T[]) {
    this._optionsSubject.next(value);
  }
  public opened(): void {
    this._showAllSubject.next(true);
  }

  public ngOnInit(): void {
    if (!this.control) {
      throw new Error(
        'property control must be set for the auto-complete-field to work properly'
      );
    }

    // set initial disabled state
    this.control.disabled
      ? this.queryControl.disable()
      : this.queryControl.enable();
    // listen to disabled changes
    this.control.registerOnDisabledChange(disabled =>
      disabled ? this.queryControl.disable() : this.queryControl.enable()
    );

    this.errorStateMatcher = new AutoCompleteFieldComponentErrorStateMatcher();

    setTimeout(() => {
      this.control.valueChanges
        .pipe(startWith(this.control.value), takeUntil(this._destroySubject))
        .subscribe(value => {
          this.queryControl.setValue(value);
          if (this.auto.options) {
            for (const option of this.auto.options) {
              if (option.value == value) {
                option.select();
              } else {
                option.deselect();
              }
            }
          }
        });
    });
  }

  public ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  public resetQueryControl(): void {
    const clearQuery$ = timer(200).pipe(map(() => this.control.value ?? ''));
    race(this.control.valueChanges, clearQuery$)
      .pipe(take(1))
      .subscribe(value => {
        this.queryControl.setValue(value);
      });
  }

  public setSelection(option: T): void {
    this.control.setValue(option);
  }

  public getErrors(): string {
    if (!this.control?.errors) return '';
    const errors = Object.keys(this.control.errors);
    return errors?.map(e => this.errorMessages[e] || e).join(',') ?? '';
  }

  private _getMatchingOptions(
    options: Array<T> | null,
    query: string,
    showAll: boolean
  ): T[] {
    if (!options) return [];
    if (showAll || !query) return options;
    return options.filter(option => this.equalsFn(query, option));
  }
}
