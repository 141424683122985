import { IAction } from './action.interface';

//TODO: adding blank as an option
export class UrlAction implements IAction {
  constructor(
    private _url: string,
    private _focus = true
  ) {}

  public do(): void {
    if (this._url) {
      const w = window.open(this._url, '_blank');
      if (w && this._focus) {
        w.focus();
      }
    }
  }
}
