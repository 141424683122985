import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

export class BaseToggleService {
  private _openSubject = new BehaviorSubject<boolean>(false);
  public open$ = this._openSubject.asObservable().pipe(distinctUntilChanged());

  public toggle(): void {
    this._openSubject.next(!this._openSubject.value);
  }

  public open(): void {
    this._openSubject.next(true);
  }

  public close(): void {
    this._openSubject.next(false);
  }
}
