import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CustomAction } from '../../../models/actions';
import { MobileMenuToggleService } from '../../../services/behavior/mobile-menu-toggle.service';
import { MobileSiteSelectorToggleService } from '../../../services/behavior/mobile-site-selector-toggle.service';
import { Link } from '../../link/link.component';
import { SiteIntl } from '../site-intl';
import { Site } from '../site.model';
import { TrafficLightModel } from '../traffic-light/traffic-light.model';

@Component({
  selector: 'ui-shell-mobile-site-selector',
  template: `
    <div
      class="mobile app-btn"
      [class.open]="mobileSiteSelectorToggleService.open$ | async"
    >
      <div
        class="site"
        (click)="siteChangeable && mobileSiteSelectorToggleService.toggle()"
        data-testid="site-wrapper"
      >
        <span
          *ngIf="trafficLight && trafficLight.state"
          [class.small]="!siteChangeable"
          class="traffic-light {{ trafficLight.state }}"
          data-testid="traffic-light"
        ></span>
        <div class="app-data">
          <div class="app">
            <div *ngIf="siteChangeable" class="app-title" data-testid="site">
              {{ activeSite?.site || intl.chooseSiteLabel }}
            </div>
            <div
              [class.app-title]="!siteChangeable"
              class="app-traffic {{ trafficLight.state }}"
              *ngIf="trafficLight"
              data-testid="traffic-light-value"
            >
              {{ trafficLight.value }}
            </div>
          </div>
          <mat-icon *ngIf="siteChangeable"
            >{{
              (mobileSiteSelectorToggleService.open$ | async)
                ? 'expand_less'
                : 'expand_more'
            }}
          </mat-icon>
        </div>
      </div>
      <ng-container *ngIf="mobileSiteSelectorToggleService.open$ | async">
        <hr />
        <ui-shell-site-search
          [sites]="sites || []"
          [link]="link"
          (handleSiteChange)="changeSite($event)"
          data-testid="site-search"
        ></ui-shell-site-search>
      </ng-container>
    </div>
  `,
  styleUrls: ['./mobile-site-selector.component.scss']
})
export class MobileSiteSelectorComponent implements OnChanges, OnDestroy {
  private readonly _destroySubject = new Subject<void>();

  @Input() public activeSite?: Site;
  @Input() public sites?: Site[];
  @Input() public trafficLight?: TrafficLightModel;
  @Input() public searchLink?: Link;
  public link?: Link;

  @Output() public handleSiteChange = new EventEmitter<Site>();

  public siteChangeable = this._getSiteChangeable();

  constructor(
    public mobileSiteSelectorToggleService: MobileSiteSelectorToggleService,
    private _mobileMenuToggleService: MobileMenuToggleService,
    public intl: SiteIntl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    intl.changes$
      .pipe(takeUntil(this._destroySubject))
      .subscribe(() => changeDetectorRef.markForCheck());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchLink']) {
      this._updateLink();
    }

    if (changes['sites']) {
      this.siteChangeable = this._getSiteChangeable();
    }
  }

  public ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  public changeSite(site: Site): void {
    this.handleSiteChange.emit(site);
    this.mobileSiteSelectorToggleService.close();
  }

  private _updateLink(): void {
    if (!this.searchLink) {
      this.link = undefined;
      return;
    }

    this.link = {
      ...this.searchLink,
      action: new CustomAction(() => {
        //Mobile menu should also be closed when link is clicked
        this._mobileMenuToggleService.close();
        this.mobileSiteSelectorToggleService.close();
        this.searchLink?.action?.do();
      })
    };
  }

  private _getSiteChangeable(): undefined | boolean {
    return this.sites && this.sites.length > 1;
  }
}
