<ui-filter
  *ngIf="enableFilter"
  class="mb-m mt-m"
  [filterExpand]="false"
  [control]="searchFieldControl"
  [hotpicks]="enableHotPick"
  [filterNoBreakpoint]="filterNoBreakpoint"
  [placeholder]="filterPlaceholder"
>
  <ng-content></ng-content>
</ui-filter>

<mat-table
  [dataSource]="dataSource"
  [trackBy]="trackBy"
  multiTemplateDataRows
  matSort
  [matSortActive]="defaultSortActive ?? key?.toString() ?? ''"
  [matSortDirection]="defaultSortDirection"
  data-testid="table"
  *ngIf="structure"
  [class.clickable]="isClickable"
>
  <mat-header-row *matHeaderRowDef="allColumns; sticky: true"></mat-header-row>

  <mat-row
    [attr.data-testid]="'row-' + row[key]"
    table-row
    *matRowDef="let row; columns: allColumns"
    [class]="classesForRow(row)"
    (click)="singleSelect(row)"
  ></mat-row>

  <div
    class="mat-row"
    *matNoDataRow
    class="no-data-row"
    data-testid="empty-row"
  >
    {{ emptyListReason() }}
  </div>

  <ng-container *ngFor="let column of columns" [matColumnDef]="column">
    <mat-header-cell
      *matHeaderCellDef
      mat-sort-header
      [attr.data-testid]="column + '-header'"
    >
      {{ structureValueFor(column) }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <div
        [attr.data-testid]="column + '-cell'"
        *ngIf="!hasOverride(column)"
        uiHighlight
        [content]="element[column]"
        [searchTerm]="currentFilter"
      ></div>
      <div *ngIf="hasOverride(column)" [attr.data-testid]="column + '-cell'">
        <ng-container
          [ngTemplateOutlet]="hasOverride(column)!.template"
          [ngTemplateOutletContext]="{
            $implicit: element,
            currentFilter: currentFilter
          }"
        ></ng-container>
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="select" *ngIf="selectableColumnVisible">
    <mat-header-cell *matHeaderCellDef mat-sort-header>
      <ng-container *ngIf="deselectVisible">
        <mat-checkbox
          color="primary"
          data-testid="deselect-all"
          *ngIf="anythingSelected"
          [checked]="true"
          (click)="$event.stopPropagation(); deselectAll()"
        ></mat-checkbox>
        <mat-checkbox
          color="primary"
          data-testid="select-all"
          [checked]="false"
          *ngIf="!anythingSelected"
          (click)="$event.stopPropagation(); selectAll()"
        ></mat-checkbox>
      </ng-container>
    </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <mat-checkbox
        color="primary"
        (click)="$event.stopPropagation()"
        (change)="toggleSelection(element)"
        [checked]="selection.isSelected(element[key])"
        [attr.data-select-id]="element[key]"
      ></mat-checkbox>
    </mat-cell>
  </ng-container>
</mat-table>

<mat-paginator
  *ngIf="paging"
  [pageSize]="pageSize"
  (page)="pageChanged($event)"
  [pageSizeOptions]="[15, 25, 50]"
  showFirstLastButtons
  data-testid="paginator"
>
</mat-paginator>
