import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { OptionGroupsDropdownComponent } from './option-groups-dropdown.component';

export * from './option-groups-dropdown.component';

@NgModule({
  imports: [
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    CommonModule,
    MatIconModule,
    ScrollingModule
  ],
  declarations: [OptionGroupsDropdownComponent],
  exports: [OptionGroupsDropdownComponent],
  providers: []
})
export class OptionGroupsDropdownModule {}
