import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OptionsGeneric, StrictModifiers } from '@popperjs/core';
import { joinAndTruncateList } from '../../../utils/truncate-list/truncate-list';

@Component({
  selector: 'ui-list-cell',
  template: `
    <div
      class="popper-target"
      *ngIf="displayPopup"
      uiPopper
      [popperTarget]="fixedPopperLabel"
      [popperOptions]="labelPopperOptions"
      uiHighlight
      [content]="truncated()"
      [searchTerm]="currentFilter"
    ></div>
    <div #fixedPopperLabel class="popper">
      <mat-icon svgIcon="icon_tooltip"></mat-icon>
      <span>{{ all() }}</span>
    </div>
    <span
      *ngIf="!displayPopup"
      uiHighlight
      [content]="truncated()"
      [searchTerm]="currentFilter"
    ></span>
  `,
  styleUrls: ['./list-cell.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListCellComponent {
  @Input() public element: string[] = [];
  @Input() public currentFilter = '';
  @Input() public maxDisplayElements = 3;

  public get displayPopup(): boolean {
    return this.element.length > this.maxDisplayElements;
  }

  public labelPopperOptions: Partial<OptionsGeneric<StrictModifiers>> = {
    placement: 'bottom-start',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [-10, 10]
        }
      }
    ]
  };
  public truncated(): string {
    return joinAndTruncateList(this.element, this.maxDisplayElements);
  }

  public all(): string {
    return joinAndTruncateList(this.element, 0); // 0 means no limit
  }
}
