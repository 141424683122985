import { Component, Input } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ResponsiveService } from '../../services/responsiveness/responsive.service';

@Component({
  selector: 'ui-shell-action',
  template: `
    <ui-link-button
      *ngIf="isMobile$ | async; else desktop"
      [title]="title"
      size="medium"
      border
    >
      <ng-container prefix>
        <ng-container prefix *ngTemplateOutlet="prefix"></ng-container>
      </ng-container>
      <ng-container suffix>
        <ng-container *ngTemplateOutlet="suffix"></ng-container>
      </ng-container>
      <mat-icon suffix svgIcon="icon_arrow-line" class="arrow"></mat-icon>
    </ui-link-button>
    <ng-template #desktop>
      <ui-link-button [title]="title">
        <ng-container prefix>
          <ng-container *ngTemplateOutlet="prefix"></ng-container>
        </ng-container>
        <ng-container suffix>
          <ng-container *ngTemplateOutlet="suffix"></ng-container>
        </ng-container>
      </ui-link-button>
    </ng-template>
    <ng-template #prefix>
      <ng-content select="[prefix]"></ng-content>
    </ng-template>
    <ng-template #suffix>
      <ng-content select="[suffix]"></ng-content>
    </ng-template>
  `,
  styleUrls: ['./action.component.scss']
})
export class ActionComponent {
  @Input({ required: true }) public title!: string;

  public isMobile$: Observable<boolean>;

  constructor(responsiveService: ResponsiveService) {
    this.isMobile$ = responsiveService.responsiveFlags$.pipe(
      map(flags => flags.mobile)
    );
  }
}
