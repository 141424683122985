import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable, Subject, map, startWith, takeUntil } from 'rxjs';
import { Link } from '../../link/link.component';
import { SiteIntl } from '../site-intl';
import { Site } from '../site.model';

@Component({
  selector: 'ui-shell-site-search',
  template: ` <div class="other">
      <ui-sidepanel-title [title]="intl.sitesLabel" size="small">
        <ui-shell-link
          *ngIf="link"
          [link]="link"
          data-testid="link"
        ></ui-shell-link>
      </ui-sidepanel-title>
    </div>

    <form [formGroup]="sitesForm">
      <mat-form-field class="search">
        <input
          matInput
          [placeholder]="intl.searchLabel"
          formControlName="siteGroup"
          data-testid="search-input"
        />
        <mat-icon
          matSuffix
          (click)="clearSearchField($event)"
          *ngIf="searchField"
          data-testid="clear-search"
          >close
        </mat-icon>
        <mat-icon matPrefix>search</mat-icon>
      </mat-form-field>
      <div class="list">
        <ng-container *ngIf="siteOptions$">
          <div class="list-item" *ngFor="let site of siteOptions$ | async">
            <ui-link-button
              [title]="site.site"
              [subtitle]="site.group"
              (click)="changeSite(site.group || '', site.site)"
              data-testid="site-button"
              border
              background="light"
            ></ui-link-button>
          </div>
        </ng-container>
      </div>
    </form>`,
  styleUrls: ['./site-search.component.scss']
})
export class SiteSearchComponent implements OnInit, OnDestroy {
  private readonly _destroySubject = new Subject<void>();

  @Input() public sites: Site[] = [];
  @Input() public link?: Link;

  @Output() public handleSiteChange = new EventEmitter<Site>();

  public sitesForm = this._formBuilder.group({
    siteGroup: ''
  });
  public siteOptions$: Observable<Site[]> | undefined;

  constructor(
    private _formBuilder: FormBuilder,
    public intl: SiteIntl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    intl.changes$
      .pipe(takeUntil(this._destroySubject))
      .subscribe(() => changeDetectorRef.markForCheck());
  }

  public ngOnInit(): void {
    this.siteOptions$ = this.sitesForm.controls.siteGroup.valueChanges.pipe(
      startWith(null),
      map(value => this._filterGroup(value))
    );
  }

  public ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  private _filterGroup(value?: string | null): Site[] {
    if (!value || value === '') {
      return this.sites;
    }

    return this.sites.filter(site =>
      site.site.toLowerCase().includes(value.toLowerCase())
    );
  }

  public get searchField(): string | null {
    return this.sitesForm.controls.siteGroup.value;
  }

  public clearSearchField(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.sitesForm.controls.siteGroup.setValue('');
  }

  public changeSite(group: string, site: string): void {
    this.handleSiteChange.emit({ group, site: site });
  }
}
