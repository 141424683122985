import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponsiveService } from '../../services/responsiveness/responsive.service';
import { Link } from '../link/link.component';
import { Site, SiteGroup } from './site.model';
import { TrafficLightModel } from './traffic-light/traffic-light.model';

@Component({
  selector: 'ui-shell-site',
  template: `
    <ui-shell-mobile-site-selector
      *ngIf="isMobile$ | async; else desktop"
      [activeSite]="site"
      [sites]="sites"
      [trafficLight]="trafficLight"
      [searchLink]="searchLink"
      (handleSiteChange)="handleSiteChange.emit($event)"
      data-testid="mobile-site-selector"
    ></ui-shell-mobile-site-selector>

    <ng-template #desktop>
      <div class="desktop" data-testid="desktop">
        <ui-shell-site-selector
          *ngIf="siteGroups && sites.length > 1"
          [activeSite]="site"
          [sites]="sites"
          [searchLink]="searchLink"
          (handleSiteChange)="handleSiteChange.emit($event)"
          data-testid="site-selector"
        ></ui-shell-site-selector>
        <ui-shell-traffic-light
          *ngIf="trafficLight"
          [state]="trafficLight.state"
          [value]="trafficLight.value"
          data-testid="traffic-light"
        ></ui-shell-traffic-light>
      </div>
    </ng-template>
  `,
  styleUrls: ['./site.component.scss']
})
export class SiteComponent implements OnChanges {
  @Input() public site?: Site;
  @Input() public siteGroups?: SiteGroup[];
  @Input() public trafficLight?: TrafficLightModel;
  @Input() public searchLink?: Link;

  @Output() public handleSiteChange = new EventEmitter<Site>();

  public isMobile$: Observable<boolean>;

  public sites: Site[];

  constructor(responsiveService: ResponsiveService) {
    this.isMobile$ = responsiveService.responsiveFlags$.pipe(
      map(x => x.mobile)
    );

    this.sites = this._getSites();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['siteGroups']) {
      this.sites = this._getSites();
    }
  }

  private _getSites(): Site[] {
    return (
      this.siteGroups?.reduce(
        (acc, curr) => [
          ...acc,
          ...curr.sites.map<Site>(site => ({
            site,
            group: curr.groupName
          }))
        ],
        new Array<Site>()
      ) || []
    );
  }
}
