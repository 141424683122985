import { Component, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { ActiveNavigationItemService } from '../../../services/active-navigation-item.service';
import { LevelPanelToggleService } from '../../../services/behavior/level-panel-toggle.service';
import {
  BaseNavigationItem,
  NavigationItem
} from '../nav-item/navigation-item.model';

@Component({
  selector: 'ui-shell-nav-levelpanel',
  template: `
    <ng-container *ngIf="selectedItem$ | async as selectedItem">
      <div class="title">{{ selectedItem.label }}</div>
      <nav class="level" data-testid="menu">
        <ui-shell-nav-item
          *ngFor="let item of selectedItem.children; trackBy: trackByKey"
          [label]="item.label"
          [link]="buildLink(selectedItem, item)"
          [exactMatch]="item.exactMatch"
          [disabled]="item.disabled"
          [additionalTooltipText]="item.tooltip"
          [badge]="item.badge"
          [preserveQueryParams]="item.preserveQueryParams"
          [preserveFragment]="item.preserveFragment"
          (click)="closeLevelMenu()"
        ></ui-shell-nav-item>
      </nav>
    </ng-container>
  `,
  styleUrls: ['nav-levelpanel.component.scss']
})
export class NavLevelpanelComponent {
  public selectedItem$: Observable<NavigationItem>;

  @HostListener('mouseleave', ['$event'])
  public onMouseLeave(): void {
    this.closeLevelMenu();
  }

  constructor(
    private _toggleService: LevelPanelToggleService,
    activeNavigationItemService: ActiveNavigationItemService
  ) {
    this.selectedItem$ = activeNavigationItemService.selectedItem$;
  }

  public buildLink(parent: NavigationItem, child: NavigationItem): string[] {
    return (parent.link ?? []).concat(child.link ?? []);
  }

  public closeLevelMenu(): void {
    this._toggleService.close();
  }

  public trackByKey(_index: number, item: BaseNavigationItem): string {
    return item.key;
  }
}
