import { Component, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { ExtendedPanelToggleService } from '../../../services/behavior/extended-panel-toggle.service';
import { BrandDetailsService } from '../../../services/brand-details.service';
import { NavigationService } from '../../../services/navigation.service';
import { SettingsActionService } from '../../../services/settings-action.service';
import { ShellIntl } from '../../shell-intl';
import {
  BaseNavigationItem,
  ExtendedNavigationItem,
  NavigationItem
} from '../nav-item/navigation-item.model';

@Component({
  selector: 'ui-shell-nav-extendedpanel',
  template: `
    <header>
      <div class="logo">
        <div class="logo-icon"></div>
        <div class="logo-stack"></div>
      </div>
      <ui-icon-button
        icon="close"
        theme="tertiary"
        aria-label="Close menu"
        (click)="closeMenu()"
      >
      </ui-icon-button>
    </header>
    <nav class="extended">
      <div data-testid="menu">
        <ng-container
          *ngFor="let item of navigationItems$ | async; trackBy: trackByKey"
        >
          <ui-shell-nav-item
            *ngIf="!hasChildren(item.children)"
            [label]="item.label"
            [small]="false"
            [link]="item.link"
            [exactMatch]="item.exactMatch"
            [icon]="item.iconName"
            [customIcon]="item.customIcon"
            [disabled]="item.disabled"
            [additionalTooltipText]="item.tooltip"
            [badge]="item.badge"
            [preserveQueryParams]="item.preserveQueryParams"
            [preserveFragment]="item.preserveFragment"
            (linkClick)="closeMenu()"
            data-testid="nav-item"
          ></ui-shell-nav-item>
          <ui-shell-nav-expandable-item
            *ngIf="hasChildren(item.children)"
            [label]="item.label"
            [small]="false"
            [link]="item.link"
            [exactMatch]="item.exactMatch"
            [icon]="item.iconName"
            [customIcon]="item.customIcon"
            [disableLink]="hasChildren(item.children)"
            [children]="item.children || []"
            [expanded]="hasActiveChild(item.children)"
            [disabled]="item.disabled"
            [additionalTooltipText]="item.tooltip"
            [badge]="getBadge(item)"
            (linkClick)="closeMenu()"
            data-testid="nav-expandable-item"
          ></ui-shell-nav-expandable-item>
        </ng-container>
      </div>
      <ng-container *ngIf="settingsActionService.action">
        <div class="divider"></div>
        <div data-testid="general-menu" class="general">
          <ui-shell-nav-item
            [label]="intl.settingsLabel"
            [small]="false"
            [customIcon]="true"
            [icon]="'icon_settings'"
            (click)="clickOnSettings()"
          ></ui-shell-nav-item>
        </div>
      </ng-container>
      <ng-container
        *ngIf="extendedNavigationItems$ | async as extendedNavigationItems"
      >
        <ng-container *ngIf="extendedNavigationItems.length > 0">
          <div class="divider"></div>
          <div data-testid="extended-menu" class="extended">
            <ui-shell-nav-item
              *ngFor="let item of extendedNavigationItems; trackBy: trackByKey"
              [label]="item.label"
              [small]="false"
              [link]="item.link"
              [disableLink]="!!item.action"
              [disabled]="item.disabled"
              [additionalTooltipText]="item.tooltip"
              (linkClick)="performAction(item)"
            ></ui-shell-nav-item>
          </div>
        </ng-container>
      </ng-container>
    </nav>
    <footer>
      <ui-shell-corporate-branding
        [details]="brandDetailsService.brandDetails$ | async"
      ></ui-shell-corporate-branding>
    </footer>
  `,
  styleUrls: ['nav-extendedpanel.component.scss']
})
export class NavExtendedpanelComponent {
  public navigationItems$: Observable<NavigationItem[]>;
  public extendedNavigationItems$: Observable<ExtendedNavigationItem[]>;

  @HostListener('document:mousedown', ['$event'])
  public onClick(event: MouseEvent): void {
    if (!this._elementRef.nativeElement.contains(event.target)) {
      this.closeMenu();
    }
  }

  constructor(
    public settingsActionService: SettingsActionService,
    private _extendedPanelToggleService: ExtendedPanelToggleService,
    navigationService: NavigationService,
    private _router: Router,
    private _elementRef: ElementRef,
    public brandDetailsService: BrandDetailsService,
    public intl: ShellIntl
  ) {
    this.navigationItems$ = navigationService.navigationConfig$.pipe(
      map(config => config.navigationItems)
    );
    this.extendedNavigationItems$ = navigationService.navigationConfig$.pipe(
      map(config => config.extendedNavigationItems)
    );
  }

  public hasChildren(children?: NavigationItem[]): boolean | undefined {
    return children && children?.length > 0;
  }

  public clickOnSettings(): void {
    this.settingsActionService.action?.do();
  }

  public performAction(item: ExtendedNavigationItem): void {
    if (item.action) {
      item.action.do();
    }
    this._extendedPanelToggleService.close();
  }
  public closeMenu(): void {
    this._extendedPanelToggleService.close();
  }

  public hasActiveChild(children?: NavigationItem[]): boolean {
    if (!children) {
      return false;
    }

    const currentLink = this._router.url;
    return children.some(
      child => child.link && currentLink.includes(child.link.join('/'))
    );
  }

  public trackByKey(_index: number, item: BaseNavigationItem): string {
    return item.key;
  }

  public getBadge(item: NavigationItem): number | undefined {
    if (item.badge !== undefined) {
      return item.badge;
    }

    if (item.children?.some(child => child.badge !== undefined)) {
      return 0;
    }

    return undefined;
  }
}
