import { UrlAction } from './url-action';

export class EmailAction extends UrlAction {
  constructor(email: string) {
    super(`mailto:${email}`);

    if (!this._validateEmail(email)) {
      throw new Error(`Invalid email: ${email}`);
    }
  }

  private _validateEmail(email: string): RegExpMatchArray | null {
    return String(email)
      .toLowerCase()
      .match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
  }
}
