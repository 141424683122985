<mat-form-field (click)="opened()">
  <mat-label>{{ label }}</mat-label>
  <input
    title="filter"
    type="text"
    matInput
    [matAutocomplete]="auto"
    #trigger="matAutocompleteTrigger"
    [errorStateMatcher]="errorStateMatcher"
    [placeholder]="placeHolder"
    [formControl]="queryControl"
    (keydown.escape)="resetQueryControl()"
  />
  <input style="display: none" [formControl]="control" />
  <mat-icon matSuffix>search</mat-icon>
  <mat-error>{{ getErrors() }}</mat-error>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayWith"
    (optionSelected)="setSelection($event.option.value)"
  >
    <mat-option
      *ngFor="let option of filteredOptions$ | async"
      [value]="option"
      [style.height.px]="itemSize"
    >
      <ng-container
        *ngTemplateOutlet="
          optionTemplate ?? fallBackOption;
          context: { option }
        "
      ></ng-container>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
<ng-template #fallBackOption let-option="option">
  {{ displayWith(option) }}
</ng-template>
