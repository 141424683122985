import { booleanAttribute, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-link-button',
  template: `
    <div
      class="link-button size-{{ size }} background-{{ background }}"
      [class.border]="border"
      [class.active]="active"
      [class.disabled]="disabled"
      [class.has-subtitle]="subtitle"
    >
      <div class="link-button-title">
        <div class="prefix">
          <ng-content select="[prefix]"></ng-content>
        </div>
        <div
          class="link-title"
          *ngIf="!subtitle || subtitle.length === 0; else withSubtitle"
        >
          {{ title }}
        </div>
        <ng-template #withSubtitle>
          <div class="link-title-container">
            <div class="link-title">{{ title }}</div>
            <div class="link-subtitle">{{ subtitle }}</div>
          </div>
        </ng-template>
      </div>
      <div class="suffix">
        <ng-content select="[suffix]"></ng-content>
      </div>
    </div>
  `,
  styleUrls: ['./link-button.component.scss']
})
export class LinkButtonComponent {
  @Input() public size?: LinkButtonSize = 'default';
  @Input() public background?: LinkButtonBackground = 'none';
  @Input({ transform: booleanAttribute }) public border? = false;
  @Input({ required: true }) public title!: string;
  @Input() public subtitle?: string;
  @Input({ transform: booleanAttribute }) public active? = false;
  @Input({ transform: booleanAttribute }) public disabled? = false;
}

export type LinkButtonSize = 'default' | 'medium';
export type LinkButtonBackground = 'none' | 'light';
