import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { MobileMenuToggleService } from '../../../services/behavior/mobile-menu-toggle.service';
import { MobileSiteSelectorToggleService } from '../../../services/behavior/mobile-site-selector-toggle.service';
import { ShellIntl } from '../../shell-intl';

@Component({
  selector: 'ui-shell-nav-mobile',
  template: `
    <div
      class="nav-mobile-container open"
      *ngIf="mobileMenutoggleService.open$ | async; else showClose"
    >
      <mat-icon (click)="closeMobileMenu()">close</mat-icon>
      <span (click)="closeMobileMenu()">{{ intl.closeMenuLabel }}</span>
    </div>
    <ng-template #showClose>
      <div class="nav-mobile-container">
        <mat-icon (click)="openMobileMenu()">menu</mat-icon>
        <div class="logo"></div>
        <div>
          <ng-content select="[profile]"></ng-content>
        </div>
      </div>
    </ng-template>
  `,
  styleUrls: ['./nav-mobile.component.scss']
})
export class NavMobileComponent implements OnDestroy {
  private readonly _destroySubject = new Subject<void>();

  constructor(
    public mobileMenutoggleService: MobileMenuToggleService,
    private _mobileSiteSelectorToggleService: MobileSiteSelectorToggleService,
    public intl: ShellIntl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    intl.changes$
      .pipe(takeUntil(this._destroySubject))
      .subscribe(() => changeDetectorRef.markForCheck());
  }

  public ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  public openMobileMenu = (): void => {
    this.mobileMenutoggleService.open();
    this._mobileSiteSelectorToggleService.close();
  };

  public closeMobileMenu = (): void => {
    this.mobileMenutoggleService.close();
  };
}
