import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CustomAction } from '../../../models/actions';
import { Link } from '../../link/link.component';
import { SiteIntl } from '../site-intl';
import { Site } from '../site.model';

@Component({
  selector: 'ui-shell-site-selector',
  template: `
    <button
      mat-stroked-button
      class="site"
      (click)="toggleSiteSelector()"
      data-testid="button"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
    >
      {{ this.activeSite ? this.activeSite.site : intl.chooseSiteLabel }}
      <mat-icon iconPositionEnd>arrow_drop_down</mat-icon>
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="siteSelectorCardIsOpen"
      [cdkConnectedOverlayPositions]="overlayPositions"
      [cdkConnectedOverlayOffsetY]="8"
      cdkConnectedOverlayPanelClass="site-selector-overlay"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      (backdropClick)="toggleSiteSelector()"
    >
      <div data-testid="overlay">
        <ui-sidepanel-title
          [title]="intl.changeSiteLabel"
          size="large"
        ></ui-sidepanel-title>
        <ng-container *ngIf="activeSite">
          <div class="current" data-testid="current-site">
            <ui-sidepanel-title
              [title]="intl.currentSiteLabel"
              size="small"
            ></ui-sidepanel-title>
            <ui-link-button
              [title]="activeSite.site"
              [subtitle]="activeSite.group"
              active
              border
            ></ui-link-button>
          </div>
          <hr />
        </ng-container>
        <ui-shell-site-search
          [sites]="sites"
          [link]="link"
          (handleSiteChange)="changeSite($event)"
        ></ui-shell-site-search>
      </div>
    </ng-template>
  `,
  styleUrls: ['./site-selector.component.scss']
})
export class SiteSelectorComponent implements OnChanges, OnDestroy {
  private readonly _destroySubject = new Subject<void>();

  public siteSelectorCardIsOpen = false;

  @Input() public activeSite: Site | undefined;
  @Input({ required: true }) public sites!: Site[];
  @Input() public searchLink?: Link;
  public link?: Link;

  @Output() public handleSiteChange = new EventEmitter<Site>();

  public overlayPositions: ConnectedPosition[] = [
    {
      originY: 'bottom',
      originX: 'start',
      overlayY: 'top',
      overlayX: 'start',
      offsetX: -8
    },
    {
      originY: 'bottom',
      originX: 'end',
      overlayY: 'top',
      overlayX: 'end',
      offsetX: 8
    }
  ];

  constructor(
    public intl: SiteIntl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    intl.changes$
      .pipe(takeUntil(this._destroySubject))
      .subscribe(() => changeDetectorRef.markForCheck());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['searchLink']) {
      this._updateLink();
    }
  }

  public ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }

  public toggleSiteSelector(): void {
    this.siteSelectorCardIsOpen = !this.siteSelectorCardIsOpen;
  }

  private _closeSiteSelector(): void {
    this.siteSelectorCardIsOpen = false;
  }

  public changeSite(site: Site): void {
    this._closeSiteSelector();
    this.handleSiteChange.emit(site);
  }

  private _updateLink(): void {
    if (!this.searchLink) {
      this.link = undefined;
      return;
    }

    this.link = {
      ...this.searchLink,
      action: new CustomAction(() => {
        //Site selector should also be closed when link is clicked
        this._closeSiteSelector();
        this.searchLink?.action?.do();
      })
    };
  }
}
