import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { ActiveNavigationItemService } from '../../services/active-navigation-item.service';
import { ExtendedPanelToggleService } from '../../services/behavior/extended-panel-toggle.service';
import { LevelPanelToggleService } from '../../services/behavior/level-panel-toggle.service';
import { NavigationService } from '../../services/navigation.service';
import { SettingsActionService } from '../../services/settings-action.service';
import { ShellIntl } from '../shell-intl';
import {
  BaseNavigationItem,
  NavigationItem
} from './nav-item/navigation-item.model';

@Component({
  selector: 'ui-shell-navbar',
  template: `
    <ng-container *ngIf="hasNavigationItems$ | async">
      <div class="brand-line"></div>
      <div class="header">
        <div class="logo"></div>
        <nav class="main">
          <div>
            <ui-shell-nav-item
              [label]="intl.openMenuLabel"
              [small]="true"
              preserveFragment
              preserveQueryParams
              icon="menu"
              (linkClick)="openExtendedMenu()"
              (mouseenter)="withTimeout(closeLevelMenu)"
              (mouseleave)="clearTimeout()"
              data-testid="hamburger-menu"
            ></ui-shell-nav-item>
          </div>
          <div class="divider"></div>
          <div data-testid="menu">
            <ui-shell-nav-item
              *ngFor="let item of navigationItems$ | async; trackBy: trackByKey"
              [label]="item.label"
              [small]="true"
              [link]="item.link"
              [exactMatch]="item.exactMatch"
              [icon]="item.iconName"
              [customIcon]="item.customIcon"
              [disableLink]="hasChildren(item.children)"
              [disableTooltip]="!item.disabled && hasChildren(item.children)"
              [disabled]="item.disabled"
              [additionalTooltipText]="item.tooltip"
              [badge]="showBadge(item)"
              [preserveQueryParams]="item.preserveQueryParams"
              [preserveFragment]="item.preserveFragment"
              (linkClick)="handleItemClick(item)"
              (mouseenter)="handleItemHover(item)"
              (mouseleave)="clearTimeout()"
              role="menu"
            ></ui-shell-nav-item>
          </div>
          <ng-container *ngIf="settingsMenuAction.action">
            <div class="divider"></div>
            <div class="general" data-testid="general-menu">
              <ui-shell-nav-item
                [label]="intl.settingsLabel"
                [small]="true"
                [customIcon]="true"
                [icon]="'icon_settings'"
                preserveQueryParams
                preserveFragment
                (linkClick)="clickOnSettings()"
                (mouseenter)="withTimeout(closeLevelMenu)"
                (mouseleave)="clearTimeout()"
                role="menu"
              ></ui-shell-nav-item>
            </div>
          </ng-container>
        </nav>
      </div>
      <ui-shell-nav-extendedpanel [class.open]="extendedMenuOpen$ | async">
      </ui-shell-nav-extendedpanel>
      <ui-shell-nav-levelpanel
        [class.open]="levelMenuOpen$ | async"
      ></ui-shell-nav-levelpanel>
    </ng-container>
  `,
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnDestroy {
  public extendedMenuOpen$: Observable<boolean>;
  public levelMenuOpen$: Observable<boolean>;
  public navigationItems$: Observable<NavigationItem[]>;
  public hasNavigationItems$: Observable<boolean>;

  private readonly _destroySubject = new Subject<void>();
  private _timeoutId?: ReturnType<typeof setTimeout>;

  constructor(
    private _extendPanelToggleService: ExtendedPanelToggleService,
    private _levelPanelToggleService: LevelPanelToggleService,
    navigationService: NavigationService,
    public settingsMenuAction: SettingsActionService,
    private _activeNavigationItemService: ActiveNavigationItemService,
    private _router: Router,
    public intl: ShellIntl,
    changeDetectorRef: ChangeDetectorRef
  ) {
    this.extendedMenuOpen$ = this._extendPanelToggleService.open$;
    this.levelMenuOpen$ = this._levelPanelToggleService.open$;
    this.navigationItems$ = navigationService.navigationConfig$.pipe(
      map(config => config.navigationItems)
    );
    this.hasNavigationItems$ = this.navigationItems$.pipe(
      map(x => x.length > 0)
    );
    this.extendedMenuOpen$
      .pipe(takeUntil(this._destroySubject))
      .subscribe(x => {
        if (x) {
          this._levelPanelToggleService.close();
        }
      });

    intl.changes$
      .pipe(takeUntil(this._destroySubject))
      .subscribe(() => changeDetectorRef.markForCheck());
  }

  public hasChildren(children?: NavigationItem[]): boolean {
    return !!children && children.length > 0;
  }

  public openExtendedMenu(): void {
    this._extendPanelToggleService.toggle();
  }

  public withTimeout(fn: () => void): void {
    if (this._timeoutId) {
      clearTimeout(this._timeoutId);
    }
    this._timeoutId = setTimeout(fn.bind(this), 100);
  }

  public clearTimeout(): void {
    if (!this._timeoutId) {
      return;
    }
    clearTimeout(this._timeoutId);
  }

  public handleItemHover(item: NavigationItem): void {
    if (item.disabled) {
      this.closeLevelMenu();
      return;
    }

    const handleHover = (): void => {
      if (!this.hasChildren(item.children)) {
        this.closeLevelMenu();
        return;
      }
      this.openLevelMenu(item);
    };

    this.withTimeout(handleHover);
  }

  public openLevelMenu(item: NavigationItem): void {
    if (!this.hasChildren(item.children)) return;
    this._levelPanelToggleService.open();
    this._activeNavigationItemService.selectedItem = item;
  }

  public closeLevelMenu(): void {
    this._levelPanelToggleService.close();
  }

  public handleItemClick(item: NavigationItem): void {
    if (
      !this.hasChildren(item.children) ||
      item.children?.every(child => child.disabled)
    ) {
      return;
    }
    const parentLink = item.link || [];
    const childLink = item.children?.find(child => !child.disabled)?.link || [];
    const link = [...parentLink, ...childLink];
    if (link.length === 0) {
      return;
    }
    this._router.navigate(link, {
      queryParamsHandling: item.preserveQueryParams ? 'preserve' : '',
      preserveFragment: item.preserveFragment
    });
  }

  public clickOnSettings(): void {
    this.settingsMenuAction.action?.do();
  }

  public trackByKey(_index: number, item: BaseNavigationItem): string {
    return item.key;
  }

  public showBadge(item: NavigationItem): number | undefined {
    if (
      item.badge !== undefined ||
      item.children?.some(child => child.badge !== undefined)
    ) {
      return 0;
    }
    return undefined;
  }

  public ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }
}
