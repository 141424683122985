import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  ExtendedNavigationItem,
  NavigationItem
} from '../shell/navbar/nav-item/navigation-item.model';
import {
  NAVIGATION_CONFIG,
  NavigationConfig
} from '../shell/navbar/navigation.model';

export enum NavigationServiceError {
  ParentNotFound = 'Parent Not Found',
  ItemNotFound = 'Item Not Found',
  IndexOutOfRange = 'Index Out Of Range',
  KeyAlreadyExists = 'Key Already Exists',
  KeysNotUnique = 'Keys Not Unique'
}

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private readonly _navigationConfigSubject =
    new BehaviorSubject<NavigationConfig>({
      navigationItems: [],
      extendedNavigationItems: []
    });
  public readonly navigationConfig$: Observable<NavigationConfig> =
    this._navigationConfigSubject.asObservable();

  constructor(@Inject(NAVIGATION_CONFIG) config: NavigationConfig) {
    this.update(config);
  }

  public update(config: NavigationConfig): void {
    Object.values(config).forEach(values => {
      this._checkKeys(values);
    });

    this._navigationConfigSubject.next(config);
  }

  // Adding elements
  public addItem(item: NavigationItem, index?: number): void {
    const oldConfig = this._navigationConfigSubject.value;

    this._checkIfItemExist(item.key, oldConfig.navigationItems, false);

    const newConfig: NavigationConfig = {
      ...oldConfig,
      navigationItems: this._insertItem(oldConfig.navigationItems, item, index)
    };

    this.update(newConfig);
  }

  public addExtendedItem(item: ExtendedNavigationItem, index?: number): void {
    const oldConfig = this._navigationConfigSubject.value;

    this._checkIfItemExist(item.key, oldConfig.extendedNavigationItems, false);

    const newConfig: NavigationConfig = {
      ...oldConfig,
      extendedNavigationItems: this._insertItem(
        oldConfig.extendedNavigationItems,
        item,
        index
      )
    };

    this.update(newConfig);
  }

  public addChildItem(
    parentKey: string,
    item: NavigationItem,
    index?: number
  ): void {
    const parentItem = this._getParent(parentKey);
    const children = parentItem.children || [];

    this._checkIfItemExist(item.key, children, false);

    const newParentItem: NavigationItem = {
      ...parentItem,
      children: this._insertItem(children, item, index)
    };

    this.updateItem(newParentItem.key, newParentItem);
  }

  // Removing elements
  public removeItem(key: string): void {
    const oldConfig = this._navigationConfigSubject.value;

    this._checkIfItemExist(key, oldConfig.navigationItems);

    const filteredItems = oldConfig.navigationItems.filter(
      item => item.key !== key
    );
    const newConfig: NavigationConfig = {
      ...oldConfig,
      navigationItems: filteredItems
    };

    this.update(newConfig);
  }

  public removeExtendedItem(key: string): void {
    const oldConfig = this._navigationConfigSubject.value;

    this._checkIfItemExist(key, oldConfig.extendedNavigationItems);

    const filteredItems = oldConfig.extendedNavigationItems.filter(
      item => item.key !== key
    );
    const newConfig: NavigationConfig = {
      ...oldConfig,
      extendedNavigationItems: filteredItems
    };

    this.update(newConfig);
  }

  public removeChildItem(parentKey: string, childKey: string): void {
    const parentItem = this._getParent(parentKey);
    const children = parentItem.children || [];

    this._checkIfItemExist(childKey, children);

    const filteredChildren = children.filter(item => item.key !== childKey);

    const newParentItem: NavigationItem = {
      ...parentItem,
      children:
        filteredChildren && filteredChildren.length > 0
          ? filteredChildren
          : undefined
    };
    this.updateItem(newParentItem.key, newParentItem);
  }

  // Updating elements
  public updateItem(key: string, item: NavigationItem): void {
    const oldConfig = this._navigationConfigSubject.value;

    const itemIndex = this._checkIfItemExist(key, oldConfig.navigationItems);

    const newItems = [...oldConfig.navigationItems];
    newItems[itemIndex] = item;

    const newConfig: NavigationConfig = {
      ...oldConfig,
      navigationItems: newItems
    };

    this.update(newConfig);
  }

  public updateExtendedItem(key: string, item: ExtendedNavigationItem): void {
    const oldConfig = this._navigationConfigSubject.value;

    const itemIndex = this._checkIfItemExist(
      key,
      oldConfig.extendedNavigationItems
    );

    const newItems = [...oldConfig.extendedNavigationItems];
    newItems[itemIndex] = item;

    const newConfig: NavigationConfig = {
      ...oldConfig,
      extendedNavigationItems: newItems
    };

    this.update(newConfig);
  }

  public updateChildItem(
    parentKey: string,
    childKey: string,
    item: NavigationItem
  ): void {
    const parentItem = this._getParent(parentKey);
    const children = parentItem.children || [];

    const childIndex = this._checkIfItemExist(childKey, children);

    const newChildren = [...children];
    newChildren[childIndex] = item;

    const newParentItem: NavigationItem = {
      ...parentItem,
      children: newChildren
    };

    this.updateItem(parentKey, newParentItem);
  }

  private _insertItem<T>(items: T[], newItem: T, index?: number): T[] {
    if (index === undefined) {
      return [...items, newItem];
    }

    if (index > items.length) {
      throw new Error(NavigationServiceError.IndexOutOfRange);
    }

    const newItems = [...items];
    newItems.splice(index, 0, newItem);

    return newItems;
  }

  private _checkIfItemExist<T extends { key: string }>(
    key: string,
    items: T[],
    shouldExist = true
  ): number {
    const itemIndex = items.findIndex(i => i.key === key);
    if (itemIndex === -1 && shouldExist) {
      throw new Error(NavigationServiceError.ItemNotFound);
    }
    if (itemIndex !== -1 && !shouldExist) {
      throw new Error(NavigationServiceError.KeyAlreadyExists);
    }
    return itemIndex;
  }

  private _checkKeys<T extends { key: string; children?: T[] }>(
    items: T[]
  ): void {
    const keys = items.map(i => i.key);
    const uniqueKeys = new Set(keys);

    if (keys.length !== uniqueKeys.size) {
      throw new Error(NavigationServiceError.KeysNotUnique);
    }

    items.forEach(({ children }) => {
      if (!children || children.length === 0) {
        return;
      }
      this._checkKeys(children);
    });
  }

  private _getParent(parentKey: string): NavigationItem {
    const oldConfig = this._navigationConfigSubject.value;
    const parentItem = oldConfig.navigationItems.find(
      item => item.key === parentKey
    );

    if (parentItem === undefined) {
      throw new Error(NavigationServiceError.ParentNotFound);
    }

    return parentItem;
  }
}
