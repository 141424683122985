import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-shell-traffic-light',
  template: `
    <div class="traffic-light-btn {{ state }}">
      <span>{{ value }}</span>
    </div>
  `,
  styleUrls: ['./traffic-light.component.scss']
})
export class TrafficLightComponent {
  @Input() public state: 'success' | 'warning' | 'error' | undefined;
  @Input() public value: string | undefined;
}
