import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Observable, ReplaySubject, takeUntil } from 'rxjs';
import { Portal } from '../../services/bridge/base-bridge.service';
import { PageActionsBridgeService } from '../../services/bridge/page-actions-bridge.service';
import { PageTitleService } from '../../services/page-title.service';

@Component({
  selector: 'ui-shell-topbar-mobile',
  template: `
    <div
      class="title-wrapper"
      [class.clickable]="hasPageActions"
      (click)="toggleMenuChildOverlayer()"
    >
      <div>
        <span class="title">{{ title$ | async }}</span>
        <mat-icon *ngIf="hasPageActions && !showPageActions"
          >arrow_drop_down</mat-icon
        >
      </div>
      <ui-icon-button
        size="large"
        theme="tertiary"
        icon="close"
        aria-label="close page actions"
        data-testid="close-actions"
        *ngIf="hasPageActions && showPageActions"
      ></ui-icon-button>
    </div>
    <div *ngIf="showPageActions" class="page-actions">
      <ng-template [cdkPortalOutlet]="portal$ | async"></ng-template>
    </div>
  `,
  styleUrls: ['./topbar-mobile.component.scss']
})
export class TopbarMobileComponent implements OnInit, OnDestroy {
  public title$: Observable<string>;

  private _onDestroySubject = new ReplaySubject<void>(1);

  public portal$!: Observable<Portal>;

  public hasPageActions = false;
  public showPageActions = false;

  constructor(
    pageTitleService: PageTitleService,
    private _pageActionsBridgeService: PageActionsBridgeService,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.title$ = pageTitleService.pageTitle$;
  }

  public ngOnInit(): void {
    this.portal$ = this._pageActionsBridgeService.portal$;
    this._pageActionsBridgeService.activePageItems$
      .pipe(takeUntil(this._onDestroySubject))
      .subscribe(x => {
        this.hasPageActions = x > 0;

        if (!this.hasPageActions) {
          this.showPageActions = false;

          this.closeMenuChildOverlayer();
        }
      });
  }

  public toggleMenuChildOverlayer(): void {
    if (!this.hasPageActions) return;
    this.showPageActions = !this.showPageActions;

    this.closeMenuChildOverlayer();
  }

  public closeMenuChildOverlayer(): void {
    this._renderer.removeClass(this._document.body, 'hide-main-panel');
    if (this.showPageActions) {
      this._renderer.addClass(this._document.body, 'hide-main-panel');
    }
  }

  public ngOnDestroy(): void {
    this._renderer.removeClass(this._document.body, 'hide-main-panel');
    this._onDestroySubject.next();
    this._onDestroySubject.complete();
  }
}
