import { UrlAction } from './url-action';

export class PhoneAction extends UrlAction {
  constructor(phoneNumber: string) {
    super(`tel:${phoneNumber}`);

    if (!this._validatePhoneNumber(phoneNumber)) {
      throw new Error(`Invalid phone number: ${phoneNumber}`);
    }
  }

  private _validatePhoneNumber(phoneNumber: string): RegExpMatchArray | null {
    return String(phoneNumber)
      .toLowerCase()
      .match(/^[+]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/);
  }
}
