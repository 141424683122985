import { Directive, Input, TemplateRef } from '@angular/core';

/**
 * Structural directive used to override a column in the generic table.
 * Gets passed the element as $implicit context and the currentFilter is also available as element.
 * The uiOverrideColumn expects a string that matches a key of the table to override (should match with your structure)
 * Example usage: *uiOverrideColumn="'mode'; let element; let filter = currentFilter"
 *
 * You can also pass a sortin override via overRideSortHeader for example:
 * *uiOverrideColumn="'loads';let element;overrideSortHeader: 'loadIds'"
 */
@Directive({
  selector: '[uiOverrideColumn]'
})
export class OverrideColumnDirective {
  @Input() public uiOverrideColumn = '';
  constructor(public template: TemplateRef<unknown>) {}

  @Input() public uiOverrideColumnOverrideSortHeader?: string;
}
