import { Inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, PRIMARY_OUTLET, Router } from '@angular/router';
import { Application, Brand, ThemeAndBrandService } from '@onyx/core';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  filter,
  map,
  startWith
} from 'rxjs';
import { brandDetailsList } from '../config/brand-details';
import { NavigationItem } from '../shell/navbar/nav-item/navigation-item.model';
import { DEFAULT_TITLE } from '../shell/navbar/navigation.model';
import { ActiveNavigationItemService } from './active-navigation-item.service';
import { PageBackLink } from './page-back-link.model';

@Injectable({
  providedIn: 'root'
})
export class PageTitleService {
  private _currentPageTitleSubject = new BehaviorSubject<
    string | null | undefined
  >(undefined);
  private _currentBackLinkSubject = new BehaviorSubject<
    PageBackLink | null | undefined
  >(undefined);

  private get _browserPageTitle$(): Observable<string> {
    return combineLatest([
      this.pageTitle$.pipe(startWith('')),
      this._themeAndBrandingService.application$.pipe(
        startWith(Application.Suite)
      ),
      this._themeAndBrandingService.brand$.pipe(startWith(Brand.Kion))
    ]).pipe(
      map(([pageTitle, application, brand]) => {
        return `${pageTitle} - ${
          brandDetailsList[brand].productName
        }${application.toString()}`;
      })
    );
  }

  public listenForBrowserPageTitle(): void {
    this._browserPageTitle$.subscribe((title: string) => {
      this._titleService.setTitle(title);
    });
  }

  public get currentBackLink$(): Observable<PageBackLink | null | undefined> {
    return this._currentBackLinkSubject.asObservable();
  }
  constructor(
    private _router: Router,
    private _themeAndBrandingService: ThemeAndBrandService,
    private _titleService: Title,
    @Inject(DEFAULT_TITLE) private _defaultTitle: string,
    private _activeNavigationItemService: ActiveNavigationItemService
  ) {}

  public get pageTitle$(): Observable<string> {
    const url$ = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => (event as NavigationEnd).urlAfterRedirects),
      startWith(this._router.url)
    );

    const activePageTitle$: Observable<string | null> = combineLatest([
      url$,
      this._activeNavigationItemService.activeItem$
    ]).pipe(
      map(([url, activeItem]) =>
        activeItem ? this.constructTitle(url, activeItem) : null
      )
    );

    return combineLatest([
      activePageTitle$,
      this._currentPageTitleSubject
    ]).pipe(
      map(([activePageTitle, currentPageTitle]) => {
        return currentPageTitle ?? activePageTitle ?? this._defaultTitle;
      })
    );
  }

  public setPageTitle(pageTitle?: string | null): void {
    this._currentPageTitleSubject.next(pageTitle);
  }

  public setBackLink(backLink?: PageBackLink | null): void {
    this._currentBackLinkSubject.next(backLink);
  }

  public leavePage(): void {
    this._currentPageTitleSubject.next(undefined);
    this._currentBackLinkSubject.next(undefined);
  }

  public constructTitle(
    url: string,
    activeParent: NavigationItem
  ): string | null {
    const cleanUrl = this._getUrlWithoutParams(url).slice(1);
    const parentLink = activeParent.link?.join('/') ?? '';

    if (!cleanUrl.startsWith(parentLink)) {
      return null;
    }

    if (
      !activeParent.children ||
      activeParent.children.length === 0 ||
      cleanUrl === parentLink
    ) {
      return activeParent.label ?? null;
    }

    const childUrl = cleanUrl.slice(parentLink.length + 1);

    const activeChild = activeParent.children?.find(
      c => c.link && c.link.join('/') === childUrl
    );

    if (!activeChild) {
      return activeParent.label ?? null;
    }

    if (activeChild.label) {
      if (activeParent.label) {
        return `${activeParent.label}/${activeChild.label}`;
      }
      return activeChild.label;
    }
    return activeParent.label ?? null;
  }

  private _getUrlWithoutParams(url: string): string {
    const urlTree = this._router.parseUrl(url);
    const segments = urlTree.root.children[PRIMARY_OUTLET]?.segments ?? [];
    return segments.reduce((prev, curr) => `${prev}/${curr.path}`, '');
  }
}
