/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { ResponsiveService } from './responsive.service';

@Directive({
  selector: '[uiShellResponsive]'
})
export class ResponsiveDirective implements OnChanges, OnDestroy {
  @Input('uiShellResponsive') public requestedFlags: string[] = [];
  @Input('uiShellResponsiveInverse') public inverse = false;
  private readonly _destroySubject = new Subject();
  private readonly _requestedFlagsSubject = new Subject<string[]>();
  private _shown = false;

  constructor(
    responsiveService: ResponsiveService,
    templateRef: TemplateRef<any>,
    viewContainerRef: ViewContainerRef
  ) {
    combineLatest([
      responsiveService.responsiveFlags$,
      this._requestedFlagsSubject
    ])
      .pipe(
        map(([flags, requestedFlags]) => {
          const currentFlags = Object.getOwnPropertyNames(flags).filter(
            x => (flags as any)[x]
          );
          const containsFlags = requestedFlags.every(f =>
            currentFlags.includes(f)
          );
          // xor with inverse
          return containsFlags ? !this.inverse : this.inverse;
        }),
        distinctUntilChanged(),
        takeUntil(this._destroySubject)
      )
      .subscribe(show => {
        if (show && !this._shown) {
          this._shown = true;
          viewContainerRef.createEmbeddedView(templateRef);
        } else if (!show && this._shown) {
          this._shown = false;
          viewContainerRef.clear();
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['requestedFlags']) {
      this._requestedFlagsSubject.next(this.requestedFlags);
    }
  }
  public ngOnDestroy(): void {
    this._destroySubject.next(undefined);
    this._destroySubject.complete();
  }
}
