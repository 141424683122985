import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-shell-global-settings-nav-item',
  template: `
    <ng-container *ngIf="isMobile; else desktop">
      <ui-link-button class="mobile" [title]="title" size="medium" border>
        <mat-icon prefix *ngIf="icon && !customIcon">{{ icon }}</mat-icon>
        <mat-icon prefix *ngIf="icon && customIcon" [svgIcon]="icon"></mat-icon>
        <mat-icon suffix class="rotate" svgIcon="icon_arrow-line"></mat-icon
      ></ui-link-button>
    </ng-container>
    <ng-template #desktop>
      <ui-link-button [title]="title" [active]="active">
        <mat-icon prefix *ngIf="icon && !customIcon">{{ icon }}</mat-icon>
        <mat-icon prefix *ngIf="icon && customIcon" [svgIcon]="icon"></mat-icon>
      </ui-link-button>
    </ng-template>
  `
})
export class ShellGlobalSettingsNavItemComponent {
  @Input({ required: true }) public title!: string;
  @Input() public icon?: string;
  @Input() public customIcon?: boolean = false;
  @Input() public active?: boolean = false;
  @Input() public isMobile?: boolean = false;
}
