import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-card',
  template: `
    <div
      class="card-container padding-{{ padding }}"
      [class.clickable]="clickable"
      [class.rounded]="rounded"
      [class.border]="border"
      [class.tile]="tile"
      [class.selected]="selected"
      [class.headerOnly]="headerOnly"
      [class.backgroundColor]="backgroundColor"
    >
      <ng-content select="ui-card-header"></ng-content>
      <div class="card-content" *ngIf="!headerOnly" data-testid="content">
        <ng-content></ng-content>
      </div>
      <ng-content select="ui-card-footer"></ng-content>
    </div>
  `,
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() public clickable = false;
  @Input() public rounded = false;
  @Input() public border = false;
  @Input() public tile = false;
  @Input() public headerOnly = false;
  @Input() public selected = false;
  @Input() public backgroundColor = false;
  @Input() public padding: CardComponentPadding = 'default'; //default || small || none
}

@Component({
  selector: 'ui-card-header',
  template: ` <ng-content></ng-content> `,
  styleUrls: ['./card-header.component.scss']
})
export class CardHeaderComponent {
  @Input() public compact = false; // Currently not used
}

@Component({
  selector: 'ui-card-footer',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    `
  ]
})
export class CardFooterComponent {}

export type CardComponentPadding = 'default' | 'small' | 'none';
