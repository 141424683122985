import { CommonModule } from '@angular/common';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

class AssetLocationConfig {
  public assetsLocation = '';
}

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class IconsModule {
  private _assetLocationConfig: AssetLocationConfig;
  constructor(
    @Optional() @SkipSelf() module: IconsModule,
    matIconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Optional() config?: AssetLocationConfig
  ) {
    if (module) {
      throw new Error('ExtendMaterialIconsModule is loaded more than once!');
    }
    config
      ? (this._assetLocationConfig = config)
      : (this._assetLocationConfig = { assetsLocation: 'assets/icons' });
    CustomIcons.forEach(iconName => {
      matIconRegistry.addSvgIcon(
        iconName,
        sanitizer.bypassSecurityTrustResourceUrl(
          `${this._assetLocationConfig.assetsLocation}/${iconName}.svg`
        )
      );
    });
  }

  public static forRoot(
    config: AssetLocationConfig
  ): ModuleWithProviders<IconsModule> {
    return {
      ngModule: IconsModule,
      providers: [{ provide: AssetLocationConfig, useValue: config }]
    };
  }
}

export const CustomIcons = [
  'icon_user',
  'icon_usergroup',
  'icon_role',
  'icon_monitor',
  'icon_settings',
  'icon_toggle',
  'icon_close',
  'icon_search',
  'icon_assigned-vehicle',
  'icon_command',
  'icon_created',
  'icon_destination',
  'icon_id',
  'icon_load',
  'icon_location',
  'icon_priority',
  'icon_source',
  'icon_expand',
  'icon_expand2',
  'icon_error-alert',
  'icon_error',
  'icon_error_white',
  'icon_warning_alert',
  'icon_warning',
  'icon_info',
  'icon_swipe',
  'icon_tenant',
  'icon_plus',
  'icon_min',
  'icon_reset',
  'icon_arrow',
  'icon_arrow-line',
  'icon_pin',
  'icon_unpin',
  'icon_more',
  'icon_state',
  'icon_experimental',
  'icon_feature',
  'icon_feature2',
  'icon_bugfixes',
  'icon_delete',
  'icon_repeat',
  'icon_users',
  'icon_check',
  'icon_maintenance',
  'icon_maintenance_white',
  'icon_mode-automatic',
  'icon_mode-automatic_white',
  'icon_mode-semi-auto',
  'icon_mode-semi-auto_white',
  'icon_mode-manual',
  'icon_mode-manual_white',
  'icon_mode-removed',
  'icon_mode-removed_white',
  'icon_mode-out-of-service',
  'icon_mode-unavailable',
  'icon_mode-unavailable_white',
  'icon_status-restart',
  'icon_stop',
  'icon_stop_white',
  'icon_stop2',
  'icon_play',
  'icon_out-of-service',
  'icon_out-of-service_white',
  'icon_report',
  'icon_pop-out',
  'icon_jobs',
  'icon_deploy',
  'icon_AGV',
  'icon_pick',
  'icon_pick_white',
  'icon_drop',
  'icon_drop_white',
  'icon_calibration',
  'icon_calibrating',
  'icon_calibrating_white',
  'icon_commissioning',
  'icon_commissioning_white',
  'icon_idle',
  'icon_idle_white',
  'icon_mode-production',
  'icon_mode-production_white',
  'icon_more-vert',
  'icon_edit',
  'icon_rename',
  'icon_share',
  'icon_simulate',
  'icon_pauze',
  'icon_output',
  'icon_input',
  'icon_hashtag',
  'icon_id2',
  'icon_bal',
  'icon_transport-incomplete',
  'icon_list-open',
  'icon_list',
  'icon_stop-arrow',
  'icon_stop-end-arrow',
  'icon_transport-failed',
  'icon_transport-success',
  'icon_transport-finished-manually',
  'icon_transport-destination-mismatch',
  'icon_move-transport',
  'icon_marker-next',
  'icon_marker-prev',
  'icon_map-reset',
  'icon_obs-flv-static',
  'icon_obs-flv-trend',
  'icon_obs-text',
  'icon_zones',
  'icon_tooltip',
  'icon_notification',
  'icon_favorite',
  'icon_copy',
  'icon_download',
  'icon_upload',
  'icon_refresh',
  'icon_sort-default',
  'icon_sort-down',
  'icon_sort-up',
  'icon_xdeviation',
  'icon_AGV-out-of-service',
  'icon_VNA',
  'icon_VNA-out-of-service',
  'icon_location-battery',
  'icon_locations-park',
  'icon_locations-deep-stack',
  'icon_locations-search-deep-stack',
  'icon_locations-search-height-stack',
  'icon_locations-rack-floor',
  'icon_locations-rack-height',
  'icon_locations-floor',
  'icon_locations-floor2',
  'icon_locations-single-w-height',
  'icon_locations-wait',
  'icon_waiting_white',
  'icon_warming-up_white',
  'icon_locations-wait_white',
  'icon_node-direction-off',
  'icon_node-direction-on',
  'icon_node',
  'icon_nurb',
  'icon_offline',
  'icon_offline_white',
  'icon_online',
  'icon_undo',
  'icon_redo',
  'icon_save',
  'icon_sliding-node',
  'icon_doc-dxf',
  'icon_doc-cad',
  'icon_doc-pdf',
  'icon_button-close',
  'icon_button-back',
  'icon_button-add',
  'icon_button-view',
  'icon_button-plus',
  'icon_button-min',
  'icon_center-focus-weak',
  'icon_grid-on',
  'icon_snap-to',
  'icon_align-canvas',
  'icon_align-x-axis',
  'icon_align-y-axis',
  'icon_arrow-both',
  'icon_beacon',
  'icon_history',
  'icon_location-group',
  'icon_location-type',
  'icon_tool-group',
  'icon_tool-align',
  'icon_tool-box',
  'icon_tool-drawnode',
  'icon_tool-select',
  'icon_tool-select-cursor',
  'icon_title-box',
  'icon_title-group',
  'icon_title-locations',
  'icon_title-node',
  'icon_title-nurb',
  'icon_grid-off',
  'icon_interval-on',
  'icon_interval-off',
  'icon_location-templates',
  'icon_subnode',
  'icon_tool-reflector',
  'icon_tool-locations',
  'icon_status-cold-restart',
  'icon_status-hot-restart',
  'icon_status-auto-restart',
  'icon_status-none',
  'icon_status-auto-hot-restart',
  'icon_status-etricc-restart',
  'icon_status-auto-clear',
  'icon_status-move',
  'icon_status-remove',
  'icon_si-heatmap',
  'icon_si-vehicle',
  'icon_si-icon-init',
  'icon_si-icon-executing',
  'icon_filter',
  'icon_deadlock',
  'icon_deadlock_white',
  'icon_digitaltwin-error',
  'icon_link',
  'icon_nolink',
  'icon_title-measure',
  'icon_tool-lock',
  'icon_tool-polygon',
  'icon_tool-unlock',
  'icon_tool-measure',
  'icon_batteryplan',
  'icon_calibration-plan',
  'battery_20',
  'battery_20_white',
  'battery_30',
  'battery_30_white',
  'battery_50',
  'battery_50_white',
  'battery_60',
  'battery_60_white',
  'battery_80',
  'battery_80_white',
  'battery_90',
  'battery_90_white',
  'battery_full',
  'battery_full_white',
  'battery_charging_20',
  'battery_charging_20_white',
  'battery_charging_30',
  'battery_charging_30_white',
  'battery_charging_50',
  'battery_charging_50_white',
  'battery_charging_60',
  'battery_charging_60_white',
  'battery_charging_80',
  'battery_charging_80_white',
  'battery_charging_90',
  'battery_charging_90_white',
  'battery_charging_full',
  'battery_charging_full_white',
  'battery_unknown',
  'battery_unknown_white',
  'battery_to-low',
  'battery_to-low_white',
  'nav_dashboard',
  'nav_insights',
  'nav_agv',
  'nav_agv_list',
  'nav_transports',
  'nav_locations',
  'nav_signals',
  'nav_management',
  'nav_jobs',
  'nav_locations-stroked',
  'nav_jobs-stroked',
  'nav_signals-stroked',
  'nav_loads-stroked',
  'nav_easy-survey',
  'nav_stationmanagement-stroked',
  'nav_batteryplan-stroked',
  'icon_AGV-suspended',
  'icon_AGV-suspended_white',
  'icon_station-disabled',
  'icon_station-enabled',
  'stack_icon-components',
  'stack_icon-foundations',
  'stack_icon-patterns',
  'icon_station-enabled',
  'icon_permission',
  'icon_fieldfunction-auto',
  'icon_fieldfunction-forcefalse',
  'icon_fieldfunction-forcetrue',
  'icon_disable-location',
  'icon_enable-location',
  'icon_discard-loads',
  'icon_store-loads',
  'icon_in-output',
  'icon_job-suspend',
  'icon_job-cancel',
  'icon_job-release',
  'icon_transports-release',
  'icon_transports-edit',
  'icon_transports-cancel',
  'icon_transports-suspend',
  'icon_organization',
  'icon_batteries',
  'icon_chargers',
  'icon_action-cancel',
  'icon_action-delete',
  'icon_action-disable',
  'icon_action-edit',
  'icon_action-enable',
  'icon_action-release',
  'icon_action-suspend',
  'icon_cleanup',
  'icon_external-connection',
  'icon_permission-group',
  'icon_component-config',
  'icon_io',
  'icon_reports',
  'icon_create-transport-to-here',
  'icon_create-transport-from-here',
  'icon_battery-plan-delete',
  'icon_battery-plan-disable',
  'icon_battery-plan-edit',
  'icon_battery-plan-enable',
  'icon_calibration-plan-delete',
  'icon_calibration-plan-disable',
  'icon_calibration-plan-edit',
  'icon_calibration-plan-enable',
  'icon_map',
  'icon_distance-duplicate',
  'icon_mirror',
  'icon_rotate-center-key-object',
  'icon_gap-inbetween',
  'icon_title-polygon',
  'icon_center-canvas',
  'icon_background',
  'icon_screen-dark',
  'icon_screen-light',
  'icon_screen-auto'
];
