import { Component } from '@angular/core';
import { GlobalSettingsIntl } from '../global-settings-intl';

@Component({
  selector: 'ui-shell-global-settings-category-screen',
  template: `<ui-setting-category [title]="intl.screenSettings.title">
    <ui-setting-category-item>
      <ui-shell-screen-settings></ui-shell-screen-settings>
    </ui-setting-category-item>
  </ui-setting-category>`
})
export class ShellGlobalScreenSettingsComponent {
  constructor(public intl: GlobalSettingsIntl) {}
}
