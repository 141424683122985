import { Component } from '@angular/core';
import { ThemeAndBrandService } from '@onyx/core';
import { GlobalSettingsIntl } from '../../global-settings-intl';

@Component({
  selector: 'ui-shell-screen-settings',
  template: `
    <!--div class="box active">
      <mat-icon svgIcon="icon_screen-auto"></mat-icon>
      <div class="label">Auto</div>
    </div-->
    <div
      class="box"
      [ngClass]="(darkMode$ | async) ? '' : 'active'"
      (click)="changeDarkMode(false)"
    >
      <mat-icon svgIcon="icon_screen-light"></mat-icon>
      <div class="label">{{ intl.screenSettings.light }}</div>
    </div>
    <div
      class="box"
      [ngClass]="(darkMode$ | async) ? 'active' : ''"
      (click)="changeDarkMode(true)"
    >
      <mat-icon svgIcon="icon_screen-dark"></mat-icon>
      <div class="label">{{ intl.screenSettings.dark }}</div>
    </div>
  `,
  styleUrl: './screen-settings.component.scss'
})
export class ScreenSettingsComponent {
  public darkMode$ = this._themeAndBrandService.darkMode$;
  constructor(
    private _themeAndBrandService: ThemeAndBrandService,
    public intl: GlobalSettingsIntl
  ) {}

  public changeDarkMode(darkMode: boolean): void {
    this._themeAndBrandService.changeTheme(darkMode);
  }
}
