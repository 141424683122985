<div class="wrapper">
  <div class="date-time-wrapper">
    <ng-container *ngIf="type === 'date' || type === 'datetime'">
      <ng-container *ngIf="dateFormat === 'yyyy/MM/dd'">
        <ng-container *ngTemplateOutlet="yearTemplate"></ng-container>
        <span>/</span>
        <ng-container *ngTemplateOutlet="monthTemplate"></ng-container>
        <span>/</span>
        <ng-container *ngTemplateOutlet="dateTemplate"></ng-container>
      </ng-container>

      <ng-container *ngIf="dateFormat === 'MM/dd/yyyy'">
        <ng-container *ngTemplateOutlet="monthTemplate"></ng-container>
        <span>/</span>
        <ng-container *ngTemplateOutlet="dateTemplate"></ng-container>
        <span>/</span>
        <ng-container *ngTemplateOutlet="yearTemplate"></ng-container>
      </ng-container>

      <ng-container *ngIf="dateFormat === 'dd/MM/yyyy'">
        <ng-container *ngTemplateOutlet="dateTemplate"></ng-container>
        <span>/</span>
        <ng-container *ngTemplateOutlet="monthTemplate"></ng-container>
        <span>/</span>
        <ng-container *ngTemplateOutlet="yearTemplate"></ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="type === 'time' || type === 'datetime'">
      <ui-datetime-number
        #hourComponent
        [disabled]="disabled"
        [(value)]="hour"
        [max]="maxHourValue"
        [min]="minHourValue"
        placeholder="HH"
        style="width: 1.6em"
        (focusin)="onFocusIn($event)"
        (focusout)="onFocusOut($event)"
        (nextRequested)="onNextRequested($event)"
        (previousRequested)="onPreviousRequested($event)"
      >
      </ui-datetime-number>
      <span>:</span>
      <ui-datetime-number
        #minuteComponent
        [disabled]="disabled"
        [(value)]="minute"
        [max]="maxMinuteValue"
        [min]="minMinuteValue"
        placeholder="MM"
        style="width: 1.6em"
        (focusin)="onFocusIn($event)"
        (focusout)="onFocusOut($event)"
        (nextRequested)="onNextRequested($event)"
        (previousRequested)="onPreviousRequested($event)"
      >
      </ui-datetime-number>
      <ng-container *ngIf="timeFormat === 'HH:mm:ss'">
        <span>:</span>
        <ui-datetime-number
          #secondComponent
          [disabled]="disabled"
          [(value)]="second"
          [max]="maxSecondValue"
          [min]="minSecondValue"
          placeholder="SS"
          style="width: 1.6em"
          (focusin)="onFocusIn($event)"
          (focusout)="onFocusOut($event)"
          (nextRequested)="onNextRequested($event)"
          (previousRequested)="onPreviousRequested($event)"
        >
        </ui-datetime-number>
      </ng-container>
    </ng-container>
  </div>

  <input
    [type]="type==='datetime' ? 'datetime-local': type"
    #datetimePicker
    id='datetimePicker'
    (input)='onPickerSelected($event)'
    [step]="timeFormat === 'HH:mm:ss' ? 1 : 60"
  />

  <button
    type="button"
    mat-icon-button
    (click)="openDatePicker()"
    [disabled]="disabled"
  >
    <mat-icon *ngIf="type === 'date' || type === 'datetime'">today</mat-icon>
    <mat-icon *ngIf="type === 'time'">schedule</mat-icon>
  </button>
</div>

<ng-template #yearTemplate>
  <ui-datetime-number
    #yearComponent
    [disabled]="disabled"
    [(value)]="year"
    [max]="maxYearValue"
    [min]="minYearValue"
    placeholder="YYYY"
    style="width: 2.7em"
    (focusin)="onFocusIn($event)"
    (focusout)="onFocusOut($event)"
    (nextRequested)="onNextRequested($event)"
    (previousRequested)="onPreviousRequested($event)"
  >
  </ui-datetime-number>
</ng-template>

<ng-template #monthTemplate>
  <ui-datetime-number
    #monthComponent
    [disabled]="disabled"
    [(value)]="month"
    [max]="maxMonthValue"
    [min]="minMonthValue"
    placeholder="MM"
    style="width: 1.6em"
    (focusin)="onFocusIn($event)"
    (focusout)="onFocusOut($event)"
    (nextRequested)="onNextRequested($event)"
    (previousRequested)="onPreviousRequested($event)"
  >
  </ui-datetime-number>
</ng-template>

<ng-template #dateTemplate>
  <ui-datetime-number
    #dateComponent
    [disabled]="disabled"
    [(value)]="date"
    [max]="maxDateValue"
    [min]="minDateValue"
    placeholder="DD"
    style="width: 1.6em"
    (focusin)="onFocusIn($event)"
    (focusout)="onFocusOut($event)"
    (nextRequested)="onNextRequested($event)"
    (previousRequested)="onPreviousRequested($event)"
  >
  </ui-datetime-number>
</ng-template>
