import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class BaseIntl {
  private readonly _changesSubject = new Subject<void>();
  public readonly changes$ = this._changesSubject.asObservable();

  protected triggerChanges(): void {
    this._changesSubject.next();
  }
}
