import { booleanAttribute, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-icon-button',
  template: `
    <div
      class="icon-button size-{{ size }} theme-{{ theme }}"
      [class.active]="active"
      [class.disabled]="disabled"
    >
      <span
        *ngIf="badge"
        matBadge="&#8203;"
        matBadgeColor="warn"
        class="dot standalone brand"
      ></span>
      <mat-icon *ngIf="svgIcon" [svgIcon]="icon"></mat-icon>
      <mat-icon *ngIf="!svgIcon">{{ icon }}</mat-icon>
    </div>
  `,
  styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {
  @Input() public size: IconButtonSize = 'medium';
  @Input() public theme?: IconButtonTheme = 'secondary';
  @Input({ transform: booleanAttribute }) public svgIcon?: boolean = false;
  @Input({ required: true }) public icon!: string;
  @Input({ transform: booleanAttribute }) public active?: boolean = false;
  @Input({ transform: booleanAttribute }) public disabled?: boolean = false;
  @Input({ transform: booleanAttribute }) public badge?: boolean = false;
}

export type IconButtonSize = 'small' | 'medium' | 'large';
export type IconButtonTheme = 'primary' | 'secondary' | 'tertiary' | 'brand';
