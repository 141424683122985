<input
  [placeholder]="placeholder"
  [(ngModel)]="value"
  [disabled]="disabled"
  (keydown)="onKeyDown($event)"
  (paste)="onPaste($event)"
  (input)="onInput($event)"
  (blur)="onBlur()"
  (focus)="onFocus($event)"
  (click)="onClick($event)"
  type="number"
  [min]="min"
  [max]="max"
  #inputElement
/>
