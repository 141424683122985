import { booleanAttribute, Component, Input, OnDestroy } from '@angular/core';
import { map, Observable, Subject, takeUntil } from 'rxjs';
import { MobileMenuToggleService } from '../services/behavior/mobile-menu-toggle.service';
import { ResponsiveService } from '../services/responsiveness/responsive.service';

@Component({
  selector: 'ui-shell',
  template: `
    <div class="shell" [ngClass]="{ 'no-top-bar': hideTopbar }">
      <div *ngIf="isMobile$ | async; then showMobile; else showDesktop"></div>

      <ng-template #showMobile>
        <div class="main-panel mobile" role="mobile">
          <ui-shell-topbar-mobile *ngIf="!hideTopbar"></ui-shell-topbar-mobile>
          <ui-shell-main class="mobile">
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <ng-container sidePanel>
              <ng-container *ngTemplateOutlet="sidePanel"></ng-container>
            </ng-container>
          </ui-shell-main>
          <ui-shell-navigation-overlay [class.open]="mobileMenu$ | async">
            <ng-container site>
              <ng-container *ngTemplateOutlet="site"></ng-container>
            </ng-container>
          </ui-shell-navigation-overlay>
          <ui-shell-nav-mobile>
            <ng-container profile>
              <ng-container *ngTemplateOutlet="profile"></ng-container>
            </ng-container>
          </ui-shell-nav-mobile>
        </div>
      </ng-template>

      <ng-template #showDesktop>
        <ui-shell-navbar></ui-shell-navbar>
        <div class="main-panel" role="desktop">
          <ui-shell-topbar *ngIf="!hideTopbar" [hideLogo]="hideTopbarLogo">
            <ng-container icons>
              <ng-content select="mat-icon"></ng-content>
              <ng-content select="ui-icon-button"></ng-content>
            </ng-container>
            <ng-container site>
              <ng-container *ngTemplateOutlet="site"></ng-container>
            </ng-container>
            <ng-container darkMode>
              <ng-content select="ui-shell-dark-mode-toggle"></ng-content>
            </ng-container>
            <ng-container profile>
              <ng-container *ngTemplateOutlet="profile"></ng-container>
            </ng-container>
          </ui-shell-topbar>
          <ui-shell-main>
            <ng-container *ngTemplateOutlet="content"></ng-container>
            <ng-container sidePanel>
              <ng-container *ngTemplateOutlet="sidePanel"></ng-container>
            </ng-container>
          </ui-shell-main>
        </div>
      </ng-template>

      <ng-template #sidePanel>
        <ng-content select="[sidePanel]"></ng-content>
      </ng-template>

      <ng-template #content>
        <ng-content select="[content]"></ng-content>
      </ng-template>

      <ng-template #site>
        <ng-content select="ui-shell-site"></ng-content>
      </ng-template>

      <ng-template #profile>
        <ng-content select="ui-shell-profile"></ng-content>
      </ng-template>
    </div>
  `,
  styleUrls: ['shell.component.scss']
})
export class ShellComponent implements OnDestroy {
  private _destroySubject = new Subject<void>();

  @Input({ transform: booleanAttribute }) public hideTopbarLogo = false;
  @Input({ transform: booleanAttribute }) public hideTopbar = false;

  public isMobile$!: Observable<boolean>;
  public mobileMenu$: Observable<boolean>;

  constructor(
    public responsiveService: ResponsiveService,
    private _mobileMenutoggleService: MobileMenuToggleService
  ) {
    responsiveService.responsiveFlags$
      .pipe(takeUntil(this._destroySubject))
      .subscribe(flags => {
        document.body.classList.toggle('mobile', flags.mobile);
      });
    this.isMobile$ = responsiveService.responsiveFlags$.pipe(
      map(x => x.mobile)
    );
    this.mobileMenu$ = this._mobileMenutoggleService.open$.pipe(map(x => !!x));
  }

  public ngOnDestroy(): void {
    this._destroySubject.next();
    this._destroySubject.complete();
  }
}
