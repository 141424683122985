import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  Observable,
  ReplaySubject,
  combineLatest,
  map,
  shareReplay
} from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationItem } from '../shell/navbar/nav-item/navigation-item.model';
import { NavigationService } from './navigation.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveNavigationItemService {
  public activeItem$: Observable<NavigationItem | undefined>;

  private _selectedItem!: NavigationItem;
  private _selectedItemSubject = new ReplaySubject<NavigationItem>(1);

  constructor(
    private _router: Router,
    private _navigationService: NavigationService
  ) {
    this.activeItem$ = this._getActiveItem().pipe(shareReplay(1));
  }

  public get selectedItem$(): Observable<NavigationItem> {
    return this._selectedItemSubject.asObservable();
  }

  public set selectedItem(selectedItem: NavigationItem) {
    this._selectedItem = selectedItem;
    this._selectedItemSubject.next(this._selectedItem);
  }

  public get selectedItem(): NavigationItem {
    return this._selectedItem;
  }

  private _getActiveItem(): Observable<NavigationItem | undefined> {
    const currentPath$ = this._router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => event as NavigationEnd),
      map(event => event.urlAfterRedirects),
      map(url => url.slice(1)) // remove leading '/'
    );

    return combineLatest([
      this._navigationService.navigationConfig$.pipe(
        map(config => config.navigationItems)
      ),
      currentPath$
    ]).pipe(map(([items, path]) => this._findItem(items, path)));
  }

  private _findItem(
    items: NavigationItem[],
    path: string
  ): NavigationItem | undefined {
    return items.find(i => {
      const link = i.link?.join('/');
      if (link === undefined) {
        return false;
      }
      if (i.exactMatch && path === link) {
        return true;
      }
      return !i.exactMatch && path.startsWith(link);
    });
  }
}
