import { Injectable } from '@angular/core';
import { BaseIntl } from '../../services/base-intl';

@Injectable({
  providedIn: 'root'
})
export class SiteIntl extends BaseIntl {
  public changeSiteLabel = 'Change site';

  public currentSiteLabel = 'Current site';

  public chooseSiteLabel = 'Choose site';

  public sitesLabel = 'Sites';

  public searchLabel = 'Search';
}
