import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, map, startWith } from 'rxjs';
import darkTokens from '../_partials/tokens/web-stack/Dark/variables.json';
import lightTokens from '../_partials/tokens/web-stack/Light/variables.json';

export enum Brand {
  Stack = 'stack',
  Kion = 'kion',
  Linde = 'linde',
  Still = 'still',
  Dematic = 'dematic'
}

export enum Application {
  Suite = 'suite',
  Fleet = 'fleet',
  Traffic = 'traffic',
  Portal = 'portal',
  Designer = 'designer',
  Logistics = 'logistics',
  Drive = 'drive',
  Analytics = 'analytics',
  Insights = 'insights',
  Diagnostics = 'diagnostics',
  Tooling = 'tooling',
  Site = 'site',
  Orchestrator = 'orchestrator',
  Toggle = 'toggle',
  Identity = 'identity',
  Release = 'release',
  Deployer = 'deployer'
}

@Injectable({
  providedIn: 'root'
})
export class ThemeAndBrandService {
  private readonly _brandSubject = new ReplaySubject<Brand>(1);
  private readonly _applicationSubject = new ReplaySubject<Application>(1);
  private readonly _darkModeSubject = new ReplaySubject<boolean>(1);
  public readonly brand$ = this._brandSubject.asObservable();
  public readonly application$ = this._applicationSubject.asObservable();
  public readonly darkMode$ = this._darkModeSubject.asObservable();

  public readonly tokens$: Observable<{ [key: string]: string }>;

  constructor() {
    this.tokens$ = this.darkMode$
      .pipe(startWith(false))
      .pipe(map(darkMode => this.loadJsonFile(darkMode ? 'Dark' : 'Light')));
  }

  public loadJsonFile(mode: string): { [key: string]: string } {
    const jsonData = mode === 'Dark' ? darkTokens : lightTokens;
    return jsonData;
  }

  public changeTheme(darkMode: boolean): void {
    document.body.classList.remove('dark-theme', 'light');
    if (darkMode) {
      document.body.classList.add('dark-theme');
    } else {
      document.body.classList.add('light');
    }
    this._darkModeSubject.next(darkMode);
  }

  public changeBrand(brand: Brand): void {
    const brands = Object.values(Brand).map(brand => brand.toString());
    document.body.classList.remove(...brands);
    document.body.classList.add(brand.toString());

    this._addFavicons(brand);
    this._brandSubject.next(brand);
  }

  public changeApplication(application: Application): void {
    this._applicationSubject.next(application);
    const applications = Object.values(Application).map(application =>
      application.toString()
    );
    document.body.classList.remove(...applications);
    document.body.classList.add(application.toString());
  }

  public static isBrand(brand: string): brand is Brand {
    return Object.values(Brand).includes(brand as Brand);
  }

  public static isApplication(application: string): application is Application {
    return Object.values(Application).includes(application as Application);
  }

  private _removeElements(elements: NodeListOf<Element>): void {
    for (let i = 0; i < elements.length; i++) {
      const elm = elements[i];
      // Checking if elm is defined/not-null here
      if (!elm || !elm.parentNode) continue;
      elm.parentNode.removeChild(elm);
    }
  }
  private _addNode(
    id: string,
    rel: string,
    type: string,
    sizes: string,
    href: string
  ): void {
    let linkElement = document.getElementById(id);
    if (!linkElement) {
      linkElement = document.createElement('link');
      document.head.appendChild(linkElement);
    } else {
      this._removeElements(document.querySelectorAll('link[rel="icon"]'));
    }
    linkElement.setAttribute('id', 'favicon');
    linkElement.setAttribute('rel', rel);
    linkElement.setAttribute('type', type);
    linkElement.setAttribute('sizes', sizes);
    linkElement.setAttribute('href', href);
  }

  private _addFavicons(brand: string): void {
    this._addNode(
      'favicon',
      'shortcut icon',
      'image/x-icon',
      '',
      `../../assets/brands/${brand}/favicons/favicon-32x32.png`
    );
    this._addNode(
      'android-chrome-192',
      'icon',
      'image/png',
      '192x192',
      `../../assets/brands/${brand}/favicons/android-chrome-192x192.png`
    );
    this._addNode(
      'android-chrome-512',
      'icon',
      'image/png',
      '512x512',
      `../../assets/brands/${brand}/favicons/android-chrome-512x512.png`
    );
    this._addNode(
      'apple-touch-icon',
      'icon',
      'image/png',
      '57x57',
      `../../assets/brands/${brand}/favicons/apple-touch-icon-57x57.png`
    );
    this._addNode(
      'apple-touch-icon',
      'icon',
      'image/png',
      '60x60',
      `../../assets/brands/${brand}/favicons/apple-touch-icon-60x60.png`
    );
    this._addNode(
      'apple-touch-icon',
      'icon',
      'image/png',
      '72x72',
      `../../assets/brands/${brand}/favicons/apple-touch-icon-72x72.png`
    );
    this._addNode(
      'apple-touch-icon',
      'icon',
      'image/png',
      '76x76',
      `../../assets/brands/${brand}/favicons/apple-touch-icon-76x76.png`
    );
    this._addNode(
      'apple-touch-icon',
      'icon',
      'image/png',
      '114x114',
      `../../assets/brands/${brand}/favicons/apple-touch-icon-114x114.png`
    );
    this._addNode(
      'apple-touch-icon',
      'icon',
      'image/png',
      '120x120',
      `../../assets/brands/${brand}/favicons/apple-touch-icon-120x120.png`
    );
    this._addNode(
      'apple-touch-icon',
      'icon',
      'image/png',
      '144x144',
      `../../assets/brands/${brand}/favicons/apple-touch-icon-144x144.png`
    );
    this._addNode(
      'apple-touch-icon',
      'icon',
      'image/png',
      '152x152',
      `../../assets/brands/${brand}/favicons/apple-touch-icon-152x152.png`
    );
    this._addNode(
      'apple-touch-icon',
      'icon',
      'image/png',
      '167x167',
      `../../assets/brands/${brand}/favicons/apple-touch-icon-167x167.png`
    );
    this._addNode(
      'apple-touch-icon',
      'icon',
      'image/png',
      '180x180',
      `../../assets/brands/${brand}/favicons/apple-touch-icon-180x180.png`
    );

    this._addNode(
      'favicon-16',
      'icon',
      'image/png',
      '16x16',
      `../../assets/brands/${brand}/favicons/favicon-16x16.png`
    );
    this._addNode(
      'favicon-32',
      'icon',
      'image/png',
      '32x32',
      `../../assets/brands/${brand}/favicons/favicon-32x32.png`
    );
    this._addNode(
      'favicon-96',
      'icon',
      'image/png',
      '96x96',
      `../../assets/brands/${brand}/favicons/favicon-96x96.png`
    );
    this._addNode(
      'favicon-128',
      'icon',
      'image/png',
      '128x128',
      `../../assets/brands/${brand}/favicons/favicon-128x128.png`
    );
    this._addNode(
      'favicon-196',
      'icon',
      'image/png',
      '196x196',
      `../../assets/brands/${brand}/favicons/favicon-196x196.png`
    );
    this._addNode(
      'msapplication-TileImage',
      'icon',
      'image/png',
      '70x70',
      `../../assets/brands/${brand}/favicons/mstile-70x70.png`
    );
    this._addNode(
      'msapplication-TileImage',
      'icon',
      'image/png',
      '144x144',
      `../../assets/brands/${brand}/favicons/mstile-144x144.png`
    );
    this._addNode(
      'msapplication-TileImage',
      'icon',
      'image/png',
      '150x150',
      `../../assets/brands/${brand}/favicons/mstile-150x150.png`
    );
    this._addNode(
      'msapplication-TileImage',
      'icon',
      'image/png',
      '310x150',
      `../../assets/brands/${brand}/favicons/mstile-310x150.png`
    );
    this._addNode(
      'msapplication-TileImage',
      'icon',
      'image/png',
      '310x310',
      `../../assets/brands/${brand}/favicons/mstile-310x310.png`
    );
  }
}
