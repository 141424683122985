import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'ui-datetime-number',
  templateUrl: './datetime-number.component.html',
  styleUrls: ['./datetime-number.component.scss']
})
export class DatetimeNumberComponent implements OnChanges {
  @ViewChild('inputElement', { read: ElementRef })
  public inputElement!: ElementRef;

  @Input()
  public placeholder = '';

  @Input()
  public value: number | null = null;

  @Input()
  public disabled = false;

  @Output()
  public valueChange = new EventEmitter<number | null>();

  @Input()
  public max = 0;

  @Input()
  public min = 0;

  @Output()
  public previousRequested = new EventEmitter<DatetimeNumberComponent>();

  @Output()
  public nextRequested = new EventEmitter<DatetimeNumberComponent>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['min']) {
      if (this.value != null && this.value < this.min) {
        this.value = this.min;
        this._addLeadingZeros();
        this.valueChange.emit(this.value);
      }
    }

    if (changes['max']) {
      if (this.value != null && this.value > this.max) {
        this.value = this.max;
        this._addLeadingZeros();
        this.valueChange.emit(this.value);
      }
    }

    if (changes['value']) {
      this._ensureValueIsWithinBounds();
      this._addLeadingZeros();
    }
  }

  protected onKeyDown(event: KeyboardEvent): void {
    if (event.key == 'Backspace') {
      this._onBackSpace();
    } else if (event.key == 'Delete') {
      this._onDelete();
    } else if (event.key == 'ArrowLeft') {
      this._onArrowLeft();
    } else if (event.key == 'ArrowUp') {
      this._onArrowUp(event);
    } else if (event.key == 'ArrowRight') {
      this._onArrowRight();
    } else if (event.key == 'ArrowDown') {
      this._onArrowDown(event);
    } else {
      if ('-+,.eE'.includes(event.key)) {
        event.preventDefault();
      }
    }
  }

  private _onBackSpace(): void {
    if (this.value == null) {
      this.previousRequested.emit(this);
    }
  }

  private _onDelete(): void {
    if (this.value == null) {
      this.nextRequested.emit(this);
    }
  }

  private _onArrowLeft(): void {
    this.previousRequested.emit(this);
  }

  private _onArrowUp(event: KeyboardEvent): void {
    if (this.value != null && this.value < this.max) {
      const valueAsNumber = this.value;
      this.value = valueAsNumber + 1;
    } else {
      this.value = this.min;
    }
    this._addLeadingZeros();
    this.valueChange.emit(this.value);
    event.preventDefault();
  }

  private _onArrowRight(): void {
    this.nextRequested.emit(this);
  }

  private _onArrowDown(event: KeyboardEvent): void {
    if (this.value != null && this.value > this.min) {
      const valueAsNumber = this.value;
      this.value = valueAsNumber - 1;
    } else {
      this.value = this.max;
    }
    this._addLeadingZeros();
    this.valueChange.emit(this.value);
    event.preventDefault();
  }

  protected onPaste($event: ClipboardEvent): void {
    $event.preventDefault();
  }

  protected onInput(_: Event): void {
    const paddingLength = this.placeholder.toString().length;
    if (
      this.value != null &&
      this.inputElement.nativeElement.value.length == paddingLength
    ) {
      this.nextRequested.emit(this);
    } else if (
      this.value != null &&
      this.inputElement.nativeElement.value.length > paddingLength
    ) {
      setTimeout(() => {
        if (this.value!.toString().length > paddingLength) {
          this.value = parseInt(
            this.value!.toString().substring(
              this.value!.toString().length - paddingLength
            )
          );
        }
        this.inputElement.nativeElement.value =
          this.inputElement.nativeElement.value.substring(
            this.inputElement.nativeElement.value.length - paddingLength
          );
        this._addLeadingZeros();
      });
    }
  }

  private _addLeadingZeros(): void {
    setTimeout(() => {
      const paddingLength = this.placeholder.toString().length;
      if (this.inputElement.nativeElement.value) {
        this.inputElement.nativeElement.value =
          this.inputElement.nativeElement.value.padStart(paddingLength, '0');
      }
    });
  }

  protected onBlur(): void {
    this._ensureValueIsWithinBounds();
    this._addLeadingZeros();
    this.valueChange.emit(this.value);
  }

  private _ensureValueIsWithinBounds(): void {
    if (this.value != null) {
      if (this.value > this.max) {
        this.value = this.max;
        this._addLeadingZeros();
      } else if (this.value < this.min) {
        this.value = this.min;
        this._addLeadingZeros();
      }
    }
  }

  protected onFocus(event: FocusEvent): void {
    this._selectAllTextInInput(event);
  }

  protected onClick(event: FocusEvent): void {
    this._selectAllTextInInput(event);
  }

  private _selectAllTextInInput(event: UIEvent): void {
    setTimeout(() => {
      const input = event.target as HTMLInputElement;
      input.select();
    });
  }
}
