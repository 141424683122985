import { Component, Input } from '@angular/core';
import { IAction } from '../../models/actions';

export interface Link {
  label: string;
  action?: IAction;
}

@Component({
  selector: 'ui-shell-link[link]',
  template: `
    <div *ngIf="link" (click)="this.link.action?.do()" class="link">
      {{ link.label }}
    </div>
  `
})
export class LinkComponent {
  @Input() public link!: Link;
}
