import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-sidepanel-title',
  template: `
    <div class="title-container" [ngClass]="size">
      {{ title }}
      <div class="actions"><ng-content></ng-content></div>
    </div>
  `,
  styleUrls: ['./sidepanel-title.component.scss']
})
export class SidepanelTitleComponent {
  @Input() public title = '';
  @Input() public size: 'small' | 'medium' | 'large' = 'small';
}
