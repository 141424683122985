import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'ui-shell-content-header',
  styleUrls: ['content-header.component.scss'],
  template: `
    <div class="content-header" [class.sticky]="sticky">
      <ng-content></ng-content>
    </div>
  `,
  encapsulation: ViewEncapsulation.Emulated
})
export class ContentHeaderComponent implements OnChanges, OnDestroy {
  @Input() public sticky!: boolean;

  constructor(
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['sticky'] &&
      changes['sticky'].currentValue !== changes['sticky'].previousValue
    ) {
      this._renderer.removeClass(this._document.body, 'header-is-sticky');
      if (this.sticky) {
        this._renderer.addClass(this._document.body, 'header-is-sticky');
      }
    }
  }
  public ngOnDestroy(): void {
    this._renderer.removeClass(this._document.body, 'header-is-sticky');
  }
}
