import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { IAction, UrlAction } from '../../models/actions';
import { BrandDetails } from '../../services/brand-details.service';
import { ShellIntl } from '../../shell/shell-intl';

interface ContactItem {
  icon: string;
  svgIcon?: boolean;
  action: IAction;
}

export type ContactItems = [ContactItem?, ContactItem?, ContactItem?];

export interface Brand {
  brand: string;
  action?: IAction;
  termsAction?: IAction;
}

@Component({
  selector: 'ui-shell-corporate-branding[details]',
  template: `
    <div class="container">
      <div class="header">
        <div class="brand-logo"></div>
        <div *ngIf="contactItems.length > 0" class="contact">
          <span data-testid="contact-title"
            >{{ intl.termsLabels.contact }} {{ brand }}</span
          >
          <div class="contact-items" data-testid="contact-items">
            <ng-container *ngFor="let item of contactItems">
              <div *ngIf="item" class="item" (click)="item.action.do()">
                <mat-icon *ngIf="!item.svgIcon">{{ item.icon }}</mat-icon>
                <mat-icon *ngIf="item.svgIcon" [svgIcon]="item.icon"></mat-icon>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <span class="terms" data-testid="terms">
        <span [innerHTML]="getSanitizedHtml(productName)"></span>suite
        {{ intl.termsLabels.productOf }}&nbsp;<span
          [class.clickable]="siteAction"
          (click)="siteAction?.do()"
          data-testid="site-action"
          >{{ brand }}</span
        >. {{ intl.termsLabels.rightsReserved }}. {{ intl.termsLabels.usage }}
        <span
          [class.clickable]="termsAction"
          (click)="termsAction?.do()"
          data-testid="terms-action"
          >{{ intl.termsLabels.termsConditions }} {{ brand }}</span
        ></span
      >
      <span>&copy; {{ year }}</span>
    </div>
  `,
  styleUrls: ['./corporate-branding.component.scss']
})
export class CorporateBrandingComponent implements OnChanges, OnDestroy {
  @Input() public details!: BrandDetails | null;

  private _intlSubscription: Subscription;

  public brand = '';
  public productName = '';
  public siteAction?: IAction;
  public termsAction?: IAction;
  public contactItems: ContactItems = [];

  public year = new Date().getFullYear();

  constructor(
    public intl: ShellIntl,
    public sanitizer: DomSanitizer,
    changeDetectorRef: ChangeDetectorRef
  ) {
    this._updateData();

    this._intlSubscription = intl.changes$.subscribe(() =>
      changeDetectorRef.markForCheck()
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['details']) {
      this._updateData();
    }
  }

  public ngOnDestroy(): void {
    this._intlSubscription.unsubscribe();
  }

  public getSanitizedHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private _updateData(): void {
    this.brand = this.details?.fullName || '';
    this.productName = this.details?.productNameHTML || '';
    this.siteAction = this.details?.siteUrl
      ? new UrlAction(this.details.siteUrl)
      : undefined;
    this.termsAction = this.details?.termsUrl
      ? new UrlAction(this.details.termsUrl)
      : undefined;
    this.contactItems = this.details?.contactItems || [];
  }
}
