import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-thumbnail',
  template: `
    <ui-icon-button
      svgIcon
      icon="icon_arrow-line"
      theme="tertiary"
      aria-label="Back"
      class="back"
      *ngIf="showBack"
      [matTooltip]="backTooltip"
      (click)="backClicked($event)"
    >
    </ui-icon-button>
    <span class="sidepanel-title">{{ title }}</span>
    <span
      class="sidepanel-action"
      *ngIf="allowClose"
      [matTooltip]="closeTooltip"
    >
      <ui-icon-button
        icon="close"
        theme="tertiary"
        aria-label="Close"
        data-testid="close"
        (click)="closeClicked($event)"
      >
      </ui-icon-button>
    </span>
  `,
  styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public close: EventEmitter<MouseEvent> = new EventEmitter();
  @Output() public back: EventEmitter<MouseEvent> = new EventEmitter();
  @Input() public title = '';
  @Input() public allowClose = true;
  @Input() public closeTooltip = 'Close';
  @Input() public showBack = false;
  @Input() public backTooltip = 'Back';

  public closeClicked(event: MouseEvent): void {
    this.close.emit(event);
  }

  public backClicked(event: MouseEvent): void {
    this.back.emit(event);
  }
}
