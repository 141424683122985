import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-setting-category',
  template: `
    <div class="label">
      {{ title }}
      <mat-icon prefix *ngIf="icon && !svgIcon">{{ icon }}</mat-icon>
      <mat-icon prefix *ngIf="icon && svgIcon" [svgIcon]="icon"></mat-icon>
    </div>
    <div class="category-panel">
      <ng-content></ng-content>
    </div>
  `,
  styleUrl: './setting-category.component.scss'
})
export class SettingCategoryComponent {
  @Input() public title!: string;
  @Input() public icon!: string;
  @Input() public svgIcon = false;
}
