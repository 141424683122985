import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-label-value-container',
  template: `
    <ui-card padding="none" [backgroundColor]="false">
      <ui-card-header>
        <ui-title class="small" title="{{ title }}"></ui-title>
        <!--Todo DEV: clickhandler on button for matMenuTriggerFor-->
        <ui-icon-button icon="more_vert" theme="tertiary"> </ui-icon-button>
      </ui-card-header>
      <div class="container">
        <ng-content></ng-content>
      </div>
    </ui-card>
  `,
  styleUrls: ['./label-value-container.component.scss']
})
export class LabelValueContainerComponent {
  @Input() public title = '';
}
