import {
  ComponentPortal,
  DomPortal,
  TemplatePortal
} from '@angular/cdk/portal';
import { ReplaySubject } from 'rxjs';

export type Portal = TemplatePortal | ComponentPortal<unknown> | DomPortal;

export class BaseBridgeService {
  private _activePortalSubject = new ReplaySubject<Portal>(1);
  private _activePortal?: Portal;

  public readonly portal$ = this._activePortalSubject.asObservable();

  public setPortal(portal: Portal): void {
    this._activePortalSubject.next(portal);
    this._activePortal = portal;
  }

  public get activePortal(): Portal | undefined {
    return this._activePortal;
  }
}
