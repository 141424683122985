import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-label-value',
  template: `
    <div class="label">
      {{ label }}
    </div>
    <div class="value">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./label-value.component.scss']
})
export class LabelValueComponent {
  @Input() public label = '';
}
